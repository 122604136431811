import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"

import image1 from "../../assets/images/3921837.jpg"
import onboarding from "../../assets/images/card.jpeg"
import job from "../../assets/images/worker.jpeg"
import finance from "../../assets/images/businessmen-hands-white-table-with-documents-drafts.jpeg"
import health from "../../assets/images/health.jpeg"
import medical from "../../assets/images/medical.jpg"
import ContactOptions from "components/External/ContactOptions"


const Home = () => {
  // Set the meta title
  document.title = "Home Page | Care-Tip"

  // Define the bookADemo function
  const bookADemo = () => {
    location.href = "/book";
  }

  const Calculate = () => {
    location.href = "/calc"
  }

  return (
    <>
      <Header />

      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Home" breadcrumbItem="Welcome" /> */}

          {/* 1st Section: Responsive Left-Aligned Hero with Image */}
          <section
            className="hero-sectionx my-5"
            style={{ backgroundColor: "#eee", padding: "15px" }}
          >
            <Row className="align-items-center">
              <Col md="6">
                <h1 style={{ textAlign: "left", fontSize: "70px" }}>
                  Welcome to Care-Tip
                </h1>
                <h3>Your Comprehensive Workforce Management Solution</h3>
                <h4>
                  Streamlining the integration of permanent, bank, and temporary
                  staff with centralized compliance and payroll management.
                </h4>
                <br />
                <Button
                  color="primary"
                  onClick={bookADemo}
                  style={{ fontSize: "16px" }}
                >
                  Schedule a Demo
                </Button>
              </Col>
              <Col md="6">
                <img
                  src={image1}
                  alt="Hero"
                  className="img-fluid"
                  style={{ borderRadius: "2%" }}
                />
              </Col>
            </Row>
          </section>

          {/* 2nd Section: Custom Jumbotron with a Button */}
          <section className="jumbotron-section my-5 bg-light p-4 rounded">
            <h1 className="display-4" style={{ textAlign: "left" }}>
              Why Choose Us?
            </h1>
            <h3>
              Discover the benefits of our platform for care providers and
              agencies.
            </h3>
            <Button
              color="primary"
              onClick={Calculate}
              style={{ fontSize: "16px" }}
            >
              Calculate
            </Button>
          </section>

          {/* 3rd Section: Pricing Table */}
          {/* <section className="pricing-section my-5">
            <h2 className="text-center">Our Pricing Plans</h2>
            <Row className="justify-content-center mt-4">
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardBody>
                    <CardTitle tag="h3">Basic</CardTitle>
                    <h4>£29/month</h4>
                    <p>Basic features for small teams</p>
                    <Button color="primary">Select Plan</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardBody>
                    <CardTitle tag="h3">Pro</CardTitle>
                    <h4>£59/month</h4>
                    <p>Advanced features for growing teams</p>
                    <Button color="primary">Select Plan</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardBody>
                    <CardTitle tag="h3">Enterprise</CardTitle>
                    <h4>£99/month</h4>
                    <p>Full features for large teams</p>
                    <Button color="primary">Select Plan</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </section> */}

          {/* 4th Section: Custom Cards */}
          <section className="custom-cards-section my-5">
            <h1 className="text-center">Our Services</h1>
            <Row className="mt-4">
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardImg top src={onboarding} alt="Onboarding" />
                  <CardBody>
                    <CardTitle tag="h2">Onboarding</CardTitle>
                    <CardText style={{ fontSize: "16px" }}>
                      Comprehensive support for health care providers.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardImg top src={job} alt="Automated Job Management" />
                  <CardBody>
                    <CardTitle tag="h2">Automated Job Management</CardTitle>
                    <CardText style={{ fontSize: "16px" }}>
                      Customized solutions for agencies.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="text-center border p-3">
                  <CardImg top src={finance} alt="Finance" />
                  <CardBody>
                    <CardTitle tag="h2">Finance</CardTitle>
                    <CardText style={{ fontSize: "16px" }}>
                      Expert consultations and advice.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </section>

          {/* 5th Section: Icon Grid */}
          <section className="icon-grid-section my-5 text-center">
            <h1>Our Features</h1>
            <Row className="mt-4">
              <Col md="3">
                <i className="fas fa-user-md fa-3x"></i>
                <p style={{ fontSize: "16px" }}>Workers management</p>
              </Col>
              <Col md="3">
                <i className="fas fa-hands-helping fa-3x"></i>
                <p style={{ fontSize: "16px" }}>Timesheets Module</p>
              </Col>
              <Col md="3">
                <i className="fas fa-shield-alt fa-3x"></i>
                <p style={{ fontSize: "16px" }}>Invoice Client</p>
              </Col>
              <Col md="3">
                <i className="fas fa-heartbeat fa-3x"></i>
                <p style={{ fontSize: "16px" }}>Finance Engine</p>
              </Col>
            </Row>
          </section>

          {/* 6th Section: Hero with Video on the Side */}
          <section className="video-hero-section my-5">
            <Row className="align-items-center">
              <Col md="6">
                <h1 style={{ textAlign: "left" }}>
                  See Our Platform in Action
                </h1>
                <p style={{ fontSize: "16px" }}>
                  Watch a brief overview of Care-Tip's features.
                </p>
              </Col>
              <Col md="6">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="Care-Tip Video"
                    src="https://www.youtube.com/embed/3Fa3z_07oLM"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </section>

          {/* 7th Section: Section with Intro Image and Overlaying Container */}
          <section className="intro-image-section my-5 position-relative">
            <img src={medical} alt="Intro" className="img-fluid w-100" />
            <div className="overlay-content position-absolute top-50 start-50 translate-middle text-center p-4 bg-white rounded shadow">
              <h1>Join Our Community</h1>
              <p style={{ fontSize: "16px" }}>
                Connect with professionals and grow together.
              </p>
              <Button
                color="primary"
                style={{ fontSize: "16px" }}
                onClick={bookADemo}
              >
                Join Now
              </Button>
            </div>
          </section>

          {/* 8th Section: Border Hero with Cropped Image and Shadows */}
          <section className="border-hero-section my-5 text-center p-4 shadow-sm rounded">
            <h1>Experience Care-Tip</h1>
            <img
              src={health}
              alt="Experience"
              className="img-fluid rounded shadow-lg mt-3"
            />
            <p className="mt-3" style={{ fontSize: "16px" }}>
              A platform designed with care and precision for professionals.
            </p>
          </section>
        </Container>
      </div>

      <ContactOptions />

      <Footer />
    </>
  )
}

export default Home
