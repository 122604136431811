import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import ContactOptions from "components/External/ContactOptions"

const BookADemo = () => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [meetingDuration, setMeetingDuration] = useState("30 minutes")
  const [timeSlot, setTimeSlot] = useState("9:30 AM")
  const [showForm, setShowForm] = useState(false)

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    employees: "",
    products: "",
    captcha: "",
  })

  // Disable weekends in the calendar
  const isWeekday = date => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const timeSlots = [
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
  ]

  const meetingDurations = ["15 minutes", "30 minutes", "45 minutes", "1 hour"]

  const handleFormChange = e => {
    const { name, value } = e.target
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!selectedDate) {
      alert("Please select a date.")
      return
    }
    alert(
      `Preparing demo scheduled for ${selectedDate.toDateString()} at ${timeSlot} for ${meetingDuration}.`
    )
    setShowForm(true)
  }

  const handleFinalSubmit = e => {
    e.preventDefault()
    if (!formData.captcha || formData.captcha !== "12") {
      alert("Invalid captcha. Please try again.")
      return
    }
    alert(
      "Your demo has been successfully scheduled. We will contact you soon."
    )
    setShowForm(false)
    location.href="/home"
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col md="6">
            <h1 className="text-center mb-4">Book a Demo</h1>
            <Form onSubmit={handleSubmit}>
              {/* Date Picker */}
              <FormGroup>
                <Label for="date">Select a Date</Label>
                <DatePicker
                  id="date"
                  selected={selectedDate}
                  onChange={date => setSelectedDate(date)}
                  filterDate={isWeekday}
                  placeholderText="Choose a date (Monday to Friday)"
                  className="form-control"
                />
              </FormGroup>

              {/* Meeting Duration */}
              <FormGroup>
                <Label for="duration">Meeting Duration</Label>
                <Input
                  id="duration"
                  type="select"
                  value={meetingDuration}
                  onChange={e => setMeetingDuration(e.target.value)}
                >
                  {meetingDurations.map(duration => (
                    <option key={duration} value={duration}>
                      {duration}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              {/* Time Slots */}
              <FormGroup>
                <Label for="timeSlot">Select a Time</Label>
                <Input
                  id="timeSlot"
                  type="select"
                  value={timeSlot}
                  onChange={e => setTimeSlot(e.target.value)}
                >
                  {timeSlots.map(time => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              {/* Submit Button */}
              <Button color="primary" type="submit" className="w-100">
                Schedule Demo
              </Button>
            </Form>
          </Col>
        </Row>

        {/* Form Modal */}
        <Modal
          isOpen={showForm}
          toggle={() => setShowForm(!showForm)}
          size="lg"
        >
          <ModalHeader toggle={() => setShowForm(!showForm)}>
            Schedule Your Demo
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleFinalSubmit}>
              <p>
                <strong>Date:</strong> {selectedDate?.toDateString()} <br />
                <strong>Time:</strong> {timeSlot} <br />
                <strong>Duration:</strong> {meetingDuration}
              </p>

              {/* Personal Details */}
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleFormChange}
                  placeholder="Enter your first name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleFormChange}
                  placeholder="Enter your last name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  placeholder="Enter your email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Phone Number</Label>
                <Input
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleFormChange}
                  placeholder="Enter your phone number"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="company">Company</Label>
                <Input
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleFormChange}
                  placeholder="Enter your company name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="employees">Number of Employees</Label>
                <Input
                  id="employees"
                  name="employees"
                  value={formData.employees}
                  onChange={handleFormChange}
                  placeholder="Enter number of employees"
                />
              </FormGroup>
              <FormGroup>
                <Label for="products">Interested Products</Label>
                <Input
                  id="products"
                  name="products"
                  value={formData.products}
                  onChange={handleFormChange}
                  placeholder="Enter products you're interested in"
                />
              </FormGroup>
              <FormGroup>
                <Label>Captcha: What is 6 + 6?</Label>
                <Input
                  name="captcha"
                  value={formData.captcha}
                  onChange={handleFormChange}
                  placeholder="Enter the answer"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input type="checkbox" required /> I agree to the data privacy
                statement.
              </FormGroup>

              <Button color="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Form>
            <p className="mt-3 text-center">Online Meeting via Google Meets</p>
          </ModalBody>
        </Modal>
      </Container>
      <ContactOptions />
      <Footer />
    </>
  )
}

export default BookADemo
