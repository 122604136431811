// src/pages/Authentication/Email.js
import React from "react"
import { functions } from "../../firebase" // Import functions from firebase.js
import { httpsCallable } from "firebase/functions" // Import httpsCallable directly

export function Email(props) {
  const sendEmail = async () => {
    const emailData = {
      to: "delakti@yahoo.co.uk",
      subject: "Test Email Subject",
      text: "Hello, this is a test email from Firebase Functions!",
    }

    try {
      const sendEmailFunction = httpsCallable(functions, "sendEmail") // Correct usage of httpsCallable
      const response = await sendEmailFunction(emailData)
      if (response.data.success) {
        console.log("Email sent successfully!")
      } else {
        console.error("Error sending email:", response.data.message)
      }
    } catch (error) {
      console.error("Error calling email function:", error)
    }
  }

  return (
    <div className="page-content">
      <button onClick={sendEmail}>Send Test Email</button>
    </div>
  )
}

export default Email
