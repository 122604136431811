import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import future from "../../assets/images/future.jpg"
import workforce from "../../assets/images/workforce.jpg"
import cost from "../../assets/images/cost.jpg"
import compliance from "../../assets/images/compliance.jpg"
import "./BlogPage.css" // Optional CSS for custom styling

// Example blog data
const blogPosts = [
  {
    title: "5 Ways to Improve Workforce Management",
    image: workforce,
    excerpt:
      "Discover innovative strategies to streamline your workforce management and enhance productivity.",
    link: "#",
  },
  {
    title: "The Importance of Compliance in Healthcare",
    image: compliance,
    excerpt:
      "Explore how compliance impacts patient safety and operational efficiency in healthcare organizations.",
    link: "#",
  },
  {
    title: "The Future of Staffing Solutions",
    image: future,
    excerpt:
      "Learn about upcoming trends in staffing solutions and how technology is reshaping the industry.",
    link: "#",
  },
  {
    title: "How to Reduce Costs in Temporary Staffing",
    image: cost,
    excerpt:
      "Practical tips to save money while maintaining quality in temporary staffing services.",
    link: "#",
  },
]

const OurBlog = () => {
  return (
    <>
      <Header />
      <Container className="my-5">
        <h1 className="text-center mb-4">Our Blog</h1>
        <p className="text-center text-muted mb-5" style={{ fontSize: "16px" }}>
          Stay updated with the latest trends, insights, and best practices in
          workforce management.
        </p>

        <Row>
          {blogPosts.map((post, index) => (
            <Col md="6" lg="4" className="mb-4" key={index}>
              <Card className="h-100 shadow border-0">
                {/* Image */}
                <img
                  src={post.image}
                  alt={post.title}
                  className="card-img-top"
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <CardBody>
                  {/* Title */}
                  <CardTitle tag="h5">{post.title}</CardTitle>
                  {/* Excerpt */}
                  <CardText>{post.excerpt}</CardText>
                  {/* Read More Button */}
                  <Button color="primary" href={post.link} style={{ color:'white !important'}}>
                    Read More
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default OurBlog
