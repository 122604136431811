import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"

const ContactOptions = () => {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        {/* Call Us Section */}
        <Col md="6" className="mb-4">
          <Card className="h-100 shadow border-3">
            <CardBody className="text-center">
              <CardTitle tag="h2" className="mb-3">
                Call Us
              </CardTitle>
              <CardText style={{ fontSize: "20px" }}>
                Speak to a member of our team to schedule a demo
                <br />
                <strong>01253834269</strong>
                <br />
                <medium>Business Hours: 9am - 5pm on weekdays</medium>
              </CardText>
            </CardBody>
          </Card>
        </Col>

        {/* Email Us Section */}
        <Col md="6" className="mb-4">
          <Card className="h-100 shadow border-3">
            <CardBody className="text-center">
              <CardTitle tag="h2" className="mb-3">
                Email Us
              </CardTitle>
              <CardText style={{ fontSize: "20px" }}>
                Email us and we’ll make time for you:
                <br />
                <strong>support@care-tip.com</strong>
                <br />
                <medium>24-Hour Support</medium>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactOptions
