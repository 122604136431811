import { initializeApp, getApps, getApp } from "firebase/app"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getDatabase,
  ref as dbRef,
  set,
  get,
  update,
  remove,
  push,
  child,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase only if there isn't an existing app
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
const storage = getStorage(app) // Firebase Storage
const db = getDatabase(app) // Firebase Realtime Database

class TimesheetDataService {
  constructor() {
    this.timesheetRef = dbRef(db, "timesheets") // Reference to the "timesheets" node
  }

  // Fetch the most recent timesheet for a specific jobID
  async getLatestTimesheet(jobID) {
    const snapshot = await get(this.timesheetRef)
    if (snapshot.exists()) {
      const timesheets = snapshot.val()
      const jobTimesheets = Object.keys(timesheets)
        .map(key => ({ id: key, ...timesheets[key] }))
        .filter(timesheet => timesheet.jobID === jobID)

      if (jobTimesheets.length > 0) {
        // Sort by DateSubmitted in descending order to get the most recent one
        jobTimesheets.sort(
          (a, b) => new Date(b.DateSubmitted) - new Date(a.DateSubmitted)
        )
        return jobTimesheets[0] // Return the latest timesheet
      }
    }
    return null // Return null if no timesheets found for the jobID
  }

  // Fetch all timesheets
  async getAllTimesheets() {
    const snapshot = await get(this.timesheetRef) // Retrieve all timesheets from Realtime Database
    if (snapshot.exists()) {
      const timesheets = snapshot.val() // Get the data
      return Object.keys(timesheets).map(key => ({
        id: key,
        ...timesheets[key],
      })) // Return array of timesheets with 'id'
    } else {
      return [] // No data
    }
  }

  // Upload a file to Firebase Storage and return the download URL
  async uploadFile(file, path) {
    const storageRef = ref(storage, `${path}/${file.name}`)
    const snapshot = await uploadBytes(storageRef, file)
    return await getDownloadURL(snapshot.ref)
  }

  // Create a new timesheet in Realtime Database
  async createTimesheet(timesheet) {
    const newTimesheetRef = push(this.timesheetRef) // Generate a unique key for the new timesheet
    await set(newTimesheetRef, timesheet) // Save timesheet data
    return newTimesheetRef.key // Return the unique ID of the created timesheet
  }

  // In TimesheetDataService.js
  // Create a new timesheet in Realtime Database
  async createNewTimesheet(timesheetData) {
    const newTimesheetRef = push(this.timesheetRef) // Generate a unique key for the new timesheet
    await set(newTimesheetRef, timesheetData) // Save the timesheet data in the new reference
    return { id: newTimesheetRef.key, ...timesheetData } // Return the ID of the created timesheet
  }

  // Fetch a specific timesheet by ID from Realtime Database
  async getTimesheetById(timesheetID) {
    const timesheetSnapshot = await get(child(this.timesheetRef, timesheetID))
    if (timesheetSnapshot.exists()) {
      return timesheetSnapshot.val() // Return the timesheet data if it exists
    } else {
      return null // Return null if no data is found
    }
  }

  // Update an existing timesheet in Realtime Database
  async updateTimesheet(timesheetID, timesheet) {
    const timesheetToUpdate = child(this.timesheetRef, timesheetID)
    await update(timesheetToUpdate, timesheet)
  }

  // Delete a timesheet from Realtime Database
  async deleteTimesheet(timesheetID) {
    const timesheetToDelete = child(this.timesheetRef, timesheetID)
    await remove(timesheetToDelete)
  }
}

export default new TimesheetDataService()
