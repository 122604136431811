import React, { useState, useEffect } from "react"
import AWS from "aws-sdk"
import { Form, Input, Label, Button, Container, Alert, Table } from "reactstrap"

const CreateEmailTemplateForm = () => {
  const [templateName, setTemplateName] = useState("")
  const [subject, setSubject] = useState("")
  const [htmlContent, setHtmlContent] = useState("")
  const [textContent, setTextContent] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [templates, setTemplates] = useState([])
  const [isEditing, setIsEditing] = useState(false)

  // Initialize AWS SES with the region and credentials
  const ses = new AWS.SES({
    region: "eu-north-1",
    accessKeyId: "AKIAVVZON4MOPBED3CRP",
    secretAccessKey: "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",
  })

  // Fetch the list of templates when the component is mounted
  useEffect(() => {
    fetchTemplates()
  }, [])

  const fetchTemplates = async () => {
    try {
      const result = await ses.listTemplates({}).promise()
      console.log(result) // Logging the result for debugging
      setTemplates(result.TemplatesMetadata)
    } catch (error) {
      setError(`Failed to load templates: ${error.message}`)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const params = {
      Template: {
        TemplateName: templateName,
        SubjectPart: subject,
        HtmlPart: htmlContent,
        TextPart: textContent,
      },
    }

    try {
      if (isEditing) {
        // Update existing template
        await ses.updateTemplate(params).promise()
        setMessage(`Template ${templateName} updated successfully!`)
      } else {
        // Create new template
        await ses.createTemplate(params).promise()
        setMessage(`Template ${templateName} created successfully!`)
      }
      setError("")
      clearForm()
      fetchTemplates() // Refresh template list
    } catch (error) {
      setError(
        `Failed to ${isEditing ? "update" : "create"} template: ${
          error.message
        }`
      )
      setMessage("")
    }
  }

  const clearForm = () => {
    setTemplateName("")
    setSubject("")
    setHtmlContent("")
    setTextContent("")
    setIsEditing(false)
  }

  const handleEdit = async template => {
    try {
      const result = await ses
        .getTemplate({ TemplateName: template.Name })
        .promise()
      const { Template } = result

      // Populate the form fields with the fetched template data
      setTemplateName(Template.TemplateName)
      setSubject(Template.SubjectPart)
      setHtmlContent(Template.HtmlPart)
      setTextContent(Template.TextPart)
      setIsEditing(true)
    } catch (error) {
      setError(`Failed to load template details: ${error.message}`)
    }
  }

  const handleDelete = async templateName => {
    try {
      await ses.deleteTemplate({ TemplateName: templateName }).promise()
      setMessage(`Template ${templateName} deleted successfully!`)
      setError("")
      fetchTemplates() // Refresh template list
    } catch (error) {
      setError(`Failed to delete template: ${error.message}`)
    }
  }

  return (
    <div className="page-content">
      <Container>
        <h2>{isEditing ? "Edit" : "Create"} Email Template</h2>
        {message && <Alert color="success">{message}</Alert>}
        {error && <Alert color="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <div className="mb-3">
            <Label for="templateName">Template Name</Label>
            <Input
              type="text"
              id="templateName"
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
              required
              disabled={isEditing} // Disable name field when editing
            />
          </div>

          <div className="mb-3">
            <Label for="subject">Subject</Label>
            <Input
              type="text"
              id="subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <Label for="htmlContent">HTML Content</Label>
            <Input
              type="textarea"
              id="htmlContent"
              value={htmlContent}
              onChange={e => setHtmlContent(e.target.value)}
              rows="6"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="textContent">Text Content</Label>
            <Input
              type="textarea"
              id="textContent"
              value={textContent}
              onChange={e => setTextContent(e.target.value)}
              rows="4"
              required
            />
          </div>

          <Button type="submit" color="primary">
            {isEditing ? "Update Template" : "Create Template"}
          </Button>
          {isEditing && (
            <Button
              type="button"
              color="secondary"
              onClick={clearForm}
              className="ms-2"
            >
              Cancel
            </Button>
          )}
        </Form>

        <h3 className="mt-5">Available Email Templates</h3>
        <Table>
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {templates.map(template => (
              <tr key={template.Name}>
                <td>{template.Name}</td>
                <td>{new Date(template.CreatedTimestamp).toLocaleString()}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => handleEdit(template)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleDelete(template.Name)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export default CreateEmailTemplateForm
