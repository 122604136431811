import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/rota.png"
import feature1 from "../../assets/images/scheduling.jpeg"
import feature2 from "../../assets/images/realtime.png"
import feature3 from "../../assets/images/Scheduler.png"
import "./RotaManagement.css"
import ContactUs from "./ContactUs"
import BookADemo from "./BookADemo"

const RotaManagement = () => {

      const BookADemo = () => {
        location.href = "/book"
      }

      const ContactUs = () => {
        location.href = "/contactus"
      }
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Simplify Your Rota Management</h1>
            <p className="lead">
              Manage staff schedules with ease and efficiency using our
              innovative rota management platform.
            </p>
            <Button color="primary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>

        {/* Features Section */}
        <section className="features-section my-5">
          <Container>
            <h2 className="text-center mb-4">Key Features</h2>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Automated Scheduling</CardTitle>
                    <CardText>
                      Save time and reduce errors with automated rota
                      generation.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Real-Time Updates</CardTitle>
                    <CardText>
                      Stay up-to-date with instant notifications for shift
                      changes.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Customizable Rotas</CardTitle>
                    <CardText>
                      Tailor rotas to fit your organization’s unique needs and
                      preferences.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">Benefits of Rota Management</h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Increased Efficiency</h5>
                <p>
                  Streamline scheduling processes, saving time for your HR and
                  management teams.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Enhanced Communication</h5>
                <p>
                  Keep everyone on the same page with clear and accessible
                  schedules.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Reduced Absenteeism</h5>
                <p>
                  Ensure staff are aware of their shifts well in advance to
                  minimize missed shifts.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Flexibility</h5>
                <p>
                  Quickly adapt to changes with flexible shift swapping and
                  updates.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section my-5">
          <Container>
            <h2 className="text-center mb-4">What Our Clients Say</h2>
            <Row>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "Care-Tip’s rota management system has transformed how we
                      handle scheduling. It's intuitive and incredibly
                      efficient!"
                    </CardText>
                    <p className="text-muted text-end">
                      - Sarah L., HR Manager
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "We’ve significantly reduced scheduling errors and saved
                      countless hours thanks to this platform."
                    </CardText>
                    <p className="text-muted text-end">
                      - Michael B., Operations Lead
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Streamline Your Scheduling?</h2>
            <p className="lead mb-4">
              Contact us today to learn more about our rota management solutions
              or to schedule a demo.
            </p>
            <Button
              color="primary"
              size="lg"
              className="me-3"
              onClick={ContactUs}
            >
              Contact Us
            </Button>
            <Button color="secondary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default RotaManagement
