import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import { Form as DxForm } from "devextreme-react/form"
import JobDataService from "services/JobDataService"
import BusinessUnitDataService from "services/BusinessUnitDataService"

const EditJobModal = ({
  isOpen,
  toggle,
  jobToEdit,
  onSave,
  organisations = [],
}) => {
  const [jobData, setJobData] = useState(jobToEdit || {})
  const [businessUnits, setBusinessUnits] = useState([])

  // Ensure the job data is updated when the modal opens
  useEffect(() => {
    setJobData(jobToEdit || {})
  }, [jobToEdit])

  // Fetch all business units when the modal is opened
  useEffect(() => {
    if (isOpen) {
      BusinessUnitDataService.getAll(fetchedBusinessUnits => {
        setBusinessUnits(fetchedBusinessUnits)
      })
    }
  }, [isOpen])

  const handleSave = async () => {
    try {
      // Save the edited job
      await JobDataService.updateJob(jobData.jobID, jobData)
      onSave() // Callback to refresh the job list
      toggle() // Close the modal
    } catch (error) {
      console.error("Failed to save job:", error)
    }
  }

  const formItems = [
    {
      dataField: "jobTitle",
      label: { text: "Job Title" },
      editorOptions: { value: jobData.jobTitle || "" },
    },
    {
      dataField: "jobDescription",
      label: { text: "Job Description" },
      editorType: "dxTextArea",
      editorOptions: { value: jobData.jobDescription || "" },
    },
    {
      dataField: "startDate",
      label: { text: "Start Date" },
      editorType: "dxDateBox",
      editorOptions: { value: jobData.startDate || "" },
    },
    {
      dataField: "startTime",
      label: { text: "Start Time" },
      editorType: "dxDateBox",
      editorOptions: {
        type: "time", // Time selector
        value: jobData.startTime || "",
      },
    },
    {
      dataField: "endDate",
      label: { text: "End Date" },
      editorType: "dxDateBox",
      editorOptions: { value: jobData.endDate || "" },
    },
    {
      dataField: "endTime",
      label: { text: "End Time" },
      editorType: "dxDateBox",
      editorOptions: {
        type: "time", // Time selector
        value: jobData.endTime || "",
      },
    },
    {
      dataField: "location",
      label: { text: "Location" },
      editorOptions: { value: jobData.location || "" },
    },
    {
      dataField: "provider",
      label: { text: "Provider" },
      editorType: "dxSelectBox",
      editorOptions: {
        items: organisations.map(org => ({
          value: org.key,
          label: org.businessName,
        })), // Assuming `organisations` prop contains available providers
        displayExpr: "label",
        valueExpr: "value",
        value: jobData.provider || "",
      },
    },
    {
      dataField: "businessUnits",
      label: { text: "Business Unit" },
      editorType: "dxSelectBox",
      editorOptions: {
        items: businessUnits.map(bu => ({
          value: bu.key,
          label: bu.businessName,
        })),
        displayExpr: "label",
        valueExpr: "value",
        value: jobData.businessUnits || "", // Ensure the saved business unit is selected
      },
    },
    {
      dataField: "jobstatus",
      label: { text: "Job Status" },
      editorType: "dxSelectBox",
      editorOptions: {
        items: ["open", "closed", "pending"],
        value: jobData.jobstatus || "open",
      },
    },
  ]

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Edit Job</ModalHeader>
      <ModalBody>
        <DxForm
          formData={jobData}
          colCount={1}
          items={formItems}
          onFieldDataChanged={e => {
            setJobData({ ...jobData, [e.dataField]: e.value })
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditJobModal
