import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/career.jpeg"
import cultureImage1 from "../../assets/images/hr1.jpeg"
import cultureImage2 from "../../assets/images/hr2.jpeg"
import cultureImage3 from "../../assets/images/hr3.jpeg"
import "./Careers.css"

const Careers = () => {
  const jobListings = [
    {
      title: "Software Engineer",
      location: "Leicester, UK",
      type: "Full-Time",
      description: "Develop and maintain cutting-edge software solutions.",
      link: "#",
    },
    {
      title: "Marketing Specialist",
      location: "Remote",
      type: "Part-Time",
      description: "Drive marketing campaigns and brand awareness efforts.",
      link: "#",
    },
    {
      title: "HR Manager",
      location: "Leicester, UK",
      type: "Full-Time",
      description: "Manage recruitment, employee relations, and HR policies.",
      link: "#",
    },
  ]

  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Join Our Team</h1>
            <p className="lead">
              Build your career with Care-Tip and make a difference in
              transforming workforce management.
            </p>
            <Button color="primary" size="lg">
              View Open Positions
            </Button>
          </Container>
        </section>

        {/* Job Listings Section */}
        <section className="job-listings-section my-5">
          <Container>
            <h2 className="text-center mb-4">Current Openings</h2>
            <Row>
              {jobListings.map((job, index) => (
                <Col md="4" className="mb-4" key={index}>
                  <Card className="h-100 shadow border-0">
                    <CardBody>
                      <CardTitle tag="h5">{job.title}</CardTitle>
                      <CardText>
                        <strong>Location:</strong> {job.location} <br />
                        <strong>Type:</strong> {job.type}
                      </CardText>
                      <CardText>{job.description}</CardText>
                      <Button color="primary" href={job.link}>
                        Apply Now
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* Company Culture Section */}
        <section className="culture-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">Our Culture</h2>
            <p className="text-center mb-5">
              At Care-Tip, we foster an inclusive and collaborative work
              environment that empowers innovation and growth.
            </p>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={cultureImage1}
                    alt="Culture 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Team Collaboration</CardTitle>
                    <CardText>
                      We believe in teamwork and value every contribution.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={cultureImage2}
                    alt="Culture 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Career Growth</CardTitle>
                    <CardText>
                      We support our employees in achieving their career goals.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={cultureImage3}
                    alt="Culture 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Work-Life Balance</CardTitle>
                    <CardText>
                      Flexible work arrangements to maintain a healthy balance.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section my-5">
          <Container>
            <h2 className="text-center mb-4">What Our Team Says</h2>
            <Row>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "Working at Care-Tip has been a game-changer for my
                      career. The support and opportunities are unmatched."
                    </CardText>
                    <p className="text-muted text-end">
                      - Emily R., Software Engineer
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "The culture here is amazing. I’ve grown both
                      professionally and personally during my time at Care-Tip."
                    </CardText>
                    <p className="text-muted text-end">
                      - John T., Marketing Specialist
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action Section */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Join Us?</h2>
            <p className="lead mb-4">
              Explore our current openings or contact us to learn more about
              working at Care-Tip.
            </p>
            <Button color="primary" size="lg" className="me-3">
              View Open Positions
            </Button>
            <Button color="secondary" size="lg">
              Contact Us
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default Careers
