  import React, { useState, useEffect } from "react"
  import { Popup } from "devextreme-react/popup"
  import { Form, SimpleItem, RequiredRule } from "devextreme-react/form"
  import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel"
  import "devextreme/dist/css/dx.light.css"
  import WorkersAllocationService from "services/WorkersAllocationService"
  import { ContextMenu } from "devextreme-react/context-menu"
  import notify from "devextreme/ui/notify"

  const AllocationModal = ({ visible, onHiding, jobData }) => {
    const [companyName, setCompanyName] = useState("")
    const [eligibleWorkers, setEligibleWorkers] = useState([])
    const [suggestedWorkers, setSuggestedWorkers] = useState([])
    const [selectedWorkers, setSelectedWorkers] = useState([])
    const [rejectedWorkers, setRejectedWorkers] = useState([])
    const [contextMenuTarget, setContextMenuTarget] = useState(null)
    const [contextMenuItems, setContextMenuItems] = useState([])

    useEffect(() => {
      const fetchAllocations = async jobId => {
        try 
        {
          console.log(`Fetching allocations for ${jobId}`);
          
          const [eligible, suggested, selected, rejected] = await Promise.all([
            WorkersAllocationService.getAllocationsByCategory(jobId, "eligible"),
            WorkersAllocationService.getAllocationsByCategory(jobId, "suggested"),
            WorkersAllocationService.getAllocationsByCategory(jobId, "selected"),
            WorkersAllocationService.getAllocationsByCategory(jobId, "rejected"),
          ])

          setEligibleWorkers(Array.isArray(eligible) ? eligible : [])
          setSuggestedWorkers(Array.isArray(suggested) ? suggested : [])
          setSelectedWorkers(Array.isArray(selected) ? selected : [])
          setRejectedWorkers(Array.isArray(rejected) ? rejected : [])
        } catch (error) {
          console.error("Failed to fetch allocations:", error)
        }
      }

      if (!localStorage.getItem("authUserDetails")) {
        console.log("No user details found...")
      } else {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))
        const company = userDetails.userCompany
        setCompanyName(company)

        if (jobData && (jobData.key || jobData.id)) 
        {
          const jobId = jobData.key || jobData.id
          console.log(`Fetching allocations for jobId: ${jobId}`) // Log jobId before fetching
          fetchAllocations(jobId)
        } 
        else 
        {
          console.error("Job data is missing key or id:", jobData)
        }
      }
    }, [jobData])
 
const handleMoveWorker = async (worker, fromList, setFromList, toCategory) => {
  console.log(`Moving worker ${worker.key} from list to ${toCategory}`)

  const fromCategory =
    fromList === eligibleWorkers
      ? "eligible"
      : fromList === suggestedWorkers
      ? "suggested"
      : fromList === selectedWorkers
      ? "selected"
      : "rejected"

  if (Array.isArray(fromList)) {
    console.log(`Removing worker ${worker.key} from ${fromCategory}`)
    // Update the 'from' list by filtering out the worker
    setFromList(fromList.filter(w => w.key !== worker.key))

    // Update Firebase
    try {
      const jobId = jobData.key || jobData.id

      console.log("job data: " + jobId)

      // Remove worker from the previous category
      await WorkersAllocationService.removeWorkerFromCategory(
        jobId,
        fromCategory,
        worker.key
      )

      // Add worker to the new category
      await WorkersAllocationService.addWorkerToCategory(
        jobId,
        toCategory,
        worker
      )

      // Update the job data to reflect the moved worker
      await JobDataService.updateJob(jobId, {
        workerID: worker.key, // Add worker.key as workerID in the job data
        workerCategory: toCategory, // Optionally, update the category in the job data
      })

      // Update local state based on toCategory
      if (toCategory === "eligible") {
        setEligibleWorkers(prev => [...prev, worker])
      } else if (toCategory === "suggested") {
        setSuggestedWorkers(prev => [...prev, worker])
      } else if (toCategory === "selected") {
        setSelectedWorkers(prev => [...prev, worker])
      } else if (toCategory === "rejected") {
        setRejectedWorkers(prev => [...prev, worker])
      }

      console.log(`Worker ${worker.key} moved to ${toCategory}`)
      notify(
        `${worker.firstName} ${worker.lastName} has been moved.`,
        "success",
        2000
      )
    } catch (error) {
      console.error(`Error moving worker ${worker.key}:`, error)
    }
  } else {
    console.error(`fromList is not an array:`, fromList)
  }
}


    const getContextMenuItems = list => {
      console.log(`Getting context menu items for list`, list)
      switch (list) {
        case eligibleWorkers:
          return [
            { text: "Send to Suggested", category: "suggested" },
            { text: "Send to Selected", category: "selected" },
            { text: "Send to Rejected", category: "rejected" },
          ]
        case suggestedWorkers:
          return [
            { text: "Send to Eligible", category: "eligible" },
            { text: "Send to Selected", category: "selected" },
            { text: "Send to Rejected", category: "rejected" },
          ]
        case selectedWorkers:
          return [
            { text: "Send to Suggested", category: "suggested" },
            { text: "Send to Eligible", category: "eligible" },
            { text: "Send to Rejected", category: "rejected" },
          ]
        case rejectedWorkers:
          return [
            { text: "Send to Eligible", category: "eligible" },
            { text: "Send to Suggested", category: "suggested" },
            { text: "Send to Selected", category: "selected" },
          ]
        default:
          return []
      }
    }

    const handleContextMenuClick = e => {
      console.log(`Context menu clicked: ${e.itemData.text}`)
      const worker = contextMenuTarget
      const selectedAction = contextMenuItems.find(
        item => item.text === e.itemData.text
      )
      if (selectedAction) {
        let list, setList

        if (eligibleWorkers.some(w => w.key === worker.key)) {
          list = eligibleWorkers
          setList = setEligibleWorkers
        } else if (suggestedWorkers.some(w => w.key === worker.key)) {
          list = suggestedWorkers
          setList = setSuggestedWorkers
        } else if (selectedWorkers.some(w => w.key === worker.key)) {
          list = selectedWorkers
          setList = setSelectedWorkers
        } else if (rejectedWorkers.some(w => w.key === worker.key)) {
          list = rejectedWorkers
          setList = setRejectedWorkers
        } else {
          console.error("Worker not found in any list:", worker)
          return
        }

        console.log(`Moving worker ${worker.key} using list`, list)
        handleMoveWorker(worker, list, setList, selectedAction.category)
      } else {
        console.error(`No action found for ${e.itemData.text}`)
      }
    }

    const renderWorkerList = (workers, list) => (
      <ul>
        {workers.length > 0 ? (
          workers.map(worker => (
            <li
              key={worker.key}
              id={worker.key}
              style={{
                backgroundColor: "#f0f0f0",
                margin: "5px",
                padding: "5px",
                cursor: "pointer",
              }}
              onContextMenu={e => {
                e.preventDefault()
                setContextMenuTarget(worker)
                setContextMenuItems(getContextMenuItems(list))
                console.log(`Worker ${worker.key} right-clicked`)
                const contextMenu = document.getElementById("context-menu")
                const { clientX: mouseX, clientY: mouseY } = e
                contextMenu.style.left = `${mouseX}px`
                contextMenu.style.top = `${mouseY}px`
                contextMenu.style.position = "absolute"
                contextMenu.style.display = "block"
              }}
            >
              {worker.firstName} {worker.lastName} - {worker.workerType}
            </li>
          ))
        ) : (
          <div>No workers found.</div>
        )}
      </ul>
    )

    const safeJobData = jobData || {
      jobTitle: "",
      jobType: "",
      startDate: new Date(),
      endDate: new Date(),
    }

    return (
      <Popup
        visible={visible}
        onHiding={onHiding}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Allocation Details"
        width={1000}
        height={600}
        position={{ my: "center", at: "center", of: window }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{ flex: 1, padding: "10px", borderRight: "1px solid #ccc" }}
          >
            <Form formData={safeJobData}>
              <SimpleItem
                dataField="jobID"
                label={{ text: "Job ID" }}
                isRequired={true}
              >
                <RequiredRule />
                <div>{jobData?.jobID || jobData?.key}</div>
              </SimpleItem>
              <SimpleItem dataField="jobTitle" isRequired={true}>
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField="jobType" isRequired={true}>
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="startDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: "datetime",
                  displayFormat: "dd/MM/yyyy HH:mm",
                }}
                isRequired={true}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: "datetime",
                  displayFormat: "dd/MM/yyyy HH:mm",
                }}
                isRequired={true}
              >
                <RequiredRule />
              </SimpleItem>
            </Form>
          </div>
          <div style={{ flex: 3, padding: "10px" }}>
            <TabPanel>
              <TabItem title="Eligible Workers">
                <div style={{ backgroundColor: "#e6f7ff" }}>
                  {renderWorkerList(eligibleWorkers, eligibleWorkers)}
                </div>
              </TabItem>
              <TabItem title="Suggested Workers">
                <div style={{ backgroundColor: "#f9fbe7" }}>
                  {renderWorkerList(suggestedWorkers, suggestedWorkers)}
                </div>
              </TabItem>
              <TabItem title="Selected Workers">
                <div style={{ backgroundColor: "#fff3e0" }}>
                  {renderWorkerList(selectedWorkers, selectedWorkers)}
                </div>
              </TabItem>
              <TabItem title="Rejected Workers">
                <div style={{ backgroundColor: "#ffebee" }}>
                  {renderWorkerList(rejectedWorkers, rejectedWorkers)}
                </div>
              </TabItem>
            </TabPanel>
          </div>
        </div>
        <ContextMenu
          id="context-menu"
          dataSource={contextMenuItems}
          target="#context-menu"
          onItemClick={handleContextMenuClick}
          visible={contextMenuTarget !== null}
          onHidden={() => setContextMenuTarget(null)}
        />
      </Popup>
    )
  }

  export default AllocationModal
