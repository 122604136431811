import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import heroImage from "../../assets/images/3921837.jpg"
import value1Image from "../../assets/images/card.jpeg"
import value2Image from "../../assets/images/worker.jpeg"
import value3Image from "../../assets/images/businessmen-hands-white-table-with-documents-drafts.jpeg"

const AboutUs = () => {
  // Set the meta title
  document.title = "About Us | Care-Tip"

  return (
    <>
      <Header />

      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="About Us" breadcrumbItem="Care-Tip" /> */}
          <Container>
            <h1 className="display-4">About Care-Tip</h1>
            <p className="lead">
              {/* Transforming workforce management with innovative solutions
                tailored to the healthcare industry. */}
            </p>
          </Container>
          {/* 1st Section: Hero Section */}
          <section
            className="hero-section text-white text-center py-5"
            style={{
              backgroundImage: `url(${heroImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Container>
              <h1 className="display-4">Welcome to Care-Tip</h1>
              <p className="lead">
                {/* Transforming workforce management with innovative solutions
                tailored to the healthcare industry. */}
              </p>
            </Container>
          </section>

          {/* 2nd Section: Mission Statement */}
          <section className="mission-section my-5 text-center">
            <Container>
              <h1>Our Mission</h1>
              <p className="lead" style={{ fontSize: "16px" }}>
                At Care-Tip, our mission is to empower healthcare providers with
                streamlined workforce management tools that save time, reduce
                costs, and ensure quality care delivery.
              </p>
            </Container>
          </section>

          {/* 3rd Section: Our Values */}
          <section className="values-section my-5">
            <Container>
              <h1 className="text-center mb-4">Our Values</h1>
              <Row>
                <Col md="4" className="mb-3">
                  <Card className="h-100 shadow">
                    <img
                      src={value1Image}
                      alt="Value 1"
                      className="card-img-top"
                    />
                    <CardBody>
                      <CardTitle tag="h2">Innovation</CardTitle>
                      <CardText style={{ fontSize: "16px" }}>
                        We are committed to pioneering new technologies that
                        simplify workforce management.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" className="mb-3">
                  <Card className="h-100 shadow">
                    <img
                      src={value2Image}
                      alt="Value 2"
                      className="card-img-top"
                    />
                    <CardBody>
                      <CardTitle tag="h2">Integrity</CardTitle>
                      <CardText style={{ fontSize: "16px" }}>
                        Transparency and trust are the cornerstones of our
                        business.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" className="mb-3">
                  <Card className="h-100 shadow">
                    <img
                      src={value3Image}
                      alt="Value 3"
                      className="card-img-top"
                    />
                    <CardBody>
                      <CardTitle tag="h2">Collaboration</CardTitle>
                      <CardText style={{ fontSize: "16px" }}>
                        Working together with our clients and partners to
                        achieve common goals.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

          {/* 4th Section: Our Journey */}
          <section className="journey-section my-5 bg-light py-5">
            <Container>
              <h1 className="text-center mb-4">Our Journey</h1>
              <p className="text-center" style={{ fontSize: "16px" }}>
                Since our inception, Care-Tip has been focused on transforming
                the way healthcare organizations manage their workforce. From
                our humble beginnings to becoming a trusted partner in the
                industry, our story is one of growth, resilience, and
                innovation.
              </p>
            </Container>
          </section>

          {/* 5th Section: Meet Our Team */}
          <section className="team-section my-5">
            <Container>
              <h1 className="text-center mb-4">Meet Our Team</h1>
              <Row>
                <Col md="6" className="mb-4">
                  <Card className="shadow border-0">
                    <CardBody className="text-center">
                      <CardTitle tag="h2">Samuel Nwigwe</CardTitle>
                      <CardText style={{ fontSize: "16px" }}>
                        CEO & Founder
                      </CardText>
                      <p>Passionate about creating solutions that matter.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" className="mb-4">
                  <Card className="shadow border-0">
                    <CardBody className="text-center">
                      <CardTitle tag="h2">Jane Smith</CardTitle>
                      <CardText style={{ fontSize: "16px" }}>
                        Chief Technology Officer
                      </CardText>
                      <p>Driving innovation with cutting-edge technology.</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </div>

      <Footer />
    </>
  )
}

export default AboutUs
