import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap"
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import "./CircularMenu.css" // Custom CSS for circular menu styling

import logUserActivity from "helpers/activityLogger"

const Worker = () => {
  const [modals, setModals] = useState({
    profile: false,
    documents: false,
    passports: false,
    cv: false,
    covid: false,
    ni: false,
    dbs: false,
    workPermit: false,
    proof: false,
    certificates: false,
    emergency: false,
    vaccinations: false,
    references: false,
  })

  const [currentWorker, setCurrentWorker] = useState({
    firstName: "",
    lastName: "",
    workerID: "",
    picture: "",
    email: "",
    address: "",
    userCompany: "",
    fullName: "",
  })

  const [profilePic, setProfilePic] = useState("")
  const [documents, setDocuments] = useState([])
  const [newDocument, setNewDocument] = useState({
    DocumentDoc: null,
    DocumentType: "",
    DocumentValidUntil: "",
  })

  const [passports, setPassports] = useState([])
  const [newPassport, setNewPassport] = useState({
    PassportDoc: null,
    PassportType: "",
    PassportValidUntil: "",
  })

  const [cvs, setCVs] = useState([])
  const [newCV, setNewCV] = useState({
    CVDoc: null,
    CVType: "",
    CVValidUntil: "",
  }) // For new CV upload

  const [covids, setCovids] = useState([])
  const [newCovid, setNewCovid] = useState({
    CovidDoc: null,
    CovidType: "",
    CovidValidUntil: "",
  }) // For new COVID document upload

  const [nis, setNIs] = useState([])
  const [newNI, setNewNI] = useState({
    NationalInsuranceType: "",
    NationalInsuranceValidUntil: "",
    NationalInsuranceDoc: null,
  })
  const [dbsRecords, setDBSRecords] = useState([])
  const [newDBS, setNewDBS] = useState({
    DisclosureType: "",
    DisclosureValidUntil: "",
    DisclosureDoc: null,
  })
  const [workPermits, setWorkPermits] = useState([])
  const [newWorkPermit, setNewWorkPermit] = useState({
    WorkType: "",
    WorkValidUntil: "",
    WorkDoc: null,
  })

  const [proofs, setProofs] = useState([])
  const [newProof, setNewProof] = useState({
    ProofType: "",
    ProofValidUntil: "",
    ProofDoc: null,
  })
  const [certificates, setCertificates] = useState([]) // To store Certificates
  const [newCertificate, setNewCertificate] = useState({
    CertificateType: "",
    CertificateValidUntil: "",
    CertificateDoc: null,
  })
  const [references, setReferences] = useState([]) // State for reference records
  const [newReference, setNewReference] = useState({
    ReferenceType: "",
    ReferenceValidUntil: "",
    ReferenceName: "",
    ReferenceInfo: "",
    ReferenceDoc: null, // File for the reference document
  })
  const [emergencies, setEmergencies] = useState([]) // Emergency contacts
  const [newEmergency, setNewEmergency] = useState({
    emergencyContactName: "",
    emergencyRelationship: "",
    emergencyPriContact: "",
    emergencySecContact: "",
  }) // For new emergency contact

  const [vaccinations, setVaccinations] = useState([]) // State for vaccination records
  const [newVaccination, setNewVaccination] = useState({
    VaccinationType: "",
    VaccinationValidUntil: "",
    VaccinationAllergies: "",
    VaccinationInfo: "",
    VaccinationProduct: "",
    VaccinationTreatments: "",
    VaccinationDoc: null, // File for the vaccination document
  })

  // Fetch User Details from localStorage on component mount
  useEffect(() => {
    const authUserDetails = localStorage.getItem("authUserDetails")
    if (authUserDetails) {
      const userDetails = JSON.parse(authUserDetails)
      const workerId = userDetails.mailReady

      setCurrentWorker({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        workerID: workerId,
        picture: userDetails.picture,
        email: userDetails.email,
        address: userDetails.address,
        userCompany: userDetails.userCompany,
        fullName: userDetails.fullName,
      })
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  useEffect(() => {
    if (currentWorker.workerID) {
      fetchDocuments()
      fetchPassports()
      fetchCVs()
      fetchCovids()
      fetchNIs()
      fetchDBS()
      fetchWorkPermits()
      fetchProofs()
      fetchCertificates()
      fetchEmergencies()
      fetchVaccinations()
    }
  }, [currentWorker.workerID])

  // Handle profile picture update
  const handleProfilePicChange = e => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfilePic(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // Handle form input changes
  const handleProfileInputChange = e => {
    const { name, value } = e.target
    setCurrentWorker({ ...currentWorker, [name]: value })
  }

  // Handle profile save action
  const handleProfileSave = () => {
    console.log("Profile saved:", currentWorker)
    toggleModal("profile")
  }

  const fetchCertificates = async () => {
    const db = getFirestore()
    const certificatesRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "certificates"
    )
    const certificatesSnapshot = await getDocs(certificatesRef)
    const certificatesList = certificatesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setCertificates(certificatesList)
  }

  const fetchDocuments = async () => {
    const db = getFirestore()
    const documentsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "documents"
    )
    const documentsSnapshot = await getDocs(documentsRef)
    const documentsList = documentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setDocuments(documentsList)
  }

  const fetchPassports = async () => {
    const db = getFirestore()
    const passportsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "passports"
    )
    const passportsSnapshot = await getDocs(passportsRef)
    const passportsList = passportsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setPassports(passportsList)
  }

  const fetchCVs = async () => {
    const db = getFirestore()
    const cvsRef = collection(db, "workers", currentWorker.workerID, "cvs")
    const cvsSnapshot = await getDocs(cvsRef)
    const cvsList = cvsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setCVs(cvsList)
  }

  const fetchCovids = async () => {
    const db = getFirestore()
    const covidRef = collection(db, "workers", currentWorker.workerID, "covids")
    const covidSnapshot = await getDocs(covidRef)
    const covidList = covidSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setCovids(covidList)
  }

  const fetchNIs = async () => {
    const db = getFirestore()
    const nisRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "nationalinsurances"
    )
    const nisSnapshot = await getDocs(nisRef)
    const nisList = nisSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setNIs(nisList)
  }

  const fetchDBS = async () => {
    const db = getFirestore()
    const dbsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "disclosures"
    )
    const dbsSnapshot = await getDocs(dbsRef)
    const dbsList = dbsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setDBSRecords(dbsList)
  }

  const fetchWorkPermits = async () => {
    const db = getFirestore()
    const workPermitsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "workPermits"
    )
    const workPermitsSnapshot = await getDocs(workPermitsRef)
    const workPermitsList = workPermitsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setWorkPermits(workPermitsList)
  }

  const fetchProofs = async () => {
    const db = getFirestore()
    const proofsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "proofs"
    )
    const proofsSnapshot = await getDocs(proofsRef)
    const proofsList = proofsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setProofs(proofsList)
  }

  const fetchEmergencies = async () => {
    const db = getFirestore()
    const emergenciesRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "emergencies"
    )
    const emergenciesSnapshot = await getDocs(emergenciesRef)
    const emergenciesList = emergenciesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setEmergencies(emergenciesList)
  }

  // Fetch vaccinations from Firestore
  const fetchVaccinations = async () => {
    const db = getFirestore()
    const vaccinationsRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "vaccinations"
    )
    const vaccinationsSnapshot = await getDocs(vaccinationsRef)
    const vaccinationsList = vaccinationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setVaccinations(vaccinationsList)
  }

  useEffect(() => {
    if (currentWorker.workerID) {
      fetchReferences() // Fetch references on load
    }
  }, [currentWorker.workerID])

  // Fetch references from Firestore
  const fetchReferences = async () => {
    const db = getFirestore()
    const referencesRef = collection(
      db,
      "workers",
      currentWorker.workerID,
      "references"
    )
    const referencesSnapshot = await getDocs(referencesRef)
    const referencesList = referencesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
    setReferences(referencesList)
  }

  // Toggle modals
  const toggleModal = modal => setModals({ ...modals, [modal]: !modals[modal] })

  // Handle form input changes
  const handleRefInputChange = e => {
    const { name, value } = e.target
    setNewReference({ ...newReference, [name]: value })
  }

  // Handle reference document file change
  const handleRefFileChange = e => {
    setNewReference({ ...newReference, ReferenceDoc: e.target.files[0] })
  }

  // Handle document upload
  const submitDocumentHandler = async e => {
    e.preventDefault()
    const { workerID } = currentWorker

    if (
      !newDocument.DocumentDoc ||
      !newDocument.DocumentType ||
      !newDocument.DocumentValidUntil
    ) {
      alert("All fields are required for document upload!")
      return
    }

    const db = getFirestore()
    const storage = getStorage()
    const storageRef = ref(
      storage,
      `documents/${workerID}/${newDocument.DocumentDoc.name}`
    )

    await uploadBytes(storageRef, newDocument.DocumentDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const documentData = {
          DocumentType: newDocument.DocumentType,
          DocumentValidUntil: newDocument.DocumentValidUntil,
          DocumentDoc: downloadURL,
        }

        const workerDocsRef = collection(db, "workers", workerID, "documents")
        const docRef = await addDoc(workerDocsRef, documentData)

        setDocuments([...documents, { id: docRef.id, ...documentData }])
        setNewDocument({
          DocumentDoc: null,
          DocumentType: "",
          DocumentValidUntil: "",
        })
      })
      .catch(error => console.error("Error uploading document: ", error))
  }

  // Handle passport upload
  const submitPassportHandler = async e => {
    e.preventDefault()
    const { workerID } = currentWorker

    if (
      !newPassport.PassportDoc ||
      !newPassport.PassportType ||
      !newPassport.PassportValidUntil
    ) {
      alert("All fields are required for passport upload!")
      return
    }

    const db = getFirestore()
    const storage = getStorage()
    const storageRef = ref(
      storage,
      `passports/${workerID}/${newPassport.PassportDoc.name}`
    )

    await uploadBytes(storageRef, newPassport.PassportDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const passportData = {
          PassportType: newPassport.PassportType,
          PassportValidUntil: newPassport.PassportValidUntil,
          PassportDoc: downloadURL,
        }

        const workerPassportsRef = collection(
          db,
          "workers",
          workerID,
          "passports"
        )
        const docRef = await addDoc(workerPassportsRef, passportData)

        setPassports([...passports, { id: docRef.id, ...passportData }])
        setNewPassport({
          PassportDoc: null,
          PassportType: "",
          PassportValidUntil: "",
        })
      })
      .catch(error => console.error("Error uploading passport: ", error))
  }

  // Handle CV upload
  const submitCVHandler = async e => {
    e.preventDefault()
    const { workerID } = currentWorker

    if (!newCV.CVDoc || !newCV.CVType || !newCV.CVValidUntil) {
      alert("All fields are required for CV upload!")
      return
    }

    const db = getFirestore()
    const storage = getStorage()
    const storageRef = ref(storage, `cvs/${workerID}/${newCV.CVDoc.name}`)

    await uploadBytes(storageRef, newCV.CVDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const cvData = {
          CVType: newCV.CVType,
          CVValidUntil: newCV.CVValidUntil,
          CVDoc: downloadURL,
        }

        const workerCVsRef = collection(db, "workers", workerID, "cvs")
        const docRef = await addDoc(workerCVsRef, cvData)

        setCVs([...cvs, { id: docRef.id, ...cvData }])
        setNewCV({ CVDoc: null, CVType: "", CVValidUntil: "" })
      })
      .catch(error => console.error("Error uploading CV: ", error))
  }

  // Handle COVID document upload
  const submitCovidHandler = async e => {
    e.preventDefault()

    const { workerID } = currentWorker

    if (
      !newCovid.CovidDoc ||
      !newCovid.CovidType ||
      !newCovid.CovidValidUntil
    ) {
      alert("All fields are required for COVID document upload!")
      return
    }

    const db = getFirestore()
    const storage = getStorage()
    const storageRef = ref(
      storage,
      `covids/${workerID}/${newCovid.CovidDoc.name}`
    )

    await uploadBytes(storageRef, newCovid.CovidDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const covidData = {
          CovidType: newCovid.CovidType,
          CovidValidUntil: newCovid.CovidValidUntil,
          CovidDoc: downloadURL,
        }

        const workerCovidRef = collection(db, "workers", workerID, "covids")
        const docRef = await addDoc(workerCovidRef, covidData)

        setCovids([...covids, { id: docRef.id, ...covidData }])
        setNewCovid({ CovidDoc: null, CovidType: "", CovidValidUntil: "" })
      })
      .catch(error => console.error("Error uploading COVID document: ", error))
  }

  // Handle NI document upload
  const submitNIHandler = async e => {
    e.preventDefault()

    if (
      !newNI.NationalInsuranceType ||
      !newNI.NationalInsuranceValidUntil ||
      !newNI.NationalInsuranceDoc
    ) {
      alert(
        "National Insurance Type, Valid Until date, and document file are required."
      )
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `nationalinsurances/${workerID}/${newNI.NationalInsuranceDoc.name}`
    )

    await uploadBytes(storageRef, newNI.NationalInsuranceDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const nationalInsuranceData = {
          NationalInsuranceType: newNI.NationalInsuranceType,
          NationalInsuranceValidUntil: newNI.NationalInsuranceValidUntil,
          NationalInsuranceDoc: downloadURL,
        }

        const workerNIRef = collection(
          db,
          "workers",
          workerID,
          "nationalinsurances"
        )
        const docRef = await addDoc(workerNIRef, nationalInsuranceData)

        setNIs([...nis, { id: docRef.id, ...nationalInsuranceData }])
        setNewNI({
          NationalInsuranceType: "",
          NationalInsuranceValidUntil: "",
          NationalInsuranceDoc: null,
        })
      })
      .catch(error => console.error("Error uploading NI document: ", error))
  }

  // Handle DBS document upload
  const submitDBSHandler = async e => {
    e.preventDefault()

    if (
      !newDBS.DisclosureType ||
      !newDBS.DisclosureValidUntil ||
      !newDBS.DisclosureDoc
    ) {
      alert(
        "Disclosure Type, Valid Until date, and document file are required."
      )
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `disclosures/${workerID}/${newDBS.DisclosureDoc.name}`
    )

    await uploadBytes(storageRef, newDBS.DisclosureDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const disclosureData = {
          DisclosureType: newDBS.DisclosureType,
          DisclosureValidUntil: newDBS.DisclosureValidUntil,
          DisclosureDoc: downloadURL,
        }

        const workerDBSRef = collection(db, "workers", workerID, "disclosures")
        const docRef = await addDoc(workerDBSRef, disclosureData)

        setDBSRecords([...dbsRecords, { id: docRef.id, ...disclosureData }])
        setNewDBS({
          DisclosureType: "",
          DisclosureValidUntil: "",
          DisclosureDoc: null,
        })
      })
      .catch(error => console.error("Error uploading DBS document: ", error))
  }

  // Handle Work Permit document upload
  const submitWorkPermitHandler = async e => {
    e.preventDefault()

    if (
      !newWorkPermit.WorkType ||
      !newWorkPermit.WorkValidUntil ||
      !newWorkPermit.WorkDoc
    ) {
      alert("Work Type, Valid Until date, and document file are required.")
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `workPermits/${workerID}/${newWorkPermit.WorkDoc.name}`
    )

    await uploadBytes(storageRef, newWorkPermit.WorkDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const workPermitData = {
          WorkType: newWorkPermit.WorkType,
          WorkValidUntil: newWorkPermit.WorkValidUntil,
          WorkDoc: downloadURL,
        }

        const workerWorkPermitsRef = collection(
          db,
          "workers",
          workerID,
          "workPermits"
        )
        const docRef = await addDoc(workerWorkPermitsRef, workPermitData)

        setWorkPermits([...workPermits, { id: docRef.id, ...workPermitData }])
        setNewWorkPermit({
          WorkType: "",
          WorkValidUntil: "",
          WorkDoc: null,
        })
      })
      .catch(error => console.error("Error uploading work permit: ", error))
  }

  // Handle Proof of Address document upload
  const submitProofHandler = async e => {
    e.preventDefault()

    if (
      !newProof.ProofType ||
      !newProof.ProofValidUntil ||
      !newProof.ProofDoc
    ) {
      alert("Proof Type, Valid Until date, and document file are required.")
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `proofs/${workerID}/${newProof.ProofDoc.name}`
    )

    await uploadBytes(storageRef, newProof.ProofDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const proofData = {
          ProofType: newProof.ProofType,
          ProofValidUntil: newProof.ProofValidUntil,
          ProofDoc: downloadURL,
        }

        const workerProofsRef = collection(db, "workers", workerID, "proofs")
        const docRef = await addDoc(workerProofsRef, proofData)

        setProofs([...proofs, { id: docRef.id, ...proofData }])
        setNewProof({
          ProofType: "",
          ProofValidUntil: "",
          ProofDoc: null,
        })
      })
      .catch(error =>
        console.error("Error uploading proof of address: ", error)
      )
  }

  // Handle Certificate upload
  const submitCertHandler = async e => {
    e.preventDefault()

    if (
      !newCertificate.CertificateType ||
      !newCertificate.CertificateValidUntil ||
      !newCertificate.CertificateDoc
    ) {
      alert(
        "Certificate Type, Valid Until date, and document file are required."
      )
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `certificates/${workerID}/${newCertificate.CertificateDoc.name}`
    )

    await uploadBytes(storageRef, newCertificate.CertificateDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const certificateData = {
          CertificateType: newCertificate.CertificateType,
          CertificateValidUntil: newCertificate.CertificateValidUntil,
          CertificateDoc: downloadURL,
        }

        const workerCertsRef = collection(
          db,
          "workers",
          workerID,
          "certificates"
        )
        const docRef = await addDoc(workerCertsRef, certificateData)

        setCertificates([
          ...certificates,
          { id: docRef.id, ...certificateData },
        ])
        setNewCertificate({
          CertificateType: "",
          CertificateValidUntil: "",
          CertificateDoc: null,
        })
      })
      .catch(error =>
        console.error("Error uploading certificate to Firebase: ", error)
      )
  }

  // Handle emergency contact save
  const submitEmergencyHandler = async e => {
    e.preventDefault()

    // Validate required fields
    if (
      !newEmergency.emergencyContactName ||
      !newEmergency.emergencyRelationship ||
      !newEmergency.emergencyPriContact
    ) {
      alert("Contact Name, Relationship, and Primary Contact are required.")
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()

    const emergencyData = {
      emergencyContactName: newEmergency.emergencyContactName,
      emergencyRelationship: newEmergency.emergencyRelationship,
      emergencyPriContact: newEmergency.emergencyPriContact,
      emergencySecContact: newEmergency.emergencySecContact,
    }

    try {
      const workerEmergencyRef = collection(
        db,
        "workers",
        workerID,
        "emergencies"
      )
      const docRef = await addDoc(workerEmergencyRef, emergencyData)

      // Add the new emergency contact to the state
      setEmergencies([...emergencies, { id: docRef.id, ...emergencyData }])

      // Reset the form
      setNewEmergency({
        emergencyContactName: "",
        emergencyRelationship: "",
        emergencyPriContact: "",
        emergencySecContact: "",
      })
    } catch (error) {
      console.error("Error saving emergency contact to Firestore: ", error)
    }
  }

  // Submit reference form handler
  const submitReferenceHandler = async e => {
    e.preventDefault()

    // Validate required fields
    if (
      !newReference.ReferenceType ||
      !newReference.ReferenceValidUntil ||
      !newReference.ReferenceDoc
    ) {
      alert(
        "Reference Type, Reference Valid Until, and Reference Document are required."
      )
      return
    }

    const { workerID } = currentWorker
    const db = getFirestore()
    const storage = getStorage()

    // Upload reference document to Firebase Storage
    const storageRef = ref(
      storage,
      `references/${workerID}/${newReference.ReferenceDoc.name}`
    )

    await uploadBytes(storageRef, newReference.ReferenceDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const referenceData = {
          ReferenceType: newReference.ReferenceType,
          ReferenceValidUntil: newReference.ReferenceValidUntil,
          ReferenceName: newReference.ReferenceName,
          ReferenceInfo: newReference.ReferenceInfo,
          ReferenceDoc: downloadURL, // Store download URL for document
        }

        try {
          const workerReferenceRef = collection(
            db,
            "workers",
            workerID,
            "references"
          )
          const docRef = await addDoc(workerReferenceRef, referenceData)

          // Log the activity
          logUserActivity({
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s reference updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })

          // Update state with the new reference record
          setReferences(prev => [...prev, { id: docRef.id, ...referenceData }])

          // Reset the form
          setNewReference({
            ReferenceType: "",
            ReferenceValidUntil: "",
            ReferenceName: "",
            ReferenceInfo: "",
            ReferenceDoc: null,
          })
        } catch (error) {
          console.error("Error saving reference to Firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading reference document: ", error)
      })
  }

  // Render modal for updating sections like Profile, Documents, Passports, Emergency contacts, Vaccinations, References, etc.
  const renderModal = (modal, title, bodyContent) => (
    <Modal isOpen={modals[modal]} toggle={() => toggleModal(modal)} size="lg">
      <ModalHeader toggle={() => toggleModal(modal)}>
        {title} | {currentWorker.firstName} {currentWorker.lastName}
      </ModalHeader>
      <ModalBody>{bodyContent}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => toggleModal(modal)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <div className="page-content">
      <Table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Worker ID</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{currentWorker.firstName}</td>
            <td>{currentWorker.lastName}</td>
            <td>{currentWorker.workerID}</td>
          </tr>
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Button color="primary" onClick={() => toggleModal("profile")}>
          Profile
        </Button>
        &nbsp; &nbsp;
        <Button color="secondary" onClick={() => toggleModal("documents")}>
          Documents
        </Button>
        &nbsp; &nbsp;
        <Button color="success" onClick={() => toggleModal("passports")}>
          Passports
        </Button>
        &nbsp; &nbsp;
        <Button color="info" onClick={() => toggleModal("cv")}>
          CV
        </Button>
        &nbsp; &nbsp;
        <Button color="warning" onClick={() => toggleModal("covid")}>
          COVID
        </Button>
        &nbsp; &nbsp;
        <Button color="danger" onClick={() => toggleModal("ni")}>
          NI
        </Button>
        &nbsp; &nbsp;
        <Button color="dark" onClick={() => toggleModal("dbs")}>
          DBS
        </Button>
        &nbsp; &nbsp;
        <Button color="primary" onClick={() => toggleModal("workPermit")}>
          Work Permit
        </Button>
        &nbsp; &nbsp;
        <Button color="secondary" onClick={() => toggleModal("proof")}>
          Proof of Address
        </Button>
        &nbsp; &nbsp;
        <Button color="success" onClick={() => toggleModal("certificates")}>
          Certificates
        </Button>
        &nbsp; &nbsp;
        <Button color="warning" onClick={() => toggleModal("emergency")}>
          Emergency Contacts
        </Button>
        &nbsp; &nbsp;
        <Button color="info" onClick={() => toggleModal("references")}>
          References
        </Button>
      </div>

      {/* Profile Update Modal */}
      {renderModal(
        "profile",
        "Update Profile",
        <form>
          <FormGroup>
            <Label for="profilePicture">Profile Picture</Label>
            <div>
              <img
                src={profilePic || currentWorker.picture}
                alt="Profile"
                width="100"
                height="100"
                style={{ borderRadius: "50%" }}
              />
              <Input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={currentWorker.firstName}
              onChange={handleProfileInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={currentWorker.lastName}
              onChange={handleProfileInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              value={currentWorker.email}
              onChange={handleProfileInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="address"
              value={currentWorker.address}
              onChange={handleProfileInputChange}
            />
          </FormGroup>

          <Button color="success" onClick={handleProfileSave}>
            Save Profile
          </Button>
        </form>
      )}

      {/* Documents Modal */}
      {renderModal(
        "documents",
        "Manage Documents",
        <div>
          <FormGroup>
            <Label for="documentUpload">Upload Document</Label>
            <Input
              type="file"
              onChange={e =>
                setNewDocument({
                  ...newDocument,
                  DocumentDoc: e.target.files[0],
                })
              }
            />
            <FormGroup>
              <Label for="documentType">Document Type</Label>
              <Input
                type="text"
                name="documentType"
                value={newDocument.DocumentType}
                onChange={e =>
                  setNewDocument({
                    ...newDocument,
                    DocumentType: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="documentValidUntil">Valid Until</Label>
              <Input
                type="date"
                name="documentValidUntil"
                value={newDocument.DocumentValidUntil}
                onChange={e =>
                  setNewDocument({
                    ...newDocument,
                    DocumentValidUntil: e.target.value,
                  })
                }
              />
            </FormGroup>
            <Button
              color="primary"
              onClick={submitDocumentHandler}
              style={{ marginTop: "10px" }}
            >
              Upload Document
            </Button>
          </FormGroup>

          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {documents.map((doc, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{doc.DocumentType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(doc.DocumentDoc, "_blank")}
                    >
                      View
                    </Button>
                    <Button color="danger" size="sm">
                      Delete
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Passports Modal */}
      {renderModal(
        "passports",
        "Manage Passports",
        <div>
          <FormGroup>
            <Label for="passportType">Passport Type</Label>
            <Input
              type="text"
              name="passportType"
              value={newPassport.PassportType}
              onChange={e =>
                setNewPassport({ ...newPassport, PassportType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="passportValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="passportValidUntil"
              value={newPassport.PassportValidUntil}
              onChange={e =>
                setNewPassport({
                  ...newPassport,
                  PassportValidUntil: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="passportUpload">Upload Passport</Label>
            <Input
              type="file"
              onChange={e =>
                setNewPassport({
                  ...newPassport,
                  PassportDoc: e.target.files[0],
                })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitPassportHandler}>
            Upload Passport
          </Button>
          <ul>
            {passports.map((passport, index) => (
              <li key={index}>
                <a href={passport.PassportDoc} target="_blank" rel="noreferrer">
                  {passport.PassportType}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* CV Modal */}
      {renderModal(
        "cv",
        "Manage CV",
        <div>
          <FormGroup>
            <Label for="cvType">CV Type</Label>
            <Input
              type="text"
              name="cvType"
              value={newCV.CVType}
              onChange={e => setNewCV({ ...newCV, CVType: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="cvValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="cvValidUntil"
              value={newCV.CVValidUntil}
              onChange={e =>
                setNewCV({ ...newCV, CVValidUntil: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="cvUpload">Upload CV</Label>
            <Input
              type="file"
              onChange={e => setNewCV({ ...newCV, CVDoc: e.target.files[0] })}
            />
          </FormGroup>
          <Button color="primary" onClick={submitCVHandler}>
            Upload CV
          </Button>
          <ul>
            {cvs.map((cv, index) => (
              <li key={index}>
                <a href={cv.CVDoc} target="_blank" rel="noreferrer">
                  {cv.CVType}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* COVID Modal */}
      {renderModal(
        "covid",
        "Manage COVID Records",
        <div>
          <FormGroup>
            <Label for="covidType">COVID Document Type</Label>
            <Input
              type="text"
              name="covidType"
              value={newCovid.CovidType}
              onChange={e =>
                setNewCovid({ ...newCovid, CovidType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="covidValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="covidValidUntil"
              value={newCovid.CovidValidUntil}
              onChange={e =>
                setNewCovid({ ...newCovid, CovidValidUntil: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="covidUpload">Upload COVID Document</Label>
            <Input
              type="file"
              onChange={e =>
                setNewCovid({ ...newCovid, CovidDoc: e.target.files[0] })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitCovidHandler}>
            Upload COVID Document
          </Button>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {covids.map((covid, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{covid.CovidType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(covid.CovidDoc, "_blank")}
                    >
                      View
                    </Button>
                    <Button color="danger" size="sm">
                      Delete
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* National Insurance Modal */}
      {renderModal(
        "ni",
        "Manage National Insurance (NI)",
        <div>
          <FormGroup>
            <Label for="niType">National Insurance Type</Label>
            <Input
              type="text"
              name="niType"
              value={newNI.NationalInsuranceType}
              onChange={e =>
                setNewNI({ ...newNI, NationalInsuranceType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="niValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="niValidUntil"
              value={newNI.NationalInsuranceValidUntil}
              onChange={e =>
                setNewNI({
                  ...newNI,
                  NationalInsuranceValidUntil: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="niUpload">Upload NI Document</Label>
            <Input
              type="file"
              onChange={e =>
                setNewNI({ ...newNI, NationalInsuranceDoc: e.target.files[0] })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitNIHandler}>
            Upload NI Document
          </Button>

          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {nis.map((ni, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{ni.NationalInsuranceType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() =>
                        window.open(ni.NationalInsuranceDoc, "_blank")
                      }
                    >
                      View
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* DBS Modal */}
      {renderModal(
        "dbs",
        "Manage Disclosure and Barring Service (DBS)",
        <div>
          <FormGroup>
            <Label for="dbsType">Disclosure Type</Label>
            <Input
              type="text"
              name="dbsType"
              value={newDBS.DisclosureType}
              onChange={e =>
                setNewDBS({ ...newDBS, DisclosureType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="dbsValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="dbsValidUntil"
              value={newDBS.DisclosureValidUntil}
              onChange={e =>
                setNewDBS({ ...newDBS, DisclosureValidUntil: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="dbsDoc">Upload Disclosure Document</Label>
            <Input
              type="file"
              name="dbsDoc"
              onChange={e =>
                setNewDBS({ ...newDBS, DisclosureDoc: e.target.files[0] })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitDBSHandler}>
            Upload DBS
          </Button>

          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {dbsRecords.map((dbs, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{dbs.DisclosureType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(dbs.DisclosureDoc, "_blank")}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Work Permit Modal */}
      {renderModal(
        "workPermit",
        "Manage Work Permit",
        <div>
          <FormGroup>
            <Label for="workType">Work Permit Type</Label>
            <Input
              type="text"
              name="workType"
              value={newWorkPermit.WorkType}
              onChange={e =>
                setNewWorkPermit({ ...newWorkPermit, WorkType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="workValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="workValidUntil"
              value={newWorkPermit.WorkValidUntil}
              onChange={e =>
                setNewWorkPermit({
                  ...newWorkPermit,
                  WorkValidUntil: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="workDoc">Upload Work Permit Document</Label>
            <Input
              type="file"
              name="workDoc"
              onChange={e =>
                setNewWorkPermit({
                  ...newWorkPermit,
                  WorkDoc: e.target.files[0],
                })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitWorkPermitHandler}>
            Upload Work Permit
          </Button>

          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {workPermits.map((work, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{work.WorkType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(work.WorkDoc, "_blank")}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Proof of Address Modal */}
      {renderModal(
        "proof",
        "Manage Proof of Address",
        <div>
          <FormGroup>
            <Label for="proofType">Proof of Address Type</Label>
            <Input
              type="text"
              name="proofType"
              value={newProof.ProofType}
              onChange={e =>
                setNewProof({ ...newProof, ProofType: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="proofValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="proofValidUntil"
              value={newProof.ProofValidUntil}
              onChange={e =>
                setNewProof({ ...newProof, ProofValidUntil: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="proofDoc">Upload Proof of Address Document</Label>
            <Input
              type="file"
              name="proofDoc"
              onChange={e =>
                setNewProof({ ...newProof, ProofDoc: e.target.files[0] })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitProofHandler}>
            Upload Proof of Address
          </Button>

          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {proofs.map((proof, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{proof.ProofType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(proof.ProofDoc, "_blank")}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Certificates Modal */}
      {renderModal(
        "certificates",
        "Manage Certificates",
        <div>
          <FormGroup>
            <Label for="certificateType">Certificate Type</Label>
            <Input
              type="text"
              name="certificateType"
              value={newCertificate.CertificateType}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateType: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="certificateValidUntil">Valid Until</Label>
            <Input
              type="date"
              name="certificateValidUntil"
              value={newCertificate.CertificateValidUntil}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateValidUntil: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="certificateDoc">Upload Certificate Document</Label>
            <Input
              type="file"
              name="certificateDoc"
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateDoc: e.target.files[0],
                })
              }
            />
          </FormGroup>
          <Button color="primary" onClick={submitCertHandler}>
            Upload Certificate
          </Button>

          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {certificates.map((cert, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span>{cert.CertificateType}</span>
                  <div>
                    <Button
                      color="link"
                      onClick={() => window.open(cert.CertificateDoc, "_blank")}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Emergency Contacts Modal */}
      {renderModal(
        "emergency",
        "Manage Emergency Contacts",
        <div>
          <form onSubmit={submitEmergencyHandler}>
            <FormGroup>
              <Label for="emergencyContactName">Emergency Contact Name</Label>
              <Input
                type="text"
                name="emergencyContactName"
                value={newEmergency.emergencyContactName}
                onChange={handleRefInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyRelationship">Relationship</Label>
              <Input
                type="text"
                name="emergencyRelationship"
                value={newEmergency.emergencyRelationship}
                onChange={handleRefInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyPriContact">Primary Contact</Label>
              <Input
                type="text"
                name="emergencyPriContact"
                value={newEmergency.emergencyPriContact}
                onChange={handleRefInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencySecContact">Secondary Contact</Label>
              <Input
                type="text"
                name="emergencySecContact"
                value={newEmergency.emergencySecContact}
                onChange={handleRefInputChange}
              />
            </FormGroup>
            <Button color="primary" type="submit">
              Add Emergency Contact
            </Button>
          </form>

          {/* Display the list of existing emergency contacts */}
          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {emergencies.map((emergency, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <div>
                    <strong>{emergency.emergencyContactName}</strong> (
                    {emergency.emergencyRelationship})
                    <br />
                    Primary: {emergency.emergencyPriContact}
                    <br />
                    Secondary: {emergency.emergencySecContact || "N/A"}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* References Modal */}
      {renderModal(
        "references",
        "Manage References",
        <div>
          <form onSubmit={submitReferenceHandler}>
            <FormGroup>
              <Label for="ReferenceType">Reference Type</Label>
              <Input
                type="text"
                name="ReferenceType"
                value={newReference.ReferenceType}
                onChange={handleRefInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="ReferenceValidUntil">Valid Until</Label>
              <Input
                type="date"
                name="ReferenceValidUntil"
                value={newReference.ReferenceValidUntil}
                onChange={handleRefInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="ReferenceName">Reference Name</Label>
              <Input
                type="text"
                name="ReferenceName"
                value={newReference.ReferenceName}
                onChange={handleRefInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="ReferenceInfo">Additional Info</Label>
              <Input
                type="textarea"
                name="ReferenceInfo"
                value={newReference.ReferenceInfo}
                onChange={handleRefInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="ReferenceDoc">Reference Document</Label>
              <Input type="file" onChange={handleRefFileChange} />
            </FormGroup>
            <Button color="primary" type="submit">
              Add Reference
            </Button>
          </form>

          {/* Display the list of existing references */}
          <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "20px" }}>
            {references.map((reference, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <div>
                    <strong>{reference.ReferenceType}</strong>
                    <br />
                    Valid Until: {reference.ReferenceValidUntil}
                    <br />
                    <Button
                      color="link"
                      onClick={() =>
                        window.open(reference.ReferenceDoc, "_blank")
                      }
                    >
                      View Document
                    </Button>
                  </div>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDeleteReference(reference.id)}
                  >
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Worker
