import { getDatabase, ref, push, onValue, update, remove, query, equalTo, orderByChild , orderByKey} from 'firebase/database';

class JobDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/job")
  }

  getTheJobById(jobID, callback) {
    try {
      const jobRef = ref(getDatabase(), "/job")
      console.log(jobRef)

      const jobQuery = query(jobRef, orderByKey(), equalTo(jobID))

      return onValue(jobQuery, snapshot => {
        let jobData = null
        const data = snapshot.val()
        if (data) {
          console.log("fxn data: " + data)
          jobData = { ...data[jobID], key: jobID }
        }
        callback(jobData)
      })
    } catch (error) {
      return callback(error)
    }
  }

  // Fetch a job by its jobID
  getJobById(jobID) {
    return new Promise((resolve, reject) => {
      const jobRef = ref(getDatabase(), "/job")
      const jobQuery = query(jobRef, orderByKey(), equalTo(jobID))
      onValue(
        jobQuery,
        snapshot => {
          let jobData = null
          const data = snapshot.val()
          if (data) {
            jobData = { ...data[jobID], key: jobID }
          }
          resolve(jobData)
        },
        reject
      )
    })
  }

  // getJobById(jobID) {
  //   return new Promise((resolve, reject) => {
  //     const jobRef = ref(getDatabase(), "/job")
  //     const jobQuery = query(jobRef, orderByKey(), equalTo(jobID))
  //     onValue(
  //       jobQuery,
  //       snapshot => {
  //         let jobData = null
  //         const data = snapshot.val()
  //         if (data) {
  //           jobData = { ...data[jobID], key: jobID }
  //         }
  //         resolve(jobData)
  //       },
  //       reject
  //     )
  //   })
  // }

  // getAllJobs() {
  //   return new Promise((resolve, reject) => {
  //     onValue(
  //       this.dbRef,
  //       snapshot => {
  //         const data = snapshot.val()
  //         let jobs = []
  //         if (data) {
  //           jobs = Object.keys(data).map(key => ({ key, ...data[key] }))
  //         }
  //         resolve(jobs)
  //       },
  //       reject
  //     )
  //   })
  // }

  // getAllJobs() {
  //   return new Promise((resolve, reject) => {
  //     // Query to order jobs by the 'createdAt' field
  //     const jobsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

  //     onValue(
  //       jobsByLatestRef,
  //       snapshot => {
  //         const data = snapshot.val()
  //         let jobs = []

  //         if (data) {
  //           // Convert object to an array and include the 'key' property
  //           jobs = Object.keys(data).map(key => ({ key, ...data[key] }))

  //           // Sort jobs by 'createdAt' in descending order (latest first)
  //           jobs.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
  //         }

  //         resolve(jobs) // Resolve with sorted jobs
  //       },
  //       reject // Handle any errors
  //     )
  //   })
  // }

  // Fetch all jobs
  getAllJobs() {
    return new Promise((resolve, reject) => {
      const jobsByLatestRef = query(this.dbRef, orderByChild("createdAt"))
      onValue(
        jobsByLatestRef,
        snapshot => {
          const data = snapshot.val()
          let jobs = []

          if (data) {
            jobs = Object.keys(data).map(key => ({ key, ...data[key] }))
            jobs.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
          }

          resolve(jobs)
        },
        reject
      )
    })
  }

  getJobsByCompanyName(companyName, callback) {
    const jobsByCompanyRef = query(
      this.dbRef,
      orderByChild("company"),
      equalTo(companyName)
    )

    return onValue(jobsByCompanyRef, snapshot => {
      const data = snapshot.val()
      let jobs = []
      if (data) {
        jobs = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(jobs)
    })
  }

  getJobsByEmailAndStatus(email, status, callback) {
    console.log("job status: " + JSON.stringify(status))

    const jobsByEmailRef = query(
      this.dbRef,
      orderByChild("email"),
      equalTo(email)
    )

    console.log("jobsByEmailRef: " + jobsByEmailRef)

    return onValue(jobsByEmailRef, snapshot => {
      const data = snapshot.val()

      console.log("jobdata: " + JSON.stringify(data))

      let jobs = []
      if (data) {
        jobs = Object.keys(data)
          .map(key => ({ key, ...data[key] }))
          .filter(job => job.jobstatus === status)
      }
      callback(jobs)
    })
  }

  getJobsByEmail(email, callback) {
    console.log("job status: " + JSON.stringify(status))

    const jobsByEmailRef = query(
      this.dbRef,
      orderByChild("email"),
      equalTo(email)
    )

    console.log("jobsByEmailRef: " + jobsByEmailRef)

    return onValue(jobsByEmailRef, snapshot => {
      const data = snapshot.val()

      console.log("jobdata: " + JSON.stringify(data))

      let jobs = []
      if (data) {
        jobs = Object.keys(data)
          .map(key => ({ key, ...data[key] }))
          // .filter(job => job.jobstatus === status)
      }
      callback(jobs)
    })
  }

  getJobCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAll(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      let job = []
      if (data) {
        job = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      callback(job)
    })
  }

  // createJob(job) {
  //   return push(this.dbRef, job).then(ref => {
  //     return ref.key // Return the key of the newly created job
  //   })
  // }

  // Create a job with the generated jobID as the key
  createJob(job) {
    const jobRef = ref(getDatabase(), `/job/${job.jobID}`)
    return update(jobRef, job).then(() => job.jobID) // Use update to set the jobID as the key
  }

  // updateJob(key, value) {
  //   const specificJobRef = ref(getDatabase(), `/job/${key}`)
  //   return update(specificJobRef, value)
  // }

  // Update a job by its key (which is the jobID)
  updateJob(key, value) {
    const specificJobRef = ref(getDatabase(), `/job/${key}`)
    return update(specificJobRef, value)
  }

  // Delete a job by its key
  deleteJob(key) {
    const specificJobRef = ref(getDatabase(), `/job/${key}`)
    return remove(specificJobRef)
  }
}

export default new JobDataService();
