import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { deleteObject } from "firebase/storage"

import { deleteDoc } from "firebase/firestore"; // For Firestore deletion

import OrganisationDataService from "services/OrganisationDataService"

import WorkersDataService from "services/WorkersDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { editProfile } from "store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import style from "./workermanager.css"
import { v4 as uuidv4 } from "uuid"
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import "./worker.css";
import logUserActivity  from "helpers/activityLogger";
import ActivityDataService from "./../../services/ActivityDataService";
import ActivityDetailModal from "../Activity/ActivityDetailModal";
// import { jobType } from "common/data"
import JobTypeDataService from "services/JobTypeDataService";
 

const WorkerManager = () => {
  const [worker, setWorker] = useState([])
  const [dropdownOpenStates, setDropdownOpenStates] = useState({})
  const [emailError, setEmailError] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [organisations, setOrganisations] = useState([])

  const [superadmin, setSuperAdmin] = useState(false)
  const [client, setClient] = useState(false)
  const [clientmanager, setClientManager] = useState(false)
  const [agencysuperadmin, setAgencySuperAdmin] = useState(false)

  const storage = getStorage()
  const firestore = getFirestore()

  const [currentOrg, setCurrentOrg] = useState({
    businessName: "",
    businessID: "",
  })

  useEffect(() => {
    if (!localStorage.getItem("authUserDetails")) {
      // console.log("No user details found...")
    } else {
      // console.log("LS " + localStorage.getItem("authUserDetails"))
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

        const company = userDetails.userCompany
        console.log("Company is: " + company)
        setCompanyName(company)

        if (userDetails.userType === "Super Admin") {
          console.log("setting as Super Admin.")

          //setSuperAdmin(true)
        } else {
          location.href = "/dashboard"
        }
      } else {
        console.log("No user details found in localStorage.")
      }
    }
  }, [])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(
      data => {
        setOrganisations(data)
      },
      [currentOrg]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  const [newCertificate, setNewCertificate] = useState({
    CertificateType: "",
    CertificateValidUntil: "",
    CertificateDoc: null,
  })


    const [newVaccination, setNewVaccination] = useState({
      VaccinationType: "",
      VaccinationValidUntil: "",
      VaccinationProduct: "",
      vaccinationClinic: "",
      VaccinationAllergies: "",
      VaccinationTreatments: "",
      VaccinationInfo: "",
      VaccinationDoc: null,
    })

        const [newJobReference, setNewJobReference] = useState({
          JobReferenceType: "",
          JobReferenceValidUntil: "",
          JobReferenceName: "",
          JobReferenceInfo: "",
          JobReferenceDoc: null,
        })

    const [newWork, setNewWork] = useState({
      WorkType: "",
      WorkValidUntil: "",
      WorkDoc: null,
    })

        const [newProof, setNewProof] = useState({
          ProofType: "",
          ProofValidUntil: "",
          ProofDoc: null,
        })

    const [newDisclosure, setNewDisclosure] = useState({
      DisclosureType: "",
      DisclosureValidUntil: "",
      DisclosureDoc: null,
    })

    const [newNationalInsurance, setNewNationalInsurance] = useState({
      NationalInsuranceType: "",
      NationalInsuranceValidUntil: "",
      NationalInsuranceDoc: null,
    })

  const [newCovid, setNewCovid] = useState({
    CovidType: "",
    CovidValidUntil: "",
    CovidDoc: null,
  })

  const [newPassport, setNewPassport] = useState({
    PassportType: "",
    PassportValidUntil: "",
    PassportDoc: null,
  })

  const [newCV, setNewCV] = useState({
    CVType: "",
    CVValidUntil: "",
    CVDoc: null,
  })

  const [newNI, setNewNI] = useState({
    NIType: "",
    NIValidUntil: "",
    NIDoc: null,
  })

  const [newDBS, setNewDBS] = useState({
    DBSType: "",
    DBSValidUntil: "",
    DBSDoc: null,
  })

  const [newWorkPermit, setNewWorkPermit] = useState({
    WorkPermitType: "",
    WorkPermitValidUntil: "",
    WorkPermitDoc: null,
  })

  const [newProofOfAddress, setNewProofOfAddress] = useState({
    ProofOfAddressType: "",
    ProofOfAddressValidUntil: "",
    ProofOfAddressDoc: null,
  })

  const [selectedOrg, setSelectedOrg] = useState("")

  const handleSelectChange = e => {
    setSelectedOrg(e.target.value)
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  const [certificates, setCertificates] = useState([])

  const [vaccinations, setVaccinations] = useState([])

  const [jobreferences, setJobreferences] = useState([])

  const [works, setWorks] = useState([])

  const [proofs, setProofs] = useState([])

  const [disclosures, setDisclosures] = useState([])

  const [nationalinsurances, setNationalInsurances] = useState([])

  const [nis, setNIs] = useState([])

  const [passports, setPassports] = useState([])

  const [cvs, setCVs] = useState([])

  const [covids, setCovids] = useState([])

  const [dbss, setDBSs] = useState([])

  const [workpermits, setWorkPermits] = useState([])

  const [proofOfAddress, setProofOfAddress] = useState([]) // Initializing as an empty array

  const [currentWorker, setCurrentWorker] = useState({
    firstName: "",
    lastName: "",
    alsoKnownAs: "",
    workerEmailAddress: "",
    emailError: "",
    dateOfBirth: null,
    gender: "",
    phoneNumber: "",
    country: "",
    postcode: "",
    residentialAddress: "",
    howlongintheaddress: "",
    fromDuration: "",
    toDuration: "",
    proofOfAddress: "",
    vatNumber: "",
    status: "",
    workerID: "",

    //Documents
    cv: "",
    covid: "",
    nationalInsurance: "",
    referenceProof: "",
    passport: "",
    passportexpiryDate: null,
    proofOfAddress: "",
    // dbs: "",
    // dbsexpiryDate: null,
    visa_workpermit: "",
    visa_workpermitDate: null,

    //Certificates
    totalUKCareExperienceinYears: 0,
    totalUKCareExperienceinMonths: 0,
    Certificates: [],

    Disclosures: [],

    Works: [],

    Proofs: [],

    NationalInsurances: [],

    Passports: [],

    CVs: [],

    Covid: [],

    NIs: [],

    DBSs: [],

    WorkPermits: [],

    ProofOfAddress: [],

    //Emergency
    emergencyContactName: "",
    emergencyRelationship: "",
    emergencyPriContact: "",
    emergencySecContact: "",

    //Vaccination
    vaccinationType: "",
    vaccinationDate: null,
    vaccinationProductName: "",
    vaccinationClinicSite: "",
    vaccinationIsCovid: false,
    vaccinationDoc: null,
    vaccinationAllergy: "",
    vaccinationTreatment: "",
    vaccinationOtherInfo: "",

    //Reference
    referenceType: "",
    referenceDateDate: null,
    referenceName: "",
    referenceDoc: null,
    referenceOtherInfo: "",

    ReferenceChecked: false,
    jobType: "",

    createdAt: Date.now(),
    provider: "",
    jobType: "Support worker",
    vacAgreements: [],
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)
  const [isCertModalOpen, setIsCertModalOpen] = useState(false)
  const [isVaccinationModalOpen, setIsVaccinationModalOpen] = useState(false)
  const [isJobReferenceModalOpen, setIsJobReferenceModalOpen] = useState(false)
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false)
  const [isProofModalOpen, setIsProofModalOpen] = useState(false)
  const [isDisclosureModalOpen, setIsDisclosureModalOpen] = useState(false)
  const [isNationalInsuranceModalOpen, setIsNationalInsuranceModalOpen] = useState(false)
  const [isPassportModalOpen, setIsPasspportModalOpen] = useState(false)
  const [isCVModalOpen, setIsCVModalOpen] = useState(false)
  const [isCovidModalOpen, setIsCovidModalOpen] = useState(false)
  const [isNIModalOpen, setIsNIModalOpen] = useState(false)
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false)
  const [isVacModalOpen, setIsVacModalOpen] = useState(false)
  const [isRefModalOpen, setIsRefModalOpen] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [jobTypes, setJobTypes] = useState([])

  const now = new Date()
  const date = now.toLocaleDateString()
  const time = now.toLocaleTimeString()

  const [searchQuery, setSearchQuery] = useState("") // To track search input
  const [filterType, setFilterType] = useState("") // To track selected activity type
  const [sortOrder, setSortOrder] = useState("asc") // To track sort order (asc or desc)

  useEffect(() => {
    const unsubscribe = WorkersDataService.getAll(
      data => {
        setWorker(data)
      },
      [currentWorker]
    )

    return () => unsubscribe && unsubscribe()
  }, [currentWorker])

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const fetchedJobTypes = await JobTypeDataService.getAll()

        // Remove duplicates
        const uniqueJobTypes = []
        const jobTypeNames = new Set()

        fetchedJobTypes.forEach(jobType => {
          if (!jobTypeNames.has(jobType.name)) {
            jobTypeNames.add(jobType.name)
            uniqueJobTypes.push(jobType)
          }
        })

        setJobTypes(uniqueJobTypes)
      } catch (error) {
        console.error("Error fetching job types: ", error)
      }
    }

    fetchJobTypes()
  }, [])

  useEffect(() => {
    const fetchCertificates = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, "workers", currentWorker.workerID, "certificates")
      )

      try {
        const querySnapshot = await getDocs(q)
        const fetchedCertificates = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setCertificates(fetchedCertificates)
      } catch (error) {
        console.error("Error fetching certificates: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchCertificates()
    }
  }, [currentWorker])

    useEffect(() => {
      const fetchVaccinations = async () => {
        const db = getFirestore()
        const q = query(
          collection(db, "workers", currentWorker.workerID, "vaccinations")
        )

        try {
          const querySnapshot = await getDocs(q)
          const fetchedVaccinations = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          setVaccinations(fetchedVaccinations)
        } catch (error) {
          console.error("Error fetching vaccinations: ", error)
        }
      }

      if (currentWorker && currentWorker.workerID) {
        fetchVaccinations()
      }
    }, [currentWorker])

        useEffect(() => {
          const fetchJobReferences = async () => {
            const db = getFirestore()
            const q = query(
              collection(db, "workers", currentWorker.workerID, "jobreferences")
            )

            try {
              const querySnapshot = await getDocs(q)
              const fetchedJobReferences = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }))
              setJobreferences(fetchedJobReferences)
            } catch (error) {
              console.error("Error fetching job references: ", error)
            }
          }

          if (currentWorker && currentWorker.workerID) {
            fetchJobReferences()
          }
        }, [currentWorker])

    useEffect(() => {
      const fetchWorks= async () => {
        const db = getFirestore()
        const q = query(
          collection(db, "workers", currentWorker.workerID, "works")
        )

        try {
          const querySnapshot = await getDocs(q)
          const fetchedWorks = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          setWorks(fetchedWorks)
        } catch (error) {
          console.error("Error fetching works: ", error)
        }
      }

      if (currentWorker && currentWorker.workerID) {
        fetchWorks()
      }
    }, [currentWorker])

      useEffect(() => {
        const fetchProofs = async () => {
          const db = getFirestore()
          const q = query(
            collection(db, "workers", currentWorker.workerID, "proofs")
          )

          try {
            const querySnapshot = await getDocs(q)
            const fetchedProofs = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }))
            setProofs(fetchedProofs)
          } catch (error) {
            console.error("Error fetching proofs: ", error)
          }
        }

        if (currentWorker && currentWorker.workerID) {
          fetchProofs()
        }
      }, [currentWorker])

    useEffect(() => {
      const fetchDisclosures = async () => {
        const db = getFirestore()
        const q = query(
          collection(db, "workers", currentWorker.workerID, "disclosures")
        )

        try {
          const querySnapshot = await getDocs(q)
          const fetchedDisclosures = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          setDisclosures(fetchedDisclosures)
        } catch (error) {
          console.error("Error fetching disclosures: ", error)
        }
      }

      if (currentWorker && currentWorker.workerID) {
        fetchDisclosures()
      }
    }, [currentWorker])


    useEffect(() => {
      const fetchNationalInsurances = async () => {
        const db = getFirestore()
        const q = query(
          collection(db, "workers", currentWorker.workerID, "nationalinsurances")
        )

        try {
          const querySnapshot = await getDocs(q)
          const fetchedNationalInsurances = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          setNationalInsurances(fetchedNationalInsurances)
        } catch (error) {
          console.error("Error fetching National Insurances: ", error)
        }
      }

      if (currentWorker && currentWorker.workerID) {
        fetchNationalInsurances()
      }
    }, [currentWorker])


  useEffect(() => {
    const fetchPassports = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, "workers", currentWorker.workerID, "passports")
      )

      try {
        const querySnapshot = await getDocs(q)
        const fetchedPassports = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setPassports(fetchedPassports)
      } catch (error) {
        console.error("Error fetching passports: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchPassports()
    }
  }, [currentWorker])

  useEffect(() => {
    const fetchCVs = async () => {
      const db = getFirestore()
      const q = query(collection(db, "workers", currentWorker.workerID, "cvs"))

      try {
        const querySnapshot = await getDocs(q)
        const fetchedCVs = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setCVs(fetchedCVs)
      } catch (error) {
        console.error("Error fetching cv: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchCVs()
    }
  }, [currentWorker])

  useEffect(() => {
    const fetchCovids = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, "workers", currentWorker.workerID, "covids")
      )

      try {
        const querySnapshot = await getDocs(q)
        const fetchedCovids = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setCovids(fetchedCovids)
      } catch (error) {
        console.error("Error fetching covids: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchCovids()
    }
  }, [currentWorker])
 

  useEffect(() => {
    // console.log(`${currentWorker.firstName} ${currentWorker.lastName}`)
    if (currentWorker && currentWorker.firstName && currentWorker.lastName) {
      ActivityDataService.getAll()
        .then(fetchedActivities => {
          const filteredActivities = fetchedActivities.filter(
            activity =>
              activity.activityType === "Worker" &&
              activity.worker ===
                `${currentWorker.firstName}.${currentWorker.lastName}`
          )
          filteredActivities.sort((a, b) => new Date(b.date) - new Date(a.date))
          setActivities(filteredActivities)
          setLoading(false)
        })
        .catch(err => {
          setError("Failed to fetch activities")
          setLoading(false)
        })
    }
  }, [currentWorker])

  const handleActivityClick = activity => {
    setSelectedActivity(activity)
    setModalOpen(false)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)

    if (isModalOpen) {
      resetForm()
    }
  }

  const toggleCertificateModal = () => {
    setIsCertModalOpen(!isCertModalOpen)

    if (isCertModalOpen) {
      resetCertForm()
    }
  }

    const toggleVaccinationModal = () => {
      setIsVaccinationModalOpen(!isVaccinationModalOpen)

      if (isVaccinationModalOpen) {
        resetVaccinationForm()
      }
    }

       const toggleJobReferenceModal = () => {
         setIsJobReferenceModalOpen(!isJobReferenceModalOpen)

         if (isJobReferenceModalOpen) {
           resetJobReferenceForm()
         }
       }


      const toggleWorkModal = () => {
        setIsWorkModalOpen(!isWorkModalOpen)

        if (isWorkModalOpen) {
          resetWorkForm()
        }
      }

    const toggleProofModal = () => {
      setIsProofModalOpen(!isProofModalOpen)

      if (isProofModalOpen) {
        resetProofForm()
      }
    }

    const toggleDisclosureModal = () => {
      setIsDisclosureModalOpen(!isDisclosureModalOpen)

      if (isDisclosureModalOpen) {
        resetDisclosureForm()
      }
    }

    const toggleNationalInsuranceModal = () => {
      setIsNationalInsuranceModalOpen(!isNationalInsuranceModalOpen)

      if (isNationalInsuranceModalOpen) {
        resetNationalInsuranceForm()
      }
    }

  const toggleCovidModal = () => {
    setIsCovidModalOpen(!isCovidModalOpen)

    if (isCovidModalOpen) {
      resetCovidForm()
    }
  }

  const togglePassportModal = () => {
    setIsPasspportModalOpen(!isPassportModalOpen)

    if (isPassportModalOpen) {
      resetPassportForm()
    }
  }

  const toggleCVModal = () => {
    setIsCVModalOpen(!isCVModalOpen)

    if (isCVModalOpen) {
      resetCVForm()
    }
  }

  const toggleNIModal = () => {
    setIsNIModalOpen(!isNIModalOpen)

    if (isNIModalOpen) {
      resetNIForm()
    }
  }


  const filteredAndSortedActivities = activities
    .filter(activity => {
      // Filter by search query and type
      const matchesSearch = activity.activity
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
      const matchesType = filterType
        ? activity.activityType === filterType
        : true
      return matchesSearch && matchesType
    })
    .sort((a, b) => {
      // Sort by date
      const dateA = new Date(a.date)
      const dateB = new Date(b.date)
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA
    })

  const toggleEmergencyModal = () => {
    setIsEmergencyModalOpen(!isEmergencyModalOpen)

    if (isEmergencyModalOpen) {
      resetEmergencyForm()
    }
  }

  const toggleVacModal = () => {
    setIsVacModalOpen(!isVacModalOpen)

    if (isVacModalOpen) {
      resetVacForm()
    }
  }

  const handleDeleteVaccinationDoc = async docUrl => {
    try {
      // 1. Delete the vaccination document from Firebase Storage
      const storageRef = ref(storage, docUrl)
      await deleteObject(storageRef)
      console.log(
        `Deleted vaccination document from Firebase Storage: ${docUrl}`
      )

      // 2. Update the worker's state to remove the document reference
      setCurrentWorker(prevWorker => ({
        ...prevWorker,
        vaccinationDoc: null, // Remove the document reference
      }))

      // Optionally, you can update the worker data in Firestore as well
      if (currentWorker.workerID) {
        const updatedWorkerData = { ...currentWorker, vaccinationDoc: null }
        await WorkersDataService.updateWorker(
          currentWorker.workerID,
          updatedWorkerData
        )
      }

      console.log("Vaccination document deleted successfully.")
    } catch (error) {
      console.error(`Error deleting vaccination document: ${error.message}`)
    }
  }

  const toggleRefModal = () => {
    setIsRefModalOpen(!isRefModalOpen)

    if (isRefModalOpen) {
      resetRefForm()
    }
  }

  const toggleAuditModal = () => {
    setIsAuditModalOpen(!isAuditModalOpen)

    if (isAuditModalOpen) {
      resetAuditForm()
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "workerID") {
      const fixedPrefix = currentWorker.workerID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentWorker({ ...currentWorker, [name]: value })
      }
    } else if (name === "provider") {
      currentWorker.provider = company
      setCurrentWorker(prevWorker => ({ ...prevWorker, provider: company }))
    } else {
      setCurrentWorker({ ...currentWorker, [name]: value })
    }
  }

  const handleEmailInputChange = event => {
    const { name, value } = event.target

    if (name === "workerEmailAddress") {
      if (!validateEmail(value)) {
        setEmailError("Invalid email address")
        return
      } else {
        setEmailError("")
      }
    }
    setCurrentWorker({ ...currentWorker, [name]: value })
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(String(email).toLowerCase())
  }

  const handleFileChange = async (event, docType) => {
    const { files } = event.target
    const workerID = currentWorker.workerID // Ensure the workerID is available
    const storage = getStorage()

    // Define the paths for each document type
    const getPathForDocType = docType => {
      switch (docType) {
        case "cv":
          return `cv/`
        case "covid":
          return `covid/`
        case "nationalInsurance":
          return `nationalInsurance/`
        case "passport":
          return `passport/`
        case "dbs":
          return `dbs/`
        case "visa_workpermit":
          return `visa_workpermit/`
        case "proofOfAddress":
          return `proofOfAddress/`
        default:
          return `others/` // fallback path
      }
    }

    // Function to upload a document to Firebase and return its public download URL
    const uploadDocument = async (file, path) => {
      const storageRef = ref(storage, `workers/${workerID}/${path}`)

      // Upload file
      await uploadBytes(storageRef, file)

      // Get public download URL
      const downloadURL = await getDownloadURL(storageRef)
      return downloadURL // Return the public URL
    }

    // Get the correct path for the document type
    const pathForDocType = getPathForDocType(docType)

    // Upload the files and get the URLs
    const newDocs = await Promise.all(
      Array.from(files).map(async file => {
        const fileNameWithExtension = `${uuidv4()}-${file.name}` // Include file extension
        const url = await uploadDocument(
          file,
          `${pathForDocType}${fileNameWithExtension}`
        ) // Upload and get the public URL
        return { name: file.name, url } // Return the file name and public URL
      })
    )

    // Update the worker's state with the uploaded document URLs
    setCurrentWorker(prevWorker => ({
      ...prevWorker,
      [docType]: newDocs[0]?.url || null, // Assign the public URL to the specific document type
    }))

    console.log(`${docType} Uploaded Document:`, newDocs)
  }

  const handleVacFileChange = async event => {
    const { files } = event.target
    if (files.length > 0) {
      const file = files[0]
      const fileExtension = file.name.split(".").pop() // Get file extension
      const fileId = uuidv4() // Generate a unique ID for this agreement
      const storageRef = ref(
        storage,
        `vacs/${currentWorker.workerID}/worker-${fileId}.${fileExtension}`
      )

      await uploadBytes(storageRef, file)
      const downloadURL = await getDownloadURL(storageRef)

      // Add the new agreement to the array
      setCurrentWorker(prevState => ({
        ...prevState,
        vacAgreements: [
          ...(prevState.vacAgreements || []), // Default to an empty array if undefined
          { id: fileId, url: downloadURL, name: file.name },
        ],
      }))
    }
  }



  const submitRefHandler = async e => {
    e.preventDefault()
    const { workerID, referenceDocs, ...workerData } = currentWorker

    try {
      if (workerID) {
        const uploadedDocs = await Promise.all(
          referenceDocs.map(async doc => {
            if (doc.file) {
              const storageRef = ref(
                storage,
                `references/${workerID}/${uuidv4()}`
              )
              await uploadBytes(storageRef, doc.file)
              const url = await getDownloadURL(storageRef)
              return { name: doc.name, url } // Save name and URL
            }
            return doc // If already uploaded, keep existing reference
          })
        )

        const updatedData = { ...workerData, referenceDocs: uploadedDocs }

        await WorkersDataService.updateWorker(workerID, updatedData)
        resetRefForm()
        toggleRefModal()
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const handleDeleteReferenceDoc = async (docUrl, index) => {
    try {
      // Remove from Firebase storage
      const storageRef = ref(storage, docUrl)
      await deleteObject(storageRef)

      // Update the state to remove the document
      setCurrentWorker(prevWorker => {
        const updatedDocs = prevWorker.referenceDocs.filter(
          (doc, i) => i !== index
        )
        return { ...prevWorker, referenceDocs: updatedDocs }
      })

      console.log("Document deleted successfully.")
    } catch (error) {
      console.error("Error deleting document:", error.message)
    }
  }

  function getValidDate(date) {
    if (!date) return null

    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }

  const handleBirthExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dateOfBirth: date })
    }
  }

  const handleDBSExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dbsexpiryDate: date })
    }
  }

  const handleVisaWorkpermitDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, visa_workpermitDate: date })
    }
  }

  const handlePassportExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, passportexpiryDate: date })
    }
  }

  const generateWorkerID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `WOR-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    if (!currentWorker.workerID) {
      console.log(`${JSON.stringify(currentWorker)}`)
      setCurrentWorker(prevWorker => ({
        ...prevWorker,
        workerID: generateWorkerID(),
        companyName: companyName,
      }))
    }
  }, [currentWorker])

  const handleDeleteCertificate = async certificate => {
    try {
      // 1. Delete the certificate from Firebase Storage
      const storageRef = ref(storage, certificate.CertificateDoc)
      await deleteObject(storageRef)
      console.log(`Deleted certificate file: ${certificate.CertificateDoc}`)

      // 2. Remove the certificate from Firestore
      const db = getFirestore()
      const certDocRef = doc(
        db,
        "workers",
        currentWorker.workerID,
        "certificates",
        certificate.id
      )
      await deleteDoc(certDocRef)
      console.log(`Deleted certificate entry from Firestore: ${certificate.id}`)

      // 3. Update local state to remove the deleted certificate
      setCertificates(prevCertificates =>
        prevCertificates.filter(cert => cert.id !== certificate.id)
      )

      // Optionally, log the activity
      logUserActivity({
        date: date,
        time: time,
        activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s certificate deleted successfully.`,
        activityType: "Worker",
        worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
      })

      console.log("Certificate deleted successfully")
    } catch (error) {
      console.error(`Error deleting certificate: ${error.message}`)
    }
  }

    const handleDeleteVaccination = async vaccination => {
      try {
        // 1. Delete the certificate from Firebase Storage
        const storageRef = ref(storage, vaccination.VaccinationDoc)
        await deleteObject(storageRef)
        console.log(`Deleted certificate file: ${vaccination.VaccinationDoc}`)

        // 2. Remove the certificate from Firestore
        const db = getFirestore()
        const certDocRef = doc(
          db,
          "workers",
          currentWorker.workerID,
          "vaccinations",
          vaccination.id
        )
        await deleteDoc(certDocRef)
        console.log(
          `Deleted vaccination entry from Firestore: ${vaccination.id}`
        )

        // 3. Update local state to remove the deleted certificate
        setVaccinations(prevVaccination =>
          prevVaccination.filter(vacc => vacc.id !== vaccination.id)
        )

        // Optionally, log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s vaccination deleted successfully.`,
          activityType: "Worker",
          worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
        })

        console.log("Vaccination deleted successfully")
      } catch (error) {
        console.error(`Error deleting vaccination: ${error.message}`)
      }
    }

       const handleDeleteJobReference = async jobreference => {
         try {
           // 1. Delete the jobreference from Firebase Storage
           const storageRef = ref(storage, jobreference.JobReferenceDoc)
           await deleteObject(storageRef)
           console.log(
             `Deleted jobreference file: ${jobreference.JobReferenceDoc}`
           )

           // 2. Remove the prevJobReference from Firestore
           const db = getFirestore()

           const certDocRef = doc(
             db,
             "workers",
             currentWorker.workerID,
             "jobreferences",
             jobreference.id
           )
           await deleteDoc(certDocRef)

           console.log(
             `Deleted vaccination entry from Firestore: ${jobreference.id}`
           )

           // 3. Update local state to remove the deleted prevJobReference
           setJobreferences(prevJobReference =>
             prevJobReference.filter(jref => jref.id !== jobreference.id)
           )

           // Optionally, log the activity
           logUserActivity({
             date: date,
             time: time,
             activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s jobreference deleted successfully.`,
             activityType: "Worker",
             worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
           })

           console.log("jobreference deleted successfully")
         } catch (error) {
           console.error(`Error deleting jobreference: ${error.message}`)
         }
       }

    const handleDeleteWork = async work => {
      try {
         const storageRef = ref(storage, work.WorkDoc)
        await deleteObject(storageRef)
        console.log(`Deleted work file: ${work.WorkDoc}`)

        const db = getFirestore()
        const workDocRef = doc(
          db,
          "workers",
          currentWorker.workerID,
          "works",
          work.id
        )
        await deleteDoc(workDocRef)
        console.log(`Deleted work entry from Firestore: ${work.id}`)

        setWorks(prevWorks => prevWorks.filter(wor => wor.id !== work.id))

        // Optionally, log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s work permit deleted successfully.`,
          activityType: "Worker",
          worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
        })

        console.log("Work permit deleted successfully")
      } catch (error) {
        console.error(`Error deleting work permit: ${error.message}`)
      }
    }


        const handleDeleteProof = async proof => {
          try {
            const storageRef = ref(storage, proof.ProofDoc)
            await deleteObject(storageRef)
            console.log(`Deleted proof file: ${proof.ProofDoc}`)

            const db = getFirestore()
            const proofDocRef = doc(
              db,
              "workers",
              currentWorker.workerID,
              "proofs",
              proof.id
            )
            await deleteDoc(proofDocRef)
            console.log(`Deleted proof entry from Firestore: ${proof.id}`)

            setProofs(prevProofs => prevProofs.filter(pro => pro.id !== proof.id))

            // Optionally, log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s proof deleted successfully.`,
              activityType: "Worker",
              worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
            })

            console.log("Proof deleted successfully")
          } catch (error) {
            console.error(`Error deleting proof: ${error.message}`)
          }
        }



    const handleDeleteDisclosure = async disclosure => {
      try {

        const storageRef = ref(storage, disclosure.DisclosureDoc)
        await deleteObject(storageRef)

        const db = getFirestore()
        const disclosureDocRef = doc(
          db,
          "workers",
          currentWorker.workerID,
          "disclosures",
          disclosure.id
        )
        await deleteDoc(disclosureDocRef)
        console.log(`Deleted disclosure entry from Firestore: ${disclosure.id}`)

        setDisclosures(prevDisclosures =>
          prevDisclosures.filter(disc => disc.id !== disclosure.id)
        )

        // Optionally, log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s disclosure deleted successfully.`,
          activityType: "Worker",
          worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
        })

        console.log("Disclosure deleted successfully")
      } catch (error) {
        console.error(`Error deleting disclosure: ${error.message}`)
      }
    }

  
    const handleDeleteNationalInsurance = async nationalinsurance => {
      try {
        // 1. Delete the nationalinsurance from Firebase Storage
        const storageRef = ref(storage, nationalinsurance.NationalInsuranceDoc)
        await deleteObject(storageRef)
        console.log(
          `Deleted national insurance file: ${nationalinsurance.NationalInsuranceDoc}`
        )
        const db = getFirestore()
        const certDocRef = doc(
          db,
          "workers",
          currentWorker.workerID,
          "nationalinsurances",
          nationalinsurance.id
        )
        await deleteDoc(certDocRef)
        console.log(
          `Deleted ni entry from Firestore: ${nationalinsurance.id}`
        )
        setNationalInsurances(prevNationalInsurances =>
          prevNationalInsurances.filter(
            nat => nat.id !== nationalinsurance.id
          )
        )

        // Optionally, log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s nationalinsurance deleted successfully.`,
          activityType: "Worker",
          worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
        })

        console.log("nationalinsurance deleted successfully")
      } catch (error) {
        console.error(`Error deleting nationalinsurance: ${error.message}`)
      }
    }

  const handleDeletePassport = async passport => {
    try {

      const storageRef = ref(storage, passport.PassportDoc)
      await deleteObject(storageRef)
      console.log(`Deleted passport file: ${passport.PassportDoc}`)

      const db = getFirestore()
      const passportDocRef = doc(
        db,
        "workers",
        currentWorker.workerID,
        "passports",
        passport.id
      )
      await deleteDoc(passportDocRef)
      console.log(`Deleted passport entry from Firestore: ${passport.id}`)

      setPassports(prevPassports =>
        prevPassports.filter(pass => pass.id !== passport.id)
      )

      // Optionally, log the activity
      logUserActivity({
        date: date,
        time: time,
        activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s passport deleted successfully.`,
        activityType: "Worker",
        worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
      })

      console.log("Passport deleted successfully")
    } catch (error) {
      console.error(`Error deleting passport: ${error.message}`)
    }
  }

  const handleDeleteCV = async cv => {
    try {
      const storageRef = ref(storage, cv.CVDoc)
      await deleteObject(storageRef)
      console.log(`Deleted cv file: ${cv.CVDoc}`)

      const db = getFirestore()
      const cvDocRef = doc(db, "workers", currentWorker.workerID, "cvs", cv.id)
      await deleteDoc(cvDocRef)
      console.log(`Deleted cv entry from Firestore: ${cv.id}`)

      setCVs(prevCVs => prevCVs.filter(cv => cv.id !== cv.id))

      // Optionally, log the activity
      logUserActivity({
        date: date,
        time: time,
        activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s cv deleted successfully.`,
        activityType: "Worker",
        worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
      })

      console.log("CV deleted successfully")
    } catch (error) {
      console.error(`Error deleting cv: ${error.message}`)
    }
  }

  const handleDeleteCovid = async covid => {
    try {
      // 1. Delete the covid from Firebase Storage
      const storageRef = ref(storage, covid.CovidDoc)
      await deleteObject(storageRef)
      console.log(`Deleted covid file: ${covid.CovidDoc}`)

      const db = getFirestore()
      const covidDocRef = doc(
        db,
        "workers",
        currentWorker.workerID,
        "covids",
        covid.id
      )
      await deleteDoc(covidDocRef)
      console.log(`Deleted covid entry from Firestore: ${covid.id}`)

      setCovids(prevCovids => prevCovids.filter(cov => cov.id !== covid.id))

      // Optionally, log the activity
      logUserActivity({
        date: date,
        time: time,
        activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s covid deleted successfully.`,
        activityType: "Worker",
        worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
      })

      console.log("Covid deleted successfully")
    } catch (error) {
      console.error(`Error deleting covid: ${error.message}`)
    }
  }



  const submitHandler = event => {
    event.preventDefault()
    const { workerID, ...workerData } = currentWorker

    workerData.companyID = selectedOrg // Make sure selectedOrg is the company ID
    workerData.provider = selectedOrg

    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })
        resetForm()
        toggleModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s record created successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })
        resetForm()
        toggleModal()
      })
    }
  }

  const submitCertHandler = async e => {
    e.preventDefault()

    // Validate required fields
    if (
      !newCertificate.CertificateType ||
      !newCertificate.CertificateValidUntil ||
      !newCertificate.CertificateDoc
    ) {
      alert(
        "Certificate Doc, Certificate Type and Certificate Valid Until are required."
      )

      console.error(
        "Certificate Doc, Certificate Type and Certificate Valid Until are required."
      )
      return
    }

    const { workerID, ...workerData } = currentWorker

    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `certificates/${workerID}/${newCertificate.CertificateDoc.name}`
    )

    await uploadBytes(storageRef, newCertificate.CertificateDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const certificateData = {
          CertificateType: newCertificate.CertificateType,
          CertificateValidUntil: newCertificate.CertificateValidUntil,
          CertificateDoc: downloadURL,
        }

        try {
          const workerCertsRef = collection(
            db,
            "workers",
            workerID,
            "certificates"
          )

          try {
            const docRef = await addDoc(workerCertsRef, certificateData)

            // Log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${workerData.firstName} ${workerData.lastName}'s certificate updated successfully.`,
              activityType: "Worker",
              worker: `${workerData.firstName}.${workerData.lastName}`,
            })

            setCertificates(prev => [
              ...prev,
              { id: docRef.id, ...certificateData },
            ])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewCertificate({
            CertificateType: "",
            CertificateValidUntil: "",
            CertificateDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }


      const submitVaccinationHandler = async e => {
        e.preventDefault()

        // Validate required fields
        if (
          !newVaccination.VaccinationType ||
          !newVaccination.VaccinationValidUntil ||
          !newVaccination.VaccinationDoc
        ) {
              alert(
                "Vaccination Doc, Vaccination Type and Vaccination Valid Until are required."
              )

          console.error("Vaccination Doc, Type and Vaccination Valid Until are required.")
          return
        }

        const { workerID, ...workerData } = currentWorker
        const db = getFirestore()
        const storage = getStorage()

        const storageRef = ref(
          storage,
          `vaccinations/${workerID}/${newVaccination.VaccinationDoc.name}`
        )

        await uploadBytes(storageRef, newVaccination.VaccinationDoc)
          .then(async snapshot => {
            const downloadURL = await getDownloadURL(snapshot.ref)

            const vaccinationData = {
              VaccinationType: newVaccination.VaccinationType,
              VaccinationValidUntil: newVaccination.VaccinationValidUntil,
              VaccinationAllergies: newVaccination.VaccinationAllergies,
              VaccinationInfo: newVaccination.VaccinationInfo,
              VaccinationProduct: newVaccination.VaccinationProduct,
              VaccinationTreatments: newVaccination.VaccinationTreatments,
              VaccinationDoc: downloadURL,
            }

            try {
              const workerCertsRef = collection(
                db,
                "workers",
                workerID,
                "vaccinations"
              )

              try {
                const docRef = await addDoc(workerCertsRef, vaccinationData)

                // Log the activity
                logUserActivity({
                  date: date,
                  time: time,
                  activity: `Worker ${workerData.firstName} ${workerData.lastName}'s vaccination updated successfully.`,
                  activityType: "Worker",
                  worker: `${workerData.firstName}.${workerData.lastName}`,
                })

                setVaccinations(prev => [
                  ...prev,
                  { id: docRef.id, ...vaccinationData },
                ])
              } catch (error) {
                console.error("Error updating Firestore: ", error)
              }

              // Reset form
              setNewVaccination({
                VaccinationType: "",
                VaccinationValidUntil: "",
                VaccinationAllergies: "",
                VaccinationInfo: "",
                VaccinationProduct: "",
                VaccinationTreatments: "",
                VaccinationDoc: null,
              })
            } catch (error) {
              console.error("Error updating Firestore: ", error)
            }
          })
          .catch(error => {
            console.error("Error uploading file: ", error)
          })
      }

      const submitJobReferenceHandler = async e => {
        e.preventDefault()

        // Validate required fields
        if (
          !newJobReference.JobReferenceType ||
          !newJobReference.JobReferenceValidUntil ||
          !newJobReference.JobReferenceDoc
        ) {
          alert(
            "Job Reference Doc, Job Reference Type and Job Reference Valid Until are required."
          )

          console.error(
            "Job Reference Doc, Job Reference Type and Job Reference Valid Until are required."
          )
          return
        }

        const { workerID, ...workerData } = currentWorker

        const db = getFirestore()
        const storage = getStorage()

        const storageRef = ref(
          storage,
          `jobreferences/${workerID}/${newJobReference.JobReferenceDoc.name}`
        )

        await uploadBytes(storageRef, newJobReference.JobReferenceDoc)
          .then(async snapshot => {
            const downloadURL = await getDownloadURL(snapshot.ref)

            const jobreferenceData = {
              JobReferenceType: newJobReference.JobReferenceType,
              JobReferenceValidUntil: newJobReference.JobReferenceValidUntil,
              JobReferenceInfo: newJobReference.JobReferenceInfo,
              JobReferenceName: newJobReference.JobReferenceName,
              JobReferenceDoc: downloadURL,
            }

            try {
              const workerCertsRef = collection(
                db,
                "workers",
                workerID,
                "jobreferences"
              )

              try {
                const docRef = await addDoc(workerCertsRef, jobreferenceData)

                // Log the activity
                logUserActivity({
                  date: date,
                  time: time,
                  activity: `Worker ${workerData.firstName} ${workerData.lastName}'s job reference updated successfully.`,
                  activityType: "Worker",
                  worker: `${workerData.firstName}.${workerData.lastName}`,
                })

                setJobreferences(prev => [
                  ...prev,
                  { id: docRef.id, ...jobreferenceData },
                ])
              } catch (error) {
                console.error("Battling with updating of firestore: ", error)
              }

              setNewJobReference({
                JobReferenceType: "",
                JobReferenceValidUntil: "",
                JobReferenceInfo: "",
                JobReferenceName: "",
                JobReferenceDoc: null,
              })
            } catch (error) {
              console.error("Error updating firestore: ", error)
            }
          })
          .catch(error => {
            console.error("Error uploading file: ", error)
          })
      }


    const submitWorkHandler = async e => {
      e.preventDefault()

      // Validate required fields
      if (
        !newWork.WorkType ||
        !newWork.WorkValidUntil ||
        !newWork.WorkDoc
      ) {
        alert("Work Doc, Work Type and Work Valid Until are required.")

        console.error("Work Doc, Work Type and Work Valid Until are required.")
        return
      }

      const { workerID, ...workerData } = currentWorker

      const db = getFirestore()
      const storage = getStorage()

      const storageRef = ref(
        storage,
        `works/${workerID}/${newWork.WorkDoc.name}`
      )

      await uploadBytes(storageRef, newWork.WorkDoc)
        .then(async snapshot => {
          const downloadURL = await getDownloadURL(snapshot.ref)

          const workData = {
            WorkType: newWork.WorkType,
            WorkValidUntil: newWork.WorkValidUntil,
            WorkDoc: downloadURL,
          }

          try {
            const workerWorksRef = collection(db, "workers", workerID, "works")

            try {
              const docRef = await addDoc(workerWorksRef, workData)

              // Log the activity
              logUserActivity({
                date: date,
                time: time,
                activity: `Worker ${workerData.firstName} ${workerData.lastName}'s work permit updated successfully.`,
                activityType: "Worker",
                worker: `${workerData.firstName}.${workerData.lastName}`,
              })

              setWorks(prev => [...prev, { id: docRef.id, ...workData }])
            } catch (error) {
              console.error("Battling with updating of firestore: ", error)
            }

            setNewWork({
              WorkType: "",
              WorkValidUntil: "",
              WorkDoc: null,
            })
          } catch (error) {
            console.error("Error updating firestore: ", error)
          }
        })
        .catch(error => {
          console.error("Error uploading file: ", error)
        })
    }

        const submitProofHandler = async e => {
          e.preventDefault()

          // Validate required fields
          if (
            !newProof.ProofType ||
            !newProof.ProofValidUntil ||
            !newProof.ProofDoc
          ) {
            alert(
              "Proof of address Doc, Proof of address  Type and Proof of address Valid Until are required."
            )

            console.error(
              "Proof of address Doc, Proof of address Type and Proof of address Valid Until are required."
            )
            return
          }

          const { workerID, ...workerData } = currentWorker

          const db = getFirestore()
          const storage = getStorage()

          const storageRef = ref(
            storage,
            `proofs/${workerID}/${newProof.ProofDoc.name}`
          )

          await uploadBytes(storageRef, newProof.ProofDoc)
            .then(async snapshot => {
              const downloadURL = await getDownloadURL(snapshot.ref)

              const proofData = {
                ProofType: newProof.ProofType,
                ProofValidUntil: newProof.ProofValidUntil,
                ProofDoc: downloadURL,
              }

              try {
                const workerProofsRef = collection(
                  db,
                  "workers",
                  workerID,
                  "proofs"
                )

                try {
                  const docRef = await addDoc(workerProofsRef, proofData)

                  // Log the activity
                  logUserActivity({
                    date: date,
                    time: time,
                    activity: `Worker ${workerData.firstName} ${workerData.lastName}'s proof of address updated successfully.`,
                    activityType: "Worker",
                    worker: `${workerData.firstName}.${workerData.lastName}`,
                  })

                  setProofs(prev => [...prev, { id: docRef.id, ...proofData }])
                } catch (error) {
                  console.error("Battling with updating of firestore: ", error)
                }

                setNewProof({
                  ProofType: "",
                  ProofValidUntil: "",
                  ProofDoc: null,
                })
              } catch (error) {
                console.error("Error updating firestore: ", error)
              }
            })
            .catch(error => {
              console.error("Error uploading file: ", error)
            })
        }


    const submitDisclosureHandler = async e => {
      e.preventDefault()

      // Validate required fields
      if (
        !newDisclosure.DisclosureType ||
        !newDisclosure.DisclosureValidUntil ||
        !newDisclosure.DisclosureDoc
      ) {
        alert(
          "Disclosure Doc, Disclosure Type and Disclosure Valid Until are required."
        )

        console.error(
          "Disclosure Doc, Disclosure Type and Disclosure Valid Until are required."
        )
        return
      }

      const { workerID, ...workerData } = currentWorker

      const db = getFirestore()
      const storage = getStorage()

      const storageRef = ref(
        storage,
        `disclosures/${workerID}/${newDisclosure.DisclosureDoc.name}`
      )

      await uploadBytes(storageRef, newDisclosure.DisclosureDoc)
        .then(async snapshot => {
          const downloadURL = await getDownloadURL(snapshot.ref)

          const disclosureData = {
            DisclosureType: newDisclosure.DisclosureType,
            DisclosureValidUntil: newDisclosure.DisclosureValidUntil,
            DisclosureDoc: downloadURL,
          }

          try {
            const workerDisclosureRef = collection(
              db,
              "workers",
              workerID,
              "disclosures"
            )

            try {
              const docRef = await addDoc(workerDisclosureRef, disclosureData)

              // Log the activity
              logUserActivity({
                date: date,
                time: time,
                activity: `Worker ${workerData.firstName} ${workerData.lastName}'s Disclosure updated successfully.`,
                activityType: "Worker",
                worker: `${workerData.firstName}.${workerData.lastName}`,
              })

              setDisclosures(prev => [
                ...prev,
                { id: docRef.id, ...disclosureData },
              ])
            } catch (error) {
              console.error("Battling with updating of firestore: ", error)
            }

            setNewDisclosure({
              DisclosureType: "",
              DisclosureValidUntil: "",
              DisclosureDoc: null,
            })
          } catch (error) {
            console.error("Error updating firestore: ", error)
          }
        })
        .catch(error => {
          console.error("Error uploading file: ", error)
        })
    }

    const submitNationalInsuranceHandler = async e => {
      e.preventDefault()

      // Validate required fields
      if (
        !newNationalInsurance.NationalInsuranceType ||
        !newNationalInsurance.NationalInsuranceValidUntil ||
        !newNationalInsurance.NationalInsuranceDoc
      ) {
        alert(
          "National Insurance Doc, National Insurance Type and National Insurance Valid Until are required."
        )

        console.error(
          "NationalInsurance Doc, NationalInsurance Type and NationalInsurance Valid Until are required."
        )
        return
      }

      const { workerID, ...workerData } = currentWorker

      const db = getFirestore()
      const storage = getStorage()

      const storageRef = ref(
        storage,
        `nationalinsurances/${workerID}/${newNationalInsurance.NationalInsuranceDoc.name}`
      )

      await uploadBytes(storageRef, newNationalInsurance.NationalInsuranceDoc)
        .then(async snapshot => {
          const downloadURL = await getDownloadURL(snapshot.ref)

          const nationalInsuranceData = {
            NationalInsuranceType: newNationalInsurance.NationalInsuranceType,
            NationalInsuranceValidUntil:
              newNationalInsurance.NationalInsuranceValidUntil,
            NationalInsuranceDoc: downloadURL,
          }

          try {
            const workerNationalInsuranceRef = collection(
              db,
              "workers",
              workerID,
              "nationalinsurances"
            )

            try {
              const docRef = await addDoc(
                workerNationalInsuranceRef,
                nationalInsuranceData
              )

              // Log the activity
              logUserActivity({
                date: date,
                time: time,
                activity: `Worker ${workerData.firstName} ${workerData.lastName}'s NI updated successfully.`,
                activityType: "Worker",
                worker: `${workerData.firstName}.${workerData.lastName}`,
              })

              setNationalInsurances(prev => [
                ...prev,
                { id: docRef.id, ...nationalInsuranceData },
              ])
            } catch (error) {
              console.error("Battling with updating of firestore: ", error)
            }

            setNewNationalInsurance({
              NationalInsuranceType: "",
              NationalInsuranceValidUntil: "",
              NationalInsuranceDoc: null,
            })
          } catch (error) {
            console.error("Error updating firestore: ", error)
          }
        })
        .catch(error => {
          console.error("Error uploading file: ", error)
        })
    }


  const submitPassportHandler = async e => {
    e.preventDefault()

    // Validate required fields
    if (
      !newPassport.PassportType ||
      !newPassport.PassportValidUntil ||
      !newPassport.PassportDoc
    ) {
      alert(
        "Passport Doc, Passport Type and Passport Valid Until are required."
      )

      console.error(
        "Passport Doc, Passport Type and Passport Valid Until are required."
      )
      return
    }

    const { workerID, ...workerData } = currentWorker

    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `passport/${workerID}/${newPassport.PassportDoc.name}`
    )

    await uploadBytes(storageRef, newPassport.PassportDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const passportData = {
          PassportType: newPassport.PassportType,
          PassportValidUntil: newPassport.PassportValidUntil,
          PassportDoc: downloadURL,
        }

        try {
          const workerPassportRef = collection(
            db,
            "workers",
            workerID,
            "passports"
          )

          try {
            const docRef = await addDoc(workerPassportRef, passportData)

            // Log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${workerData.firstName} ${workerData.lastName}'s passport updated successfully.`,
              activityType: "Worker",
              worker: `${workerData.firstName}.${workerData.lastName}`,
            })

            setPassports(prev => [...prev, { id: docRef.id, ...passportData }])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewPassport({
            PassportType: "",
            PassportValidUntil: "",
            PassportDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }

  const submitCVHandler = async e => {
    e.preventDefault()

    // Validate required fields
    if (
      !newCV.CVType ||
      !newCV.CVValidUntil ||
      !newCV.CVDoc
    ) {
      alert(
        "CV Doc, CV Type and CV Valid Until are required."
      )

      console.error(
        "CV Doc, cv Type and cv Valid Until are required."
      )
      return
    }

    const { workerID, ...workerData } = currentWorker

    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(storage, `cvs/${workerID}/${newCV.CVDoc.name}`)

    await uploadBytes(storageRef, newCV.CVDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const cvData = {
          CVType: newCV.CVType,
          CVValidUntil: newCV.CVValidUntil,
          CVDoc: downloadURL,
        }

        try {
          const workerCVRef = collection(db, "workers", workerID, "cvs")

          try {
            const docRef = await addDoc(workerCVRef, cvData)

            // Log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${workerData.firstName} ${workerData.lastName}'s staff profile updated successfully.`,
              activityType: "Worker",
              worker: `${workerData.firstName}.${workerData.lastName}`,
            })

            setCVs(prev => [...prev, { id: docRef.id, ...cvData }])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewCV({
            CVType: "",
            CVValidUntil: "",
            CVDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }

  const submitCovidHandler = async e => {
    e.preventDefault()

    // console.log("Uploading" + JSON.stringify(newCovid.))
    // Validate required fields
    if (!newCovid.CovidType || !newCovid.CovidValidUntil || !newCovid.CovidDoc) {
      alert("Covid Doc, Covid Type and Covid Valid Until are required.")

      console.error("Covid Doc, Covid Type and Covid Valid Until are required.")
      return
    }

    const { workerID, ...workerData } = currentWorker

    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `covids/${workerID}/${newCovid.CovidDoc.name}`
    )

    await uploadBytes(storageRef, newCovid.CovidDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const covidData = {
          CovidType: newCovid.CovidType,
          CovidValidUntil: newCovid.CovidValidUntil,
          CovidDoc: downloadURL,
        }

        try {
          const workerCovidRef = collection(db, "workers", workerID, "covids")

          try {
            const docRef = await addDoc(workerCovidRef, covidData)

            // Log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${workerData.firstName} ${workerData.lastName}'s covid updated successfully.`,
              activityType: "Worker",
              worker: `${workerData.firstName}.${workerData.lastName}`,
            })

            setCovids(prev => [...prev, { id: docRef.id, ...covidData }])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewCovid({
            CovidType: "",
            CovidValidUntil: "",
            CovidDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }


  const submitEmergencyHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker
    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s emergency record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        resetForm()
        toggleEmergencyModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s emergency record created successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        resetForm()
        toggleEmergencyModal()
      })
    }
  }

  const submitVacHandler = async e => {
    e.preventDefault()
    const { workerID, vaccinationDoc, ...workerData } = currentWorker

    try {
      if (workerID) {
        // Upload the vaccination document and get its download URL
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const vaccinationDocUrl = await uploadDocument(
          vaccinationDoc,
          "vaccinationDoc"
        )

        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s vaccination record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        const updatedData = {
          ...workerData,
          vaccinationDoc: vaccinationDocUrl,
        }

        WorkersDataService.updateWorker(workerID, updatedData).then(() => {
          resetForm()
          toggleVacModal()
        })
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const submitAuditHandler = async e => {
    e.preventDefault()
    const { workerID, vaccinationDoc, ...workerData } = currentWorker

    try {
      if (workerID) {
        // Upload the vaccination document and get its download URL
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const vaccinationDocUrl = await uploadDocument(
          vaccinationDoc,
          "vaccinationDoc"
        )

        const updatedData = {
          ...workerData,
          vaccinationDoc: vaccinationDocUrl,
        }

        WorkersDataService.updateWorker(workerID, updatedData).then(() => {
          resetForm()
          toggleVacModal()
        })
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const resetForm = () => {
    setCurrentWorker({
      firstName: "",
      lastName: "",
      alsoKnownAs: "",
      workerEmailAddress: "",
      dateOfBirth: null,
      gender: "",
      phoneNumber: "",
      country: "",
      postcode: "",
      residentialAddress: "",
      howlongintheaddress: "",
      fromDuration: "",
      toDuration: "",
      proofOfAddress: "",
      vatNumber: "",
      status: "",
      workerID: "",
    })
  }

  const resetDocForm = () => {
    setCurrentWorker({
      cv: "",
      covid: "",
      nationalInsurance: "",
      referenceProof: "",
      passport: "",
      passportexpiryDate: null,
      proofOfAddress: "",
      dbs: "",
      dbsexpiryDate: null,
      visa_workpermit: "",
      visa_workpermitDate: null,
    })
  }

  const resetCertForm = () => {
    setCurrentWorker({
      CertificateType: "",
      CertificateValidUntil: null,
      certificates: [],
    })
  }


    const resetVaccinationForm = () => {
      setCurrentWorker({
        VaccinationType: "",
        VaccinationValidUntil: null,
        VaccinationAllergies: "",
        VaccinationTreatments: "",
        VaccinationProduct: "",
        VaccinationInfo:"",
        vaccinations: [],
      })
    }

      const resetJobReferenceForm = () => {
        setCurrentWorker({
          JobReferenceType: "",
          JobReferenceValidUntil: null,
          JobReferenceName: "",
          JobReferenceInfo: "",
          jobreferences: [],
        })
      }


  const resetWorkForm = () => {
    setCurrentWorker({
      WorkType: "",
      WorkValidUntil: null,
      works: [],
    })
  }


  const resetProofForm = () => {
    setCurrentWorker({
      ProofType: "",
      ProofValidUntil: null,
      proofs: [],
    })
  }

    const resetDisclosureForm = () => {
      setCurrentWorker({
        DisclosureType: "",
        DisclosureValidUntil: null,
        disclosures: [],
      })
    }

    const resetNationalInsuranceForm = () => {
      setCurrentWorker({
        NationalInsuranceType: "",
        NationalInsuranceValidUntil: null,
        nationalinsurances: [],
      })
    }
  

  const resetPassportForm = () => {
    setCurrentWorker({
      PassportType: "",
      PassportValidUntil: null,
      Passports: [],
    })
  }

  const resetCVForm = () => {
    setCurrentWorker({
      CVType: "",
      CVValidUntil: null,
      CVs: [],
    })
  }

  const resetCovidForm = () => {
    setCurrentWorker({
      CovidType: "",
      CovidValidUntil: null,
      Covid: [],
    })
  }

  const resetNIForm = () => {
    setCurrentWorker({
      NIType: "",
      NIValidUntil: null,
      NIs: [],
    })
  }

  const resetDBSForm = () => {
    setCurrentWorker({
      DBSType: "",
      DBSValidUntil: null,
      DBSs: [],
    })
  }

  const resetWorkPermitForm = () => {
    setCurrentWorker({
      WorkPermitType: "",
      WorkPermitValidUntil: null,
      workpermits: [],
    })
  }

  const resetProofOfAddressForm = () => {
    setCurrentWorker({
      ProofOfAddressType: "",
      ProofOfAddressValidUntil: null,
      ProofOfAddress: [],
    })
  }

  const resetVacForm = () => {
    setCurrentWorker({})
  }

  const resetRefForm = () => {
    setCurrentWorker({})
  }

  const resetAuditForm = () => {
    setCurrentWorker({})
  }

  const resetEmergencyForm = () => {
    setCurrentWorker({
      emergencyContactName: "",
      emergencyRelationship: "",
      emergencyPriContact: "",
      emergencySecContact: "",
    })
  }

  const editProfile = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    setSelectedOrg(work.companyID || "") // Ensure the companyID is stored in the worker data

    toggleModal()
  }

 

  const editCertificates = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleCertificateModal()
  }

   const editWorks = work => {
     setCurrentWorker({ ...work, workerID: work.key || "" })

     toggleWorkModal()
   }


  const editProofs = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleProofModal()
  }

    const editDisclosures = work => {
      setCurrentWorker({ ...work, workerID: work.key || "" })

      toggleDisclosureModal()
    }

    const editNationalInsurances = work => {
      setCurrentWorker({ ...work, workerID: work.key || "" })

      toggleNationalInsuranceModal()
    }

  const editCV = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleCVModal()
  }

  const editCovids = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleCovidModal()
  }

  const editPassports = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    togglePassportModal()
  }


  const editEmergency = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleEmergencyModal()
  }

  const editVaccinations = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleVaccinationModal()
  }

  const editJobReferences = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleJobReferenceModal()
  }

  const editAudits = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleAuditModal()
  }

  const deleteWorker = key => {
    WorkersDataService.deleteWorker(key)
  }

  const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  function getValidDate(date) {
    if (!date) return null
    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }

  useEffect(() => {
    setCurrentWorker(current => ({
      ...current,
      companyName: companyName,
    }))
  }, [companyName])

  return (
    <div className="page-content">
      <div className="doc-container">
        <Button
          style={{ margin: "10px" }}
          color="primary"
          onClick={() => {
            toggleModal()
          }}
        >
          Add New Worker
        </Button>
      </div>
      <span
        style={{ float: "right" }}
      >{`Displaying ${worker.length} worker(s)`}</span>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleModal}>
          {currentWorker.workerID ? "Worker" : "Create Worker"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="workerID">
                Worker ID<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="workerID"
                id="workerID"
                value={currentWorker.workerID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessName">
                First Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={currentWorker.firstName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">
                Last Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={currentWorker.lastName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="alsoKnownAs">Also known as</Label>
              <Input
                type="text"
                name="alsoKnownAs"
                id="alsoKnownAs"
                value={currentWorker.alsoKnownAs}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="jobType">
                Job Type<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="jobType"
                id="jobType"
                value={currentWorker.jobType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Job Type</option>
                {jobTypes.map(jobType => (
                  <option key={jobType.key} value={jobType.name}>
                    {jobType.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="workerEmailAddress">
                Worker Email Address<span style={{ color: "red" }}>*</span>
              </Label>
              <div>
                <Input
                  type="email"
                  name="workerEmailAddress"
                  id="workerEmailAddress"
                  value={currentWorker.workerEmailAddress}
                  onChange={handleInputChange}
                  className={emailError ? "error-class" : ""}
                  required
                />
                {emailError && (
                  <div className="error-message">{emailError}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="dateOfBirth">
                Date Of Birth<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.dateOfBirth)}
                onChange={handleBirthExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="gender">
                Gender<span style={{ color: "red" }}>*</span>
              </Label>
              <select
                name="gender"
                id="gender"
                value={currentWorker.gender}
                onChange={handleInputChange}
                className="form-control"
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="phoneNumber">
                Phone Number<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={currentWorker.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="country">
                Country<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="country"
                id="country"
                value={currentWorker.country}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="postcode">
                Post code<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="postcode"
                id="postcode"
                value={currentWorker.postcode}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="residentialAddress">
                Residential Address<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="residentialAddress"
                id="residentialAddress"
                value={currentWorker.residentialAddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="howlongintheaddress">
                How long have you lived in the Address?
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="howlongintheaddress"
                id="howlongintheaddress"
                value={currentWorker.howlongintheaddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="fromDuration">
                Duration From<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.fromDuration)}
                onChange={date =>
                  setCurrentWorker({ ...currentWorker, fromDuration: date })
                }
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="toDuration">
                Duration To<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.toDuration)}
                onChange={date =>
                  setCurrentWorker({ ...currentWorker, toDuration: date })
                }
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="companyName">Company</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={currentWorker.companyName}
                onChange={handleInputChange}
                disabled={true}
                style={{ display: "none" }}
              />
              <select
                className="form-control"
                value={selectedOrg}
                onChange={handleSelectChange}
              >
                <option value="">Select Organisation</option>
                {organisations.map(org => (
                  <option key={org.key} value={org.key}>
                    {org.businessName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isCertModalOpen}
        toggle={toggleCertificateModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleCertificateModal}>
          {currentWorker.workerID ? "Certificate" : "Create Certificate"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitCertHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newCertificate.CertificateType}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateType: e.target.value,
                })
              }
              placeholder="Certificate Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newCertificate.CertificateValidUntil}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Certificate
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {certificates.map((certificate, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {certificate.CertificateType}, Valid Until:{" "}
                {certificate.CertificateValidUntil}
                <a
                  href={certificate.CertificateDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Document
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteCertificate(certificate)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleCertificateModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isVaccinationModalOpen}
        toggle={toggleVaccinationModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleVaccinationModal}>
          {currentWorker.workerID ? "Vaccination" : "Create Vaccination"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitVaccinationHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newVaccination.VaccinationType}
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationType: e.target.value,
                })
              }
              placeholder="Vaccination Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newVaccination.VaccinationValidUntil}
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newVaccination.VaccinationAllergies}
              placeholder="Vaccination Allergies"
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationAllergies: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newVaccination.VaccinationProduct}
              placeholder="Vaccination Product"
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationProduct: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newVaccination.VaccinationTreatments}
              placeholder="Vaccination Treatments"
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationTreatments: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newVaccination.VaccinationInfo}
              placeholder="Other information"
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationInfo: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewVaccination({
                  ...newVaccination,
                  VaccinationDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Vaccination
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {vaccinations.map((vaccination, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {vaccination.VaccinationType}, Valid Until:{" "}
                {vaccination.VaccinationValidUntil}
                <a
                  href={vaccination.VaccinationDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View vaccination
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteVaccination(vaccination)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleVaccinationModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isJobReferenceModalOpen}
        toggle={toggleJobReferenceModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleJobReferenceModal}>
          {currentWorker.workerID ? "Job Reference" : "Create Job Reference"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitJobReferenceHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newJobReference.JobReferenceType}
              onChange={e =>
                setNewJobReference({
                  ...newJobReference,
                  JobReferenceType: e.target.value,
                })
              }
              placeholder="Job Reference Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newJobReference.JobReferenceName}
              onChange={e =>
                setNewJobReference({
                  ...newJobReference,
                  JobReferenceName: e.target.value,
                })
              }
              placeholder="Job Reference Name"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newJobReference.JobReferenceInfo}
              onChange={e =>
                setNewJobReference({
                  ...newJobReference,
                  JobReferenceInfo: e.target.value,
                })
              }
              placeholder="Job Reference Other Information"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newJobReference.JobReferenceValidUntil}
              onChange={e =>
                setNewJobReference({
                  ...newJobReference,
                  JobReferenceValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewJobReference({
                  ...newJobReference,
                  JobReferenceDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Job Reference
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {jobreferences.map((jobreference, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {jobreference.JobReferenceType}, Valid Until:{" "}
                {jobreference.JobReferenceValidUntil}
                <a
                  href={jobreference.JobReferenceDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Job reference
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteJobReference(jobreference)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleJobReferenceModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isWorkModalOpen}
        toggle={toggleWorkModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleWorkModal}>
          {currentWorker.workerID ? "Work" : "Create Work"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitWorkHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newWork.WorkType}
              onChange={e =>
                setNewWork({
                  ...newWork,
                  WorkType: e.target.value,
                })
              }
              placeholder="Work Permit Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newWork.WorkValidUntil}
              onChange={e =>
                setNewWork({
                  ...newWork,
                  WorkValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewWork({
                  ...newWork,
                  WorkDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Work Permit
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {works.map((work, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {work.WorkType}, Valid Until: {work.WorkValidUntil}
                <a
                  href={work.WorkDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View work permit
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteWork(work)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleWorkModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isProofModalOpen}
        toggle={toggleProofModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleProofModal}>
          {currentWorker.workerID
            ? "Proof of Address"
            : "Create Proof of Address"}{" "}
          | {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitProofHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newProof.ProofType}
              onChange={e =>
                setNewProof({
                  ...newProof,
                  ProofType: e.target.value,
                })
              }
              placeholder="Proof Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newProof.ProofValidUntil}
              onChange={e =>
                setNewProof({
                  ...newProof,
                  ProofValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewProof({
                  ...newProof,
                  ProofDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Proof
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {proofs.map((proof, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {proof.ProofType}, Valid Until: {proof.ProofValidUntil}
                <a
                  href={proof.ProofDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Proof
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteProof(proof)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleProofModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isDisclosureModalOpen}
        toggle={toggleDisclosureModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleDisclosureModal}>
          {currentWorker.workerID ? "Disclosure" : "Create Disclosure"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitDisclosureHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newDisclosure.DisclosureType}
              onChange={e =>
                setNewDisclosure({
                  ...newDisclosure,
                  DisclosureType: e.target.value,
                })
              }
              placeholder="Disclosure Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newDisclosure.DisclosureValidUntil}
              onChange={e =>
                setNewDisclosure({
                  ...newDisclosure,
                  DisclosureValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewDisclosure({
                  ...newDisclosure,
                  DisclosureDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Disclosure
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {disclosures.map((disclosure, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {disclosure.DisclosureType}, Valid Until:{" "}
                {disclosure.DisclosureValidUntil}
                <a
                  href={disclosure.DisclosureDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Disclosure
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteDisclosure(disclosure)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleDisclosureModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isNationalInsuranceModalOpen}
        toggle={toggleNationalInsuranceModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleNationalInsuranceModal}>
          {currentWorker.workerID ? "NI" : "Create NI"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitNationalInsuranceHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newNationalInsurance.NationalInsuranceType}
              onChange={e =>
                setNewNationalInsurance({
                  ...newNationalInsurance,
                  NationalInsuranceType: e.target.value,
                })
              }
              placeholder="NI Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newNationalInsurance.NationalInsuranceValidUntil}
              onChange={e =>
                setNewNationalInsurance({
                  ...newNationalInsurance,
                  NationalInsuranceValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewNationalInsurance({
                  ...newNationalInsurance,
                  NationalInsuranceDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add NI
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {nationalinsurances.map((nationalinsurance, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {nationalinsurance.NationalInsuranceType}, Valid Until:{" "}
                {nationalinsurance.NationalInsuranceValidUntil}
                <a
                  href={nationalinsurance.NationalInsuranceDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View NI
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() =>
                    handleDeleteNationalInsurance(nationalinsurance)
                  }
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleNationalInsuranceModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isPassportModalOpen}
        toggle={togglePassportModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={togglePassportModal}>
          {currentWorker.workerID ? "Passport" : "Create Passport"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitPassportHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newPassport.PassportType}
              onChange={e =>
                setNewPassport({
                  ...newPassport,
                  PassportType: e.target.value,
                })
              }
              placeholder="Passport Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newPassport.PassportValidUntil}
              onChange={e =>
                setNewPassport({
                  ...newPassport,
                  PassportValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewPassport({
                  ...newPassport,
                  PassportDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Passport
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {passports.map((passport, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {passport.PassportType}, Valid Until:{" "}
                {passport.PassportValidUntil}
                <a
                  href={passport.PassportDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Document
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeletePassport(passport)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={togglePassportModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isCVModalOpen}
        toggle={toggleCVModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleCVModal}>
          {currentWorker.workerID ? "CV Staff Profile" : "Create Staff Profile"}{" "}
          | {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitCVHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newCV.CVType}
              onChange={e =>
                setNewCV({
                  ...newCV,
                  CVType: e.target.value,
                })
              }
              placeholder="CV Profile Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newCV.CVValidUntil}
              onChange={e =>
                setNewCV({
                  ...newCV,
                  CVValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewCV({
                  ...newCV,
                  CVDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Staff Profile
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {cvs.map((cv, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {cv.CVType}, Valid Until: {cv.CVValidUntil}
                <a
                  href={cv.CVDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Document
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteCV(cv)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleCVModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isCovidModalOpen}
        toggle={toggleCovidModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleCovidModal}>
          {currentWorker.workerID ? "Covid" : "Create Covid"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitCovidHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newCovid.CovidType}
              onChange={e =>
                setNewCovid({
                  ...newCovid,
                  CovidType: e.target.value,
                })
              }
              placeholder="Covid Document Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newCovid.CovidValidUntil}
              onChange={e =>
                setNewCovid({
                  ...newCovid,
                  CovidValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewCovid({
                  ...newCovid,
                  CovidDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Covid record
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {covids.map((covid, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {covid.CovidType}, Valid Until: {covid.CovidValidUntil}
                <a
                  href={covid.CovidDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Covid Document
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteCovid(covid)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={toggleCovidModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isEmergencyModalOpen}
        toggle={toggleEmergencyModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleEmergencyModal}>
          {currentWorker.workerID ? "Emergency" : "Create Emergency"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form onSubmit={submitEmergencyHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="emergencyContactName">Emergency Contact Name</Label>
              <Input
                type="text"
                name="emergencyContactName"
                id="emergencyContactName"
                value={currentWorker.emergencyContactName || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyRelationship">
                Emergency Relationship<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencyRelationship"
                id="emergencyContactName"
                value={currentWorker.emergencyRelationship || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyPriContact">
                Emergency Primary Contact<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencyPriContact"
                id="emergencyPriContact"
                value={currentWorker.emergencyPriContact || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencySecContact">
                Emergency Secondary Contact
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencySecContact"
                id="emergencySecContact"
                value={currentWorker.emergencySecContact || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleEmergencyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={isAuditModalOpen}
        toggle={toggleAuditModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleAuditModal}>
          {currentWorker.workerID ? "Audit trail" : "Audit trail"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>

        <form onSubmit={submitAuditHandler}>
          <ModalBody>
            {/* Search Field */}
            <FormGroup>
              <Label for="searchQuery">Search Activities</Label>
              <Input
                type="text"
                name="searchQuery"
                id="searchQuery"
                placeholder="Search by activity description..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </FormGroup>

            {/* Filter by Activity Type */}
            <FormGroup>
              <Label for="filterType">Filter by Type</Label>
              <Input
                type="select"
                name="filterType"
                id="filterType"
                value={filterType}
                onChange={e => setFilterType(e.target.value)}
              >
                <option value="">All Types</option>
                <option value="Worker">Worker</option>
                <option value="Certificate">Certificate</option>
                <option value="Document">Document</option>
                <option value="Emergency">Emergency</option>
                {/* Add other types here as necessary */}
              </Input>
            </FormGroup>

            {/* Sort by Date (Toggle Ascending/Descending) */}
            <Button
              color="primary"
              onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            >
              Sort by Date ({sortOrder === "asc" ? "Ascending" : "Descending"})
            </Button>

            {/* List of Activities */}
            <ListGroup>
              {filteredAndSortedActivities.map((activity, index) => (
                <ListGroupItem
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {truncateText(
                      `${activity.activity} - ${activity.date}`,
                      100
                    )}
                  </span>
                </ListGroupItem>
              ))}
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleAuditModal}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <ActivityDetailModal
        activity={selectedActivity}
        isOpen={modalOpen}
        toggle={toggleModal}
      />
      <Table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Worker ID</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {worker.map(work => (
            <tr key={work.key}>
              <td>{work.firstName}</td>
              <td>{work.lastName}</td>
              <td>{work.key}</td>
              <td>
                <span
                  className={`badge badge-${
                    (work.status && work.status.toLowerCase()) || "new"
                  }`}
                >
                  {work.status || "New"}
                </span>
              </td>
              <td>
                <Dropdown
                  className="custom-dropdown-menu"
                  isOpen={dropdownOpenStates[work.key]}
                  toggle={() => toggleDropdown(work.key)}
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => editProfile(work)}>
                      Profile
                    </DropdownItem>
                    {/* <DropdownItem onClick={() => editDocuments(work)}>
                      Documents
                    </DropdownItem> */}
                    <DropdownItem onClick={() => editPassports(work)}>
                      Passports
                    </DropdownItem>
                    <DropdownItem onClick={() => editCV(work)}>
                      Staff CV Profile
                    </DropdownItem>
                    <DropdownItem onClick={() => editCovids(work)}>
                      Covid
                    </DropdownItem>
                    <DropdownItem onClick={() => editNationalInsurances(work)}>
                      NI
                    </DropdownItem>
                    <DropdownItem onClick={() => editDisclosures(work)}>
                      DBS
                    </DropdownItem>
                    <DropdownItem onClick={() => editWorks(work)}>
                      Work Permit
                    </DropdownItem>
                    <DropdownItem onClick={() => editProofs(work)}>
                      Proof of Address
                    </DropdownItem>
                    <DropdownItem onClick={() => editCertificates(work)}>
                      Certificates
                    </DropdownItem>
                    <DropdownItem onClick={() => editEmergency(work)}>
                      Emergency
                    </DropdownItem>
                    <DropdownItem onClick={() => editVaccinations(work)}>
                      Vaccinations
                    </DropdownItem>
                    <DropdownItem onClick={() => editJobReferences(work)}>
                      References
                    </DropdownItem>
                    <DropdownItem onClick={() => editAudits(work)}>
                      Audits
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default WorkerManager
