import React, { useState, useEffect, useMemo } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import debounce from "lodash/debounce"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import JobDataService from "services/JobDataService"
import emailjs from "@emailjs/browser"
import { getFirestore, collection, getDocs } from "firebase/firestore"
import OrganisationDataService from "services/OrganisationDataService"
import BusinessUnitDataService from "services/BusinessUnitDataService"
import WorkersDataService from "services/WorkersDataService"
import WorkersAllocationService from "services/WorkersAllocationService"
import avatar from "../../assets/images/users/user.png"
import UserPill from "./UserPill"
import "./booking.css"
import { useTable, useSortBy } from "react-table"
import jsPDF from "jspdf"
import EditJobModal from "./EditJobModal"

const BookingClient = () => {
  const [jobs, setJobs] = useState([])
  const [searchTerm, setSearchTerm] = useState("") // State for search input
  const [filteredJobs, setFilteredJobs] = useState([]) // State for filtered jobs

  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobExperience: "",
    jobType: "",
    provider: "",
    company: "",
    careHome: "",
    location: "",
    coordinates: "",
    workerType: "",
    noofVacancies: "",
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "open",
    jobID: "",
    category: "",
    featured: "",
    employee: "",
    businessUnits: "",
    timesheetStatus: "Not Created",
    createdAt: Date.now(),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [locationInput, setLocationInput] = useState("")
  const [coordinatesStatus, setCoordinatesStatus] = useState({
    resolved: false,
    message: "",
  })

  const [isEditMode, setIsEditMode] = useState(false)

  const [organisations, setOrganisations] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [workers, setWorkers] = useState([])
  const [selectedWorker, setSelectedWorker] = useState(null)
  const [isWorkerModalOpen, setIsWorkerModalOpen] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState("")
  const [selectedBU, setselectedBU] = useState("")
  const [selectionFeedback, setSelectionFeedback] = useState("")
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [jobToEdit, setJobToEdit] = useState(null)
  const [selectedOrganisation, setSelectedOrganisation] = useState("")
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("")
  const [company, setCompany] = useState("")
  const [companyName, setCompanyName] = useState("") // State to store the fetched company name

  // Fetch user company key from localStorage
  useEffect(() => {
    const authUserDetails = localStorage.getItem("authUserDetails")
    if (authUserDetails) {
      const userDetails = JSON.parse(authUserDetails)
      const userCompany = userDetails.userCompany
      setCompany(userCompany)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

   useEffect(() => {
     if (company) {
       // Fetch the jobs filtered by company name
       JobDataService.getJobsByCompanyName(company, data => {
         setJobs(data)
         setFilteredJobs(data) // Initialize the filteredJobs with all jobs initially
       })

       // Fetch organisation's business name based on the company key
       OrganisationDataService.getOrganisationBusinessName(company)
         .then(businessName => {
           setCompanyName(businessName)
           setSelectedOrg(company) // Automatically select the organisation
           setCurrentJob(prevJob => ({
             ...prevJob,
             provider: businessName,
           }))
         })
         .catch(error => {
           console.error("Error fetching organisation business name:", error)
         })
     }
   }, [company])

  // Fetch organisations when the component mounts
  useEffect(() => {
    OrganisationDataService.getAll(orgData => {
      setOrganisations(orgData)
    })
  }, [])

  // Automatically select the provider based on the company key
  useEffect(() => {
    if (company) {
      // Fetch organisation's business name based on the company key
      OrganisationDataService.getOrganisationBusinessName(company)
        .then(businessName => {
          console.log("Fetched business name:", businessName)
          setCompanyName(businessName) // Set the fetched business name
          setSelectedOrg(company) // Automatically select the organisation in the dropdown
          setCurrentJob(prevJob => ({
            ...prevJob,
            provider: businessName, // Set the provider as the fetched business name
          }))
        })
        .catch(error => {
          console.error("Error fetching organisation business name:", error)
        })
    }
  }, [company])

  // Handle search input change
  const handleSearchChange = e => {
    const searchValue = e.target.value.toLowerCase()
    setSearchTerm(searchValue)

    const filteredData = jobs.filter(job => {
      return (
        job.jobTitle.toLowerCase().includes(searchValue) ||
        job.jobType.toLowerCase().includes(searchValue) ||
        job.location.toLowerCase().includes(searchValue) ||
        job.jobstatus.toLowerCase().includes(searchValue)
      )
    })

    setFilteredJobs(filteredData)
  }

  // Fetch organisations when the component mounts
  useEffect(() => {
    OrganisationDataService.getAll(orgData => {
      setOrganisations(orgData)
    })
  }, [])

  // Fetch business units based on selected organisation
  useEffect(() => {
    if (selectedOrganisation) {
      BusinessUnitDataService.getBusinessUnitsByProvider(
        selectedOrganisation,
        buData => {
          setBusinessUnits(buData)
        }
      )
    } else {
      setBusinessUnits([]) // Clear if no organisation is selected
    }
  }, [selectedOrganisation])

  const handleWorkerSelection = worker => {
    setCurrentJob(prevJob => ({
      ...prevJob,
      employee: `${worker.firstName} ${worker.lastName}`,
      email: worker.workerEmailAddress,
      location: worker.residentialAddress,
      company: worker.company,
    }))
    setSelectionFeedback(
      "Worker selected: " + worker.firstName + " " + worker.lastName
    )
    setIsWorkerModalOpen(false)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (!isModalOpen) {
      setCurrentJob(prevJob => ({ ...prevJob, provider: selectedOrg }))
    } else {
      resetForm()
    }
  }

  const handleSelectChange = e => {
    const selectedValue = e.target.value
    setSelectedOrg(selectedValue)
    setCurrentJob(prevJob => ({
      ...prevJob,
      provider: selectedValue,
    }))
  }

  const handleOrganisationChange = event => {
    setSelectedOrganisation(event.target.value)
    setSelectedBusinessUnit("") // Clear the business unit when organisation changes
  }

  const handleBusinessUnitChange = event => {
    setSelectedBusinessUnit(event.target.value)
  }

  const handleSelectBUChange = e => {
    const selectedValue = e.target.value
    setselectedBU(selectedValue)

    setCurrentJob(currentJob => ({
      ...currentJob,
      businessUnits: selectedValue,
    }))
  }

  useEffect(() => {
    setLocationInput(currentJob.location)
  }, [currentJob.location])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(data => {
      setOrganisations(data)
    })

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    let isActive = true

    if (selectedOrg) {
      const unsubscribeBU = BusinessUnitDataService.getBusinessUnitsByProvider(
        selectedOrg,
        data => {
          if (isActive) {
            setBusinessUnits(data)
          }
        }
      )

      return () => {
        isActive = false
        if (unsubscribeBU) {
          unsubscribeBU()
        }
      }
    }
  }, [selectedOrg])

  useEffect(() => {
    if (selectedOrg && currentJob.jobType) {
      fetchWorkers(selectedOrg, currentJob.jobType)
    }
  }, [selectedOrg, currentJob.jobType])

  const fetchWorkers = (provider, jobType) => {
    WorkersDataService.getWorkersByTypeAndProvider(jobType, provider)
      .then(data => {
        setWorkers(data)
      })
      .catch(error => {
        console.error("Failed to fetch workers:", error)
      })
  }

  const handleWorkerClick = worker => {
    if (!worker) {
      console.error("No worker data available")
      return
    }
    setSelectedWorker(worker)
    setIsWorkerModalOpen(true)
  }

  const handleInputChange = async event => {
    const { name, value } = event.target

    if (name === "jobID") {
      const fixedPrefix = currentJob.jobID.substring(0, 14)
      if (value.startsWith(fixedPrefix)) {
        setCurrentJob({ ...currentJob, [name]: value })
      }
    } else if (name === "location") {
      setLocationInput(value)
      setCurrentJob({ ...currentJob, [name]: value })
    } else {
      setCurrentJob({ ...currentJob, [name]: value })
    }

    // Handle the case when the input field is 'company'
    if (name === "provider") {
      // Fetch company name asynchronously when provider changes
      const companyName = await getCompanyName(value)
      setCurrentJob(prevJob => ({
        ...prevJob,
        provider: value,
        company: companyName || "Company not found", // Fallback if company not found
      }))
    }

    if (name === "jobType") {
      setCurrentJob({ ...currentJob, jobType: value })
      if (value && currentJob.provider) {
        fetchWorkers(currentJob.provider, value)
      }
    } else {
      setCurrentJob({ ...currentJob, [name]: value })
    }

    if (isEditMode) {
      console.log(`Field "${name}" changed to:`, value)
    }
  }

  const generatejobID = () => {
    const currentDate = new Date()
    const day = currentDate.getDay()
    const sunday = new Date(currentDate)
    sunday.setDate(currentDate.getDate() - day)

    const formattedDate = `${String(sunday.getDate()).padStart(2, "0")}${String(
      sunday.getMonth() + 1
    ).padStart(2, "0")}${sunday.getFullYear()}`

    // Generate a random five-character alphanumeric suffix
    const randomSuffix = Math.random()
      .toString(36)
      .substring(2, 7)
      .toUpperCase()

    return `JOB-WK${formattedDate}-${randomSuffix}`
  }

  // Function to fetch the company name (Provider)
  async function getCompanyName(companyKey) {
    try {
      // console.log('Getting company name: ' + companyKey)
      const companyData = await OrganisationDataService.getOrganisationByKey(
        companyKey
      )
      // console.log("Got company data: " + companyData)
      if (companyData && companyData.businessName) {
        console.log("Got company name: " + companyData.businessName)
        return companyData.businessName
      } else {
        console.error("Company not found for key: ", companyKey)
        return null // Return null if no company found
      }
    } catch (error) {
      console.error("Error fetching company name: ", error)
      return null // Handle error gracefully
    }
  }

  const submitHandler = async event => {
    event.preventDefault()

    // console.log("Checking employees")

    // if (!currentJob.employee) {
    //   alert("Please select at least one worker before submitting the job.")
    //   return
    // }

    // Combine date and time for start and end
    const startDateTime = new Date(
      `${currentJob.startDate}T${currentJob.startTime}`
    )
    const endDateTime = new Date(`${currentJob.endDate}T${currentJob.endTime}`)

    // Validate if start time is after or equal to end time
    if (startDateTime >= endDateTime) {
      alert(
        "Start date/time cannot be later than or equal to the end date/time."
      )
      return // Stop form submission
    }

    try {
      // const generatedJobID = currentJob.jobID || generatejobID(jobs) // Use existing jobID if editing, or generate a new one for creation

      const generatedJobID = currentJob.jobID || generatejobID() // Use existing jobID if editing, or generate a new one for creation

      const updatedJobData = {
        ...currentJob,
        provider: selectedOrg,
        company: selectedOrg,
        jobID: generatedJobID, // Use the same value for jobID and key
        key: generatedJobID,
      }

      console.log("Updated job data: ", JSON.stringify(updatedJobData))

      if (currentJob.jobID) {
        // Editing existing job
        await JobDataService.updateJob(generatedJobID, updatedJobData)
        console.log("Job updated: ", generatedJobID)
      } else {
        // Creating new job
        await JobDataService.createJob(updatedJobData)
        console.log("Job created: ", generatedJobID)
      }

      //   console.log("Adding eligible workers")

      //   await WorkersAllocationService.addEligibleWorkers(generatedJobID, workers)

      //   console.log("Get all jobs")

      const updatedJobList = await JobDataService.getAllJobs()

      console.log("Setting job list")

      setJobs(updatedJobList)

      resetForm()
      toggleModal()
    } catch (error) {
      console.log("Error in form submission:", error)
    }
  }

  const resetForm = () => {
    setCurrentJob({
      jobTitle: "",
      jobDescription: "",
      jobExperience: "",
      jobType: "",
      careHome: "",
      workerType: "",
      noofVacancies: "",
      location: "",
      coordinates: "",
      startDate: "",
      startTime: "",
      endTime: "",
      enableRecurrence: "",
      breaks: "",
      jobstatus: "open",
      jobID: "",
      category: "",
    })
    setIsEditMode(false)
  }

  // const editJob = job => {
  //   setCurrentJob({ ...job, jobID: job.key || "" })
  //   toggleModal()
  // }

  const editJob = job => {
    setCurrentJob({ ...job, jobID: job.key || "" })
    setSelectedOrg(job.provider)
    setselectedBU(job.businessUnits)
    setIsEditMode(true) // Enable edit mode
    toggleModal()
  }

  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen)

  const openEditModal = job => {
    setJobToEdit(job) // Pass the job to edit
    toggleEditModal() // Open the edit modal
  }

  const deleteJob = async key => {
    console.log(`delete job ${key}`)
    try {
      await JobDataService.deleteJob(key)

      // Refetch the job list after deletion to update the UI
      const updatedJobList = await JobDataService.getAllJobs()
      setJobs(updatedJobList)

      // Optionally show a success message
      console.log("Job deleted successfully and list refreshed.")
    } catch (error) {
      console.error("Error deleting job:", error)
    }
  }

  const WorkerDetailModal = ({ worker, isOpen, toggle }) => {
    const [activeTab, setActiveTab] = useState("details")
    const [cvs, setCvs] = useState([])
    const [certificates, setCertificates] = useState([])
    const [covids, setCovids] = useState([])
    const [disclosures, setDisclosures] = useState([])
    const [jobReferences, setJobReferences] = useState([])
    const [nationalInsurances, setNationalInsurances] = useState([])
    const [proofs, setProofs] = useState([])
    const [vaccinations, setVaccinations] = useState([])
    const [works, setWorks] = useState([])

    // Helper function to fetch documents by type
    const fetchDocuments = async (documentType, setDocuments) => {
      try {
        const documents = await WorkersDataService.getDocumentsByType(
          worker.key,
          documentType
        )
        setDocuments(documents)
      } catch (error) {
        console.error(`Error fetching ${documentType}:`, error)
      }
    }

    // Handle tab clicks and fetch the corresponding documents
    const handleTabClick = tab => {
      setActiveTab(tab)
      switch (tab) {
        case "cv":
          fetchDocuments("cvs", setCvs)
          break
        case "certificates":
          fetchDocuments("certificates", setCertificates)
          break
        case "covids":
          fetchDocuments("covids", setCovids)
          break
        case "disclosures":
          fetchDocuments("disclosures", setDisclosures)
          break
        case "jobreferences":
          fetchDocuments("jobreferences", setJobReferences)
          break
        case "nationalinsurances":
          fetchDocuments("nationalinsurances", setNationalInsurances)
          break
        case "proofs":
          fetchDocuments("proofs", setProofs)
          break
        case "vaccinations":
          fetchDocuments("vaccinations", setVaccinations)
          break
        case "works":
          fetchDocuments("works", setWorks)
          break
        default:
          break
      }
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="xl" backdrop={true}>
        <ModalHeader toggle={toggle}>
          Details for {worker.firstName} {worker.lastName} | {worker.key}...
        </ModalHeader>

        <div className="nav nav-tabs">
          <a
            className={`nav-item nav-link ${
              activeTab === "details" ? "active" : ""
            }`}
            onClick={() => handleTabClick("details")}
          >
            Candidate Details
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "cv" ? "active" : ""
            }`}
            onClick={() => handleTabClick("cv")}
          >
            Staff Profile
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "certificates" ? "active" : ""
            }`}
            onClick={() => handleTabClick("certificates")}
          >
            Certificates
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "covids" ? "active" : ""
            }`}
            onClick={() => handleTabClick("covids")}
          >
            Covids
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "disclosures" ? "active" : ""
            }`}
            onClick={() => handleTabClick("disclosures")}
          >
            Disclosures
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "jobreferences" ? "active" : ""
            }`}
            onClick={() => handleTabClick("jobreferences")}
          >
            Job References
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "nationalinsurances" ? "active" : ""
            }`}
            onClick={() => handleTabClick("nationalinsurances")}
          >
            National Insurances
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "proofs" ? "active" : ""
            }`}
            onClick={() => handleTabClick("proofs")}
          >
            Proofs
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "vaccinations" ? "active" : ""
            }`}
            onClick={() => handleTabClick("vaccinations")}
          >
            Vaccinations
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "works" ? "active" : ""
            }`}
            onClick={() => handleTabClick("works")}
          >
            Works
          </a>
        </div>

        <ModalBody>
          {activeTab === "details" && (
            <div>
              <fieldset>
                <legend>Personal Details</legend>
                <p>
                  <strong>Name:</strong> {worker.firstName} {worker.lastName}
                </p>
                <p>
                  <strong>Gender:</strong> {worker.gender}
                </p>
              </fieldset>
              <fieldset>
                <legend>Address</legend>
                <p>
                  <strong>Street:</strong> {worker.residentialAddress}
                </p>
                <p>
                  <strong>Post code:</strong> {worker.postcode}
                </p>
                <p>
                  <strong>Country:</strong> {worker.country}
                </p>
              </fieldset>
              <fieldset>
                <legend>Contact Details</legend>
                <p>
                  <strong>Email:</strong>{" "}
                  <a
                    href={"mailto:" + worker.workerEmailAddress}
                    rel="noreferrer"
                  >
                    {worker.workerEmailAddress}
                  </a>
                </p>
                <p>
                  <strong>Phone Number:</strong> {worker.phoneNumber}
                </p>
              </fieldset>
            </div>
          )}

          {activeTab === "cv" && (
            <div>
              <h3>Staff Profile Documents</h3>
              {cvs.length === 0 ? (
                <p>No staff profile available for this worker.</p>
              ) : (
                <ul>
                  {cvs.map((cv, index) => (
                    <li key={index}>
                      <a
                        href={cv.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Staff Profile {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {/* Render for each document type */}
          {activeTab === "certificates" && (
            <div>
              <h3>Certificates</h3>
              {certificates.length === 0 ? (
                <p>No Certificates available for this worker.</p>
              ) : (
                <ul>
                  {certificates.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Certificate {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "covids" && (
            <div>
              <h3>Covid Documents</h3>
              {covids.length === 0 ? (
                <p>No Covid documents available for this worker.</p>
              ) : (
                <ul>
                  {covids.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Covid Document {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "disclosures" && (
            <div>
              <h3>Disclosures</h3>
              {disclosures.length === 0 ? (
                <p>No Disclosures available for this worker.</p>
              ) : (
                <ul>
                  {disclosures.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Disclosure {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "jobreferences" && (
            <div>
              <h3>Job References</h3>
              {jobReferences.length === 0 ? (
                <p>No Job References available for this worker.</p>
              ) : (
                <ul>
                  {jobReferences.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Job Reference {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "nationalinsurances" && (
            <div>
              <h3>National Insurances</h3>
              {nationalInsurances.length === 0 ? (
                <p>
                  No National Insurance documents available for this worker.
                </p>
              ) : (
                <ul>
                  {nationalInsurances.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download National Insurance Document {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "proofs" && (
            <div>
              <h3>Proof Documents</h3>
              {proofs.length === 0 ? (
                <p>No Proof documents available for this worker.</p>
              ) : (
                <ul>
                  {proofs.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Proof Document {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "vaccinations" && (
            <div>
              <h3>Vaccinations</h3>
              {vaccinations.length === 0 ? (
                <p>No Vaccination documents available for this worker.</p>
              ) : (
                <ul>
                  {vaccinations.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Vaccination Document {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {activeTab === "works" && (
            <div>
              <h3>Work Permit Documents</h3>
              {works.length === 0 ? (
                <p>No Work permit documents available for this worker.</p>
              ) : (
                <ul>
                  {works.map((doc, index) => (
                    <li key={index}>
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Work Permit Document {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setCurrentJob(prevJob => ({
                ...prevJob,
                employee: `${worker.firstName} ${worker.lastName}`,
                email: worker.workerEmailAddress,
                location: worker.residentialAddress,
              }))
              toggle()
            }}
          >
            Select This Worker
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        id: "row",
        accessor: "",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "Job Type",
        accessor: "jobType",
      },
      {
        Header: "Job ID",
        accessor: "key",
      },
      //   {
      //     Header: "Provider",
      //     accessor: "provider",
      //   },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Status",
        accessor: "jobstatus",
      },
      //   {
      //     Header: "Actions",
      //     accessor: "actions",
      //     Cell: ({ row }) => (
      //       <>
      //         <Button color="info" onClick={() => openEditModal(row.original)}>
      //           Edit
      //         </Button>
      //         {/* <Button onClick={() => editJob(row.original)}>Edit</Button> */}
      //         &nbsp;&nbsp;&nbsp;
      //         <Button onClick={() => deleteJob(row.original.key)}>Delete</Button>
      //       </>
      //     ),
      //   },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: filteredJobs || [] }, useSortBy)

  return (
    <div className="page-content">
      {selectedWorker && (
        <WorkerDetailModal
          worker={selectedWorker}
          isOpen={isWorkerModalOpen}
          toggle={() => setIsWorkerModalOpen(false)}
        />
      )}

      <div className="d-flex justify-content-between">
        {/* Search Input */}
        <Input
          type="text"
          placeholder="Search jobs..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ margin: "10px" }}
        />
      </div>

      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => setIsModalOpen(true)}
      >
        Add New Job
      </Button>
      <h5>Logged in as: {companyName || "Loading..."}</h5>

      <span
        style={{ float: "right" }}
      >{`Displaying ${jobs.length} job(s)`}</span>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>
          {/* {currentJob.jobID ? "Create a new Job" : "Create Job"} |{" "}
          {currentJob.key} */}
          {isEditMode ? "Edit Job" : "Add a new Job"} {currentJob.key}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody style={{ display: "flex", flexDirection: "row" }}>
            {/* <div
              style={{
                flex: 1,
                paddingRight: "20px",
                borderRight: "1px solid #ccc",
                textAlign: "center",
              }}
            >
              <img
                src={avatar}
                alt="User list"
                className="avatar-md rounded-circle img-thumbnail"
                style={{
                  objectFit: "cover",
                  width: "auto",
                }}
              />
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li>Select a client, business unit</li>
                <li>and job to view eligible</li>
                <li>candidates</li>
              </ul>

              <Table style={{ textAlign: "justify" }}>
                <thead>
                  <tr>
                    <th>Worker Firstname</th>
                    <th>Worker Lastname</th>
                  </tr>
                </thead>
                <tbody>
                  {workers.map(worker => (
                    <tr
                      key={worker.id}
                      className="table-row"
                      onClick={() => handleWorkerClick(worker)}
                    >
                      <td>{worker.firstName}</td>
                      <td>{worker.lastName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div> */}

            <div style={{ flex: 3, paddingLeft: "20px" }}>
              <div>Fields marked with an asterisk (*) are required.</div>

              <FormGroup>
                {selectionFeedback && (
                  <div className="feedback-message">{selectionFeedback}</div>
                )}

                <Label for="provider">
                  Provider<span style={{ color: "red" }}>*</span>
                </Label>
                <select  disabled
                  className="form-control"
                  value={selectedOrg}
                  onChange={handleSelectChange}
                >
                  <option value="">Select a Provider</option>
                  {organisations.map(org => (
                    <option key={org.key} value={org.key}>
                      {org.businessName}
                    </option>
                  ))}
                </select>
                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="provider"
                    id="provider"
                    value={currentJob.provider}
                    onChange={handleInputChange}
                    required
                  />
                </span>
              </FormGroup>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormGroup style={{ flex: 1, marginRight: "20px" }}>
                  <Label for="startDate">
                    Start Date<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="date"
                    name="startDate"
                    id="startDate"
                    value={currentJob.startDate}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                  <Label for="startTime">
                    Start Time<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="time"
                    name="startTime"
                    id="startTime"
                    value={currentJob.startTime}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormGroup style={{ flex: 1, marginRight: "20px" }}>
                  <Label for="endDate">
                    End Date<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={currentJob.endDate}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                  <Label for="endTime">
                    End Time<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="time"
                    name="endTime"
                    id="endTime"
                    value={currentJob.endTime}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </div>

              <FormGroup>
                <Label for="careHome">
                  Business Unit {`(${businessUnits.length})`}
                </Label>
                <select
                  className="form-control"
                  value={selectedBU}
                  onChange={handleSelectBUChange}
                >
                  <option value="">Select a Business Unit</option>
                  {businessUnits.map(bu => (
                    <option key={bu.key} value={bu.key}>
                      {bu.businessName}
                    </option>
                  ))}
                </select>

                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="careHome"
                    id="careHome"
                    value={currentJob.businessUnits}
                    onChange={handleInputChange}
                  />
                </span>

                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    value={currentJob.company}
                    onChange={handleInputChange}
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <Label for="jobTitle">
                  Job Title<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  value={currentJob.jobTitle || ""} // Ensure value is never undefined
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="jobID">
                  Booking Reference/Number
                </Label>
                <Input
                  type="text"
                  name="jobID"
                  id="jobID"
                  value={currentJob.jobID || ""}
                  onChange={e =>
                    setCurrentJob(prevJob => ({
                      ...prevJob,
                      jobID: e.target.value,
                    }))
                  }
                  
                />
              </FormGroup> */}
              <FormGroup>
                <Label for="jobDescription">Job Description</Label>
                <Input
                  type="textarea"
                  name="jobDescription"
                  id="jobDescription"
                  value={currentJob.jobDescription || ""}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="jobType">
                  Job Type<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  name="jobType"
                  id="jobType"
                  value={currentJob.jobType || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select job type</option>
                  <option value="Support worker">Support worker</option>
                  <option value="Sleep in">Sleep in</option>
                  <option value="Health Care Assistant">
                    Health Care Assistant
                  </option>
                  <option value="Registered Mental Health Nurse">
                    Registered Mental Health Nurse
                  </option>
                  <option value="Registered General Nurse">
                    Registered General Nurse
                  </option>
                  <option value="Doctor">Doctor</option>
                </Input>
              </FormGroup>

              {(currentJob.employee || currentJob.email) && (
                <FormGroup>
                  <UserPill
                    employeeName={currentJob.employee}
                    employeeEmail={currentJob.email}
                    employeeLocation={currentJob.location}
                  />

                  <Label for="employee">Employee</Label>
                  <Input
                    type="text"
                    name="employee"
                    id="employee"
                    value={currentJob.employee}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="location">Location</Label>
                <PlacesAutocomplete
                  value={locationInput}
                  onChange={setLocationInput}
                  onSelect={async address => {
                    setLocationInput(address)
                    try {
                      const results = await geocodeByAddress(address)
                      const latLng = await getLatLng(results[0])
                      setCurrentJob(prevJob => ({
                        ...prevJob,
                        location: address,
                        coordinates: `${latLng.lat}, ${latLng.lng}`,
                      }))
                      setCoordinatesStatus({ resolved: true, message: "✓" })
                    } catch (error) {
                      console.error("Error getting coordinates:", error)
                      setCoordinatesStatus({
                        resolved: false,
                        message: " ",
                      })
                    }
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                          required: true,
                          style: coordinatesStatus.resolved
                            ? { backgroundColor: "darkgreen", color: "white" }
                            : {},
                        })}
                        style={{ flex: 1, marginRight: "5px" }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item"
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" }
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <span style={{ flex: 0, width: "10%", fontSize: "20" }}>
                  {coordinatesStatus.message}
                </span>
              </FormGroup>

              <FormGroup style={{ visibility: "hidden" }}>
                <Label for="coordinates">Coordinates</Label>
                <Input
                  type="text"
                  name="coordinates"
                  id="coordinates"
                  value={currentJob.coordinates}
                  onChange={handleInputChange}
                  required
                  disabled={true}
                  style={{
                    backgroundColor: coordinatesStatus.resolved
                      ? "lightgreen"
                      : "lightcoral",
                  }}
                />
              </FormGroup>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={submitHandler} type="submit">Save</Button>
            <Button color="primary" type="submit">
              {currentJob.jobID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter> */}

          <ModalFooter>
            {isEditMode ? (
              <Button
                color="success"
                onClick={() => {
                  console.log("Job changes:", currentJob)
                  setIsEditMode(false) // Exit edit mode after saving
                  toggleModal() // Close modal
                }}
              >
                Save Changes
              </Button>
            ) : (
              <Button color="primary" type="submit">
                {currentJob.jobID ? "Update" : "Create"}
              </Button>
            )}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {filteredJobs && filteredJobs.length > 0 ? (
        <Table
          {...getTableProps()}
          className="table table-striped table-bordered"
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                  >
                    {column.render("Header")}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p>No jobs available</p>
      )}

      {/* Edit Modal */}
      {isEditModalOpen && (
        <EditJobModal
          isOpen={isEditModalOpen}
          toggle={toggleEditModal}
          jobToEdit={jobToEdit}
          organisations={organisations} // Pass organisations to the modal
          businessUnits={businessUnits} // Pass business units to the modal
          onSave={() => {
            // Refresh the job list after saving
            JobDataService.getAllJobs().then(updatedJobs =>
              setJobs(updatedJobs)
            )
          }}
        />
      )}
    </div>
  )
}

export default BookingClient
