import React, { useState, useEffect } from "react"
import { Popup } from "devextreme-react/popup"
import TimesheetDataService from "services/TimesheetDataService"
import { Button } from "devextreme-react/button"
import { Form, SimpleItem, RequiredRule } from "devextreme-react/form"
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"

const ViewTimeModal = ({ visible, onHiding, jobID }) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState({})
  const [newAttachments, setNewAttachments] = useState([]) // To store newly uploaded attachments
  const [loading, setLoading] = useState(true)

  // Fetch the timesheet based on jobID
  useEffect(() => {
    const fetchTimesheet = async () => {
      if (jobID) {
        setLoading(true)
        const timesheet = await TimesheetDataService.getLatestTimesheet(jobID)
        setSelectedTimesheet(timesheet || {})
        setLoading(false)
      }
    }

    fetchTimesheet()
  }, [jobID])

  const handleFileUpload = event => {
    const file = event.target.files[0]
    if (file) {
      setNewAttachments([...newAttachments, file])
    }
  }

  const handleSaveAttachments = async () => {
    try {
      const storage = getStorage()
      const attachmentURLs = []

      // Upload all new attachments
      for (let file of newAttachments) {
        const storageReference = storageRef(
          storage,
          `timesheets/${selectedTimesheet.jobID}/attachments/${file.name}`
        )
        await uploadBytes(storageReference, file)
        const downloadURL = await getDownloadURL(storageReference)
        attachmentURLs.push({ name: file.name, url: downloadURL })
      }

      // Update the timesheet with new attachment URLs
      const updatedTimesheet = {
        ...selectedTimesheet,
        attachments: [
          ...(selectedTimesheet.attachments || []),
          ...attachmentURLs,
        ],
      }

      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )
      setSelectedTimesheet(updatedTimesheet)
      setNewAttachments([]) // Clear new attachments after upload
    } catch (error) {
      console.error("Error uploading files:", error)
    }
  }

  const renderAttachments = () => {
    return (
      <fieldset>
        <legend>Attachment(s)</legend>
        {selectedTimesheet.attachments &&
        selectedTimesheet.attachments.length > 0 ? (
          <ul className="attachments-list">
            {selectedTimesheet.attachments.map((attachment, index) => (
              <li key={index}>
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.name}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div>No attachments available.</div>
        )}
      </fieldset>
    )
  }

  const renderNewAttachments = () => {
    return (
      <div className="new-attachments-section">
        <input type="file" onChange={handleFileUpload} />
        {newAttachments.length > 0 && (
          <div>
            <h5>New Attachments</h5>
            <ul>
              {newAttachments.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
            <Button text="Save Attachments" onClick={handleSaveAttachments} />
          </div>
        )}
      </div>
    )
  }

  if (loading) {
    return <p>Loading timesheet...</p>
  }

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showTitle={true}
      title={`Timesheet Details - Job ID: ${selectedTimesheet.jobID}`}
      width={600}
      height={600}
    >
      <div>
        {renderAttachments()}
        {/* {renderNewAttachments()} */}

        <fieldset style={{ marginTop: "10px" }}>
          <legend>Timesheet Information</legend>
          <Form formData={selectedTimesheet}>
            <SimpleItem dataField="jobID" label={{ text: "Job ID" }} />
            <SimpleItem
              dataField="fromDuration"
              label={{ text: "Duration From" }}
            />
            <SimpleItem
              dataField="toDuration"
              label={{ text: "Duration To" }}
            />
            <SimpleItem dataField="breakTime" label={{ text: "Break Time" }} />
            {/* <SimpleItem
              dataField="status"
              editorType="dxSelectBox"
              editorOptions={{
                items: ["Approved", "Dispute", "Rejected"],
                value: selectedTimesheet.status,
              }}
              label={{ text: "Status" }}
            >
              <RequiredRule message="Status is required" />
            </SimpleItem> */}
          </Form>
        </fieldset>
      </div>
    </Popup>
  )
}

export default ViewTimeModal
