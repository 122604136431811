import React, { useState, useEffect } from "react"
import {
  Gantt,
  Tasks,
  Column,
  Editing,
  Toolbar,
  Item,
} from "devextreme-react/gantt"
import JobDataService from "services/JobDataService"
import AllocationModal from "./AllocationModal"
import TimeModal from "./TimeModal"
import CancelJobModal from "./CancelJobModal"
import "devextreme/dist/css/dx.light.css"
import "./master.css"

const MasterGantt = () => {
  const [jobs, setJobs] = useState([])
  const [currentJob, setCurrentJob] = useState(null)
  const [isAllocationModalOpen, setIsAllocationModalOpen] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [isCancelJobModalOpen, setIsCancelJobModalOpen] = useState(false)

  // Fetch job data on component mount
  useEffect(() => {
    const unsubscribe = JobDataService.getAll(data => {
      setJobs(data)
    })
    return () => unsubscribe && unsubscribe()
  }, [])

  // Map jobs data to Gantt chart format with 'id' field and filter invalid rows
  const mapJobsToTasks = jobs => {
    return jobs
      .filter(job => job.jobID) // Filter out jobs without a jobID
      .map(job => ({
        id: job.jobID, // Use jobID as the 'id' field
        title: job.jobTitle || "No Title",
        start: new Date(`${job.startDate}T${job.startTime}:00`),
        end: new Date(`${job.endDate}T${job.endTime}:00`),
        status: job.jobstatus,
      }))
  }

  const tasks = mapJobsToTasks(jobs)

  // Function to handle updates when job dates or details change
  const handleJobUpdate = async updatedTask => {
    const updatedJob = jobs.find(job => job.jobID === updatedTask.id)
    if (updatedJob) {
      updatedJob.startDate = updatedTask.start.toISOString().split("T")[0]
      updatedJob.endDate = updatedTask.end.toISOString().split("T")[0]
      updatedJob.startTime = updatedTask.start
        .toTimeString()
        .split(" ")[0]
        .slice(0, 5)
      updatedJob.endTime = updatedTask.end
        .toTimeString()
        .split(" ")[0]
        .slice(0, 5)
      await JobDataService.updateJob(updatedJob.jobID, updatedJob)
      setJobs([...jobs])
    }
  }

  return (
    <div className="page-content">
      <h2> Gantt View</h2>
      <h1 style={{ float: "right" }}>
        <a href="master" className="">
          Scheduler View
        </a>{" "}
        |{" "}
        <a href="masterk" className="">
          Kanban View
        </a>
      </h1>
      <Gantt
        taskListWidth={500}
        height={600}
        taskTitlePosition="outside"
        onTaskUpdated={e => handleJobUpdate(e.values)} // Update job when task is modified
        onSelectionChanged={e =>
          setCurrentJob(jobs.find(job => job.jobID === e.selectedRowKey))
        } // Set current job on selection
      >
        <Tasks dataSource={tasks} />

        {/* Toolbar to include actions */}
        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>

        {/* Enable editing for Gantt */}
        <Editing
          allowTaskUpdating={true}
          allowTaskAdding={false}
          allowTaskDeleting={false}
        />

        {/* Task columns displayed in the Gantt task list */}
        <Column dataField="title" caption="Job Title" width={300} />
        <Column dataField="start" caption="Start Date" dataType="date" />
        <Column dataField="end" caption="End Date" dataType="date" />
      </Gantt>

      {/* Modals for Job Allocation, Timesheet, and Cancellation */}
      <AllocationModal
        visible={isAllocationModalOpen}
        onHiding={() => setIsAllocationModalOpen(false)}
        jobData={currentJob}
      />

      <TimeModal
        visible={isTimeModalOpen}
        onHiding={() => setIsTimeModalOpen(false)}
        jobData={currentJob}
      />

      <CancelJobModal
        visible={isCancelJobModalOpen}
        onHiding={() => setIsCancelJobModalOpen(false)}
        jobData={currentJob}
      />
    </div>
  )
}

export default MasterGantt
