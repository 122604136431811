import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/press.jpeg"
import newsImage1 from "../../assets/images/news1.jpeg"
import newsImage2 from "../../assets/images/news2.jpeg"
import newsImage3 from "../../assets/images/news3.jpeg"
import "./Press.css"

const Press = () => {
  const pressReleases = [
    {
      title: "Care-Tip Launches New Workforce Management Platform",
      date: "October 15, 2024",
      image: newsImage1,
      excerpt:
        "Care-Tip introduces its groundbreaking platform designed to streamline workforce operations for healthcare providers.",
      link: "#",
    },
    {
      title: "Care-Tip Recognized as Industry Leader in Staffing Solutions",
      date: "September 10, 2024",
      image: newsImage2,
      excerpt:
        "Care-Tip earns accolades for its innovative approach to staffing and workforce management.",
      link: "#",
    },
    {
      title: "New Insights Report: Trends in Workforce Management",
      date: "August 25, 2024",
      image: newsImage3,
      excerpt:
        "Care-Tip releases its annual report highlighting key trends in workforce and compliance management.",
      link: "#",
    },
  ]

  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Press & Media</h1>
            <p className="lead">
              Explore the latest news, updates, and press releases from
              Care-Tip.
            </p>
            <Button color="primary" size="lg">
              Contact Press Team
            </Button>
          </Container>
        </section>

        {/* Latest News Section */}
        <section className="latest-news-section my-5">
          <Container>
            <h2 className="text-center mb-4">Latest News & Press Releases</h2>
            <Row>
              {pressReleases.map((release, index) => (
                <Col md="4" className="mb-4" key={index}>
                  <Card className="h-100 shadow border-0">
                    <img
                      src={release.image}
                      alt={release.title}
                      className="card-img-top"
                    />
                    <CardBody>
                      <CardTitle tag="h5">{release.title}</CardTitle>
                      <p className="text-muted">{release.date}</p>
                      <CardText>{release.excerpt}</CardText>
                      <Button color="primary" href={release.link}>
                        Read More
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* Media Resources Section */}
        <section className="media-resources-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">Media Resources</h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Press Kit</h5>
                <p>
                  Access our logos, brand guidelines, and high-quality images
                  for media use.
                </p>
                <Button color="primary" href="#press-kit">
                  Download Press Kit
                </Button>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Contact Press Team</h5>
                <p>
                  Reach out to our press team for interviews, quotes, or
                  additional information.
                </p>
                <Button color="secondary" href="#contact-press">
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action Section */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Stay Updated</h2>
            <p className="lead mb-4">
              Subscribe to our newsletter to receive the latest updates and news
              directly to your inbox.
            </p>
            <Button color="primary" size="lg" href="#subscribe">
              Subscribe Now
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default Press
