import React, { useState, useEffect, useRef } from "react"
import "./invoices.css"
import FinanceDataService from "./../../services/FinanceDataService"
import { useNavigate } from "react-router-dom"

const InvoiceManager = () => {
  const [invoices, setInvoices] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState("") // New state for search term
  const navigate = useNavigate()

  useEffect(() => {
    FinanceDataService.getAll(fetchedInvoices => {
      setInvoices(fetchedInvoices)
    })
  }, [])

  const handleInvoiceClick = invoice => {
    setSelectedInvoice(invoice)
    setShowModal(true)
  }

  const createInvoice = () => {
    navigate("/invoiceform")
  }

  // Delete invoice with confirmation
  const handleDelete = async invoiceKey => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this invoice?"
    )
    if (confirmDelete) {
      try {
        await FinanceDataService.deleteFinance(invoiceKey)
        setInvoices(invoices.filter(invoice => invoice.key !== invoiceKey))
        alert("Invoice deleted successfully.")
      } catch (error) {
        alert("Error deleting invoice: " + error.message)
      }
    }
  }

  // Filter invoices based on search term
  const filteredInvoices = invoices.filter(invoice => {
    const invoiceHeader = invoice.invoiceHeader || {} // Safely access invoiceHeader
    const invoiceNumber = invoiceHeader.invoiceNumber || "" // Default to empty string if undefined
    const fromOrg = invoiceHeader.fromOrg || ""
    const toOrg = invoiceHeader.toOrg || ""

    // Search by invoice number, fromOrg, or toOrg (case-insensitive)
    return (
      invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fromOrg.toLowerCase().includes(searchTerm.toLowerCase()) ||
      toOrg.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  return (
    <>
      <div className="page-content">
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-block"
            type="submit"
            onClick={createInvoice}
          >
            Create a new invoice
          </button>
          <br />
          <div style={{ float: "right" }}>
            {`Displaying ${filteredInvoices.length} invoice(s)`}
          </div>
        </span>

        <h2>Invoices</h2>

        {/* Search Input */}
        <div style={{ marginBottom: "20px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search invoices by number, from or to organization..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)} // Update search term on input
          />
        </div>

        <div className="invoice-grid">
          {filteredInvoices.map(invoice => (
            <div key={invoice.key} className="invoice-card">
              <h3>
                {invoice.invoiceHeader?.invoiceNumber || invoice.invoiceNo}
              </h3>
              <p>
                Due Date: {invoice.invoiceHeader?.dueDate || invoice.dueDate}
              </p>
              <p>
                Grand Total: £
                {
                  // Ensure grandTotal is a number, provide fallback if necessary
                  invoice.grandTotal && !isNaN(invoice.grandTotal)
                    ? Number(invoice.grandTotal).toFixed(2)
                    : invoice.amount && !isNaN(invoice.amount)
                    ? Number(invoice.amount).toFixed(2)
                    : "N/A" // Fallback if neither grandTotal nor amount is valid
                }
              </p>
              {/* Check if the file URL exists and render a link */}
              {invoice.fileURL && (
                <a
                  href={invoice.fileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Attachment
                </a>
              )}
              <div className="invoice-actions">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(invoice.key)}
                >
                  Delete
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-info"
                  onClick={() => handleInvoiceClick(invoice)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>

        {showModal && (
          <InvoiceModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
              setSelectedInvoice(null)
            }}
            invoiceData={selectedInvoice}
          />
        )}
      </div>
    </>
  )
}

const InvoiceModal = ({ show, onClose, invoiceData }) => {
  const printRef = useRef()

  const handlePrint = () => {
    const content = printRef.current
    const pri = document.getElementById("ifmcontentstoprint").contentWindow
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  }

  if (!show) {
    return null
  }

  return (
    <>
      <div className="invoice-modal-overlay">
        <div className="invoice-modal-container">
          <iframe
            id="ifmcontentstoprint"
            style={{ height: 0, width: 0, position: "absolute" }}
          >
            {" "}
          </iframe>

          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                background: "white",
                padding: 20,
                borderRadius: 5,
                width: "70%",
                maxHeight: "90%",
                overflowY: "auto",
              }}
            >
              <div style={{ marginTop: 20 }}>
                <button onClick={handlePrint}>Print Invoice</button>
                <button onClick={onClose} style={{ marginLeft: 10 }}>
                  Close
                </button>
              </div>
              <div ref={printRef}>
                <h2>
                  Invoice Number: {invoiceData.invoiceHeader.invoiceNumber}
                </h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <h3>From:</h3>
                    <div>{invoiceData.invoiceHeader.fromOrg}</div>
                    <div>{invoiceData.invoiceHeader.fromOrgAddress}</div>
                    <div>Email: {invoiceData.invoiceHeader.fromOrgEmail}</div>
                  </div>
                  <div>
                    <h3>To:</h3>
                    <div>{invoiceData.invoiceHeader.toOrg}</div>
                    <div>{invoiceData.invoiceHeader.toOrgAddress}</div>
                    <div>Email: {invoiceData.invoiceHeader.toOrgEmail}</div>
                    <div>VAT: {invoiceData.invoiceHeader.toOrgVAT}</div>
                  </div>
                </div>

                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Agency Name
                      </th>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Activity
                      </th>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Description
                      </th>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Hours
                      </th>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Rate
                      </th>
                      <th
                        style={{ border: "1px solid black", textAlign: "left" }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.lineItems.map((item, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid black" }}>
                          {item.agencyName}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {item.activity}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {item.description}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {item.hours}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {item.nightRate}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {item.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div style={{ marginTop: 20, float: "right" }}>
                  <div>
                    <h5>
                      Total:{" "}
                      <span style={{ float: "right" }}>
                        £{invoiceData.total.toFixed(2)}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h5>
                      VAT:{" "}
                      <span style={{ float: "right" }}>
                        £{invoiceData.vat.toFixed(2)}
                      </span>
                    </h5>
                  </div>
                  <div style={{ fontStyle: "bold" }}>
                    <h4>
                      Grand Total:{" "}
                      <span style={{ float: "right" }}>
                        £{invoiceData.grandTotal.toFixed(2)}
                      </span>
                    </h4>
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <div>
                    <strong>Invoice Date:</strong>{" "}
                    {invoiceData.invoiceHeader.invoiceDate}
                  </div>
                  <div>
                    <strong>Due Date:</strong>{" "}
                    {invoiceData.invoiceHeader.dueDate}
                  </div>
                  <div>
                    <strong>Terms:</strong> {invoiceData.invoiceHeader.terms}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceManager
