// Firebase imports
import { initializeApp } from "firebase/app"
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
  equalTo,
  get, 
  child
} from "firebase/database"
// Import Firestore functions
import {
  getFirestore,
  doc,        // Reference to a document in Firestore
  collection, // Reference to a sub-collection in Firestore
  getDocs,    // Fetch documents from Firestore
} from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)
const firestoreDb = getFirestore(app);  // Initialize Firestore

class WorkersDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/worker")
  }

  // Fetch workers by jobID using Realtime Database
  async getWorkersByJob(jobID) {
    const workersByJobRef = query(
      this.dbRef,
      orderByChild("jobID"),
      equalTo(jobID)
    )

    try {
      const snapshot = await get(workersByJobRef)
      if (snapshot.exists()) {
        const data = snapshot.val()
        // Map the result into an array format
        return Object.keys(data).map(key => ({ id: key, ...data[key] }))
      } else {
        console.log("No workers found for the jobID:", jobID)
        return []
      }
    } catch (error) {
      console.error("Error fetching workers by job:", error)
      throw error
    }
  }

  // Fetch documents by worker ID and document type (from Firestore)
  async getDocumentsByType(workerID, documentType) {
    try {
      // Reference the worker document in Firestore
      const workerDocRef = doc(firestoreDb, "workers", workerID)

      // Reference the sub-collection (cvs, certificates, etc.) under the worker document
      const documentsCollectionRef = collection(workerDocRef, documentType)

      // Fetch the documents in the sub-collection
      const querySnapshot = await getDocs(documentsCollectionRef)

      if (querySnapshot.empty) {
        console.log(
          `No documents found for worker ${workerID} in ${documentType}`
        )
        return []
      }

      // Map the Firestore documents to the required format
      const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        url: doc.data()[this.getDocFieldByType(documentType)] || "",
      }))

      return documents
    } catch (error) {
      console.error(
        `Error fetching ${documentType} for worker ${workerID}:`,
        error
      )
      throw error
    }
  }

  // Helper function to get document field name by type
  getDocFieldByType(documentType) {
    const docFields = {
      certificates: "CertificateDoc",
      covids: "CovidDoc",
      cvs: "CVDoc",
      disclosures: "DisclosureDoc",
      jobreferences: "JobReferenceDoc",
      nationalinsurances: "NationalInsuranceDoc",
      proofs: "ProofDoc",
      vaccinations: "VaccinationDoc",
      works: "WorkDoc",
    }

    return docFields[documentType] || null
  }

  getWorkersCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getWorkersByTypeAndProvider(jobType, provider, callback) {
    const workersByProviderRef = query(
      this.dbRef,
      orderByChild("provider"),
      equalTo(provider)
    )

    const promise = get(workersByProviderRef)
      .then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          const workers = Object.keys(data)
            .map(key => ({ key, ...data[key] }))
            .filter(worker => worker.jobType === jobType)
          return workers
        } else {
          return []
        }
      })
      .catch(error => {
        console.error("Error fetching workers by type and provider:", error)
        throw error
      })

    if (callback && typeof callback === "function") {
      promise
        .then(workers => callback(workers))
        .catch(error => callback(null, error))
    }

    return promise
  }

  getWorkersByCompanyName(companyName, callback) {
    const workersByCompanyRef = query(
      this.dbRef,
      orderByChild("companyName"),
      equalTo(companyName)
    )

    return get(workersByCompanyRef).then(snapshot => {
      const data = snapshot.val()
      let workers = []
      if (data) {
        workers = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      if (typeof callback === "function") {
        callback(workers)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  // Fetch worker by workerID and return first name and last name
  async getWorkerFullNameById(workerID) {
    const workerRef = ref(getDatabase(), `/worker/${workerID}`)

    try {
      const snapshot = await get(workerRef)
      if (snapshot.exists()) {
        const workerData = snapshot.val()
        const fullName = `${workerData.firstName} ${workerData.lastName}`
        return fullName // Return the concatenated first and last name
      } else {
        console.log("No worker found with workerID:", workerID)
        return null
      }
    } catch (error) {
      console.error("Error fetching worker by ID:", error)
      throw error
    }
  }

  getAll(callback) {
    const workersByLatestRef = query(this.dbRef, orderByChild("createdAt"))

    return onValue(workersByLatestRef, snapshot => {
      const data = snapshot.val()
      let workers = []

      if (data) {
        workers = Object.keys(data).map(key => ({ key, ...data[key] }))
        workers.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
      }

      if (typeof callback === "function") {
        callback(workers)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  findWorkerByEmail(email) {
    return this.getAll().then(workers => {
      const foundWorker = workers.find(
        worker => worker.workerEmailAddress === email
      )
      return foundWorker
    })
  }

  createWorker(worker) {
    return push(this.dbRef, worker)
  }

  updateWorker(key, value) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return update(specificWorkerRef, value)
  }

  deleteWorker(key) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return remove(specificWorkerRef)
  }
}

export default new WorkersDataService()
