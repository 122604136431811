import React, { useState, useEffect } from "react"
import {
  Scheduler,
  Resource,
  View,
  Editing,
  AppointmentDragging,
  Scrolling,
} from "devextreme-react/scheduler"
import { Popup } from "devextreme-react/popup"
import {
  Form,
  SimpleItem,
  RequiredRule,
  ButtonItem,
  GroupItem,
} from "devextreme-react/form"
import { DataGrid, Column, Pager, Paging } from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"
import JobDataService from "services/JobDataService"
import AllocationModal from "./AllocationModal"
import TimeModal from "./TimeModal"
import ViewTimeModal from "./ViewTimeModal"
import CancelJobModal from "./CancelJobModal"
import WorkersAllocationService from "services/WorkersAllocationService"
import OrganisationDataService from "services/OrganisationDataService"
import TimesheetDataService from "services/TimesheetDataService"

import './master.css';
  
const Master = () => {
  const [jobs, setJobs] = useState([])
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false)
  const [currentAppointmentData, setCurrentAppointmentData] = useState(null)
  const [isAllocationModalOpen, setIsAllocationModalOpen] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [isViewTimeModalOpen, setIsViewTimeModalOpen] = useState(false)
  const [isCancelJobModalOpen, setIsCancelJobModalOpen] = useState(false)
  const [organisation, setOrganisation] = useState("")
  const [loading, setLoading] = useState(false)
  const [filteredJobs, setFilteredJobs] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [isViewTimesheetModalOpen, setIsViewTimesheetModalOpen] = useState(false)
  const [timesheetData, setTimesheetData] = useState(null)
  const [loadingTimesheet, setLoadingTimesheet] = useState(false)

  // Function to fetch timesheet data when the job is completed
  // Function to fetch timesheet data when the Popup is open and the job is completed
  useEffect(() => {
    const fetchTimesheet = async () => {
      if (
        isAppointmentFormOpen &&
        currentAppointmentData?.jobstatus === "completed"
      ) 
      {
        // console.log("Loading timesheet data")
        setLoadingTimesheet(true)
        const timesheet = await TimesheetDataService.getLatestTimesheet(
          currentAppointmentData?.jobID
        )
        // console.log("TSS: " + JSON.stringify(timesheet))
        setTimesheetData(timesheet)
        setLoadingTimesheet(false)
      }
    }

    fetchTimesheet()
  }, [isAppointmentFormOpen, currentAppointmentData])

  const openViewTimesheetModal = () => {
    setIsViewTimesheetModalOpen(true)
  }

  const closeViewTimesheetModal = () => {
    setIsViewTimesheetModalOpen(false)
  }

  const renderTimesheetModalContent = () => {
    if (!timesheetData) {
      return <div>No timesheet found.</div>
    }

    return (
      <div>
        <h3>Timesheet for Job: {timesheetData.jobID}</h3>
        <p>
          <strong>Status:</strong> {timesheetData.status}
        </p>
        <p>
          <strong>Duration:</strong> {timesheetData.fromDuration} -{" "}
          {timesheetData.toDuration}
        </p>
        <p>
          <strong>Break Time:</strong> {timesheetData.breakTime} hours
        </p>

        {timesheetData.attachments && timesheetData.attachments.length > 0 && (
          <div>
            <h4>Attachments:</h4>
            <ul>
              {timesheetData.attachments.map((attachment, index) => (
                <li key={index}>
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attachment.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  const openAppointmentForm = appointmentData => {
    setCurrentAppointmentData(appointmentData)
    setIsAppointmentFormOpen(true)
  }

  // const closeAppointmentForm = () => {
  //   setIsAppointmentFormOpen(false)
  //   setCurrentAppointmentData(null)
  // }

    const closeAppointmentForm = () => {
      setIsAppointmentFormOpen(false)
    }

  const openAllocationModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsAllocationModalOpen(true)
  }

  const closeAllocationModal = () => {
    setIsAllocationModalOpen(false)
    window.location.href ='/master';
  }

  const openTimeModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsTimeModalOpen(true)
  }

    const viewTimeModal = jobData => {
      setCurrentAppointmentData(jobData)
      setIsViewTimeModalOpen(true)
    }

  const closeTimeModal = () => {
    setIsTimeModalOpen(false)
  }

    const closeViewTimeModal = () => {
      setIsViewTimeModalOpen(false)
    }


  const openCancelJobModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsCancelJobModalOpen(true)
  }

  const closeCancelJobModal = () => {
    setIsCancelJobModalOpen(false)
  }

  useEffect(() => {
    const unsubscribe = JobDataService.getAll(data => {
      setJobs(data)
      setFilteredJobs(data) // Initially, filteredJobs is the same as jobs
    })
    return () => unsubscribe && unsubscribe()
  }, [])

  const formatJobsForScheduler = jobsData => {
    const uniqueJobs = new Set()

    return jobsData
      .filter(job => {
        const jobID = job.jobID || job.key
        if (uniqueJobs.has(jobID)) return false
        uniqueJobs.add(jobID)
        return true
      })
      .map(job => {
        const startDate = new Date(`${job.startDate}T${job.startTime}:00`)
        const endDate = new Date(`${job.endDate}T${job.endTime}:00`)

        let resource
        switch (job.jobstatus) {
          case "open":
            resource = "open"
            break
          case "open over-due":
            resource = "openOverdue"
            break
          case "allocated":
            resource = "allocated"
            break
          case "completed":
            resource = "completed"
            break
          case "cancelled":
            resource = "cancelled"
            break
          default:
            resource = "default"
        }

        return {
          id: job.jobID || job.key, // Ensure jobID is used as the unique ID
          provider: job.provider,
          company: job.company,
          jobType: job.jobType,
          text: job.jobTitle || "",
          jobTitle: job.jobTitle || "",
          startDate,
          endDate,
          startTime: job.startTime,
          endTime: job.endTime,
          location: job.location,
          jobID: job.jobID || job.key,
          resourceId: resource,
          jobstatus: job.jobstatus,
          timesheetStatus: job.timesheetStatus
        }
      })
  }

  // const schedulerData = formatJobsForScheduler(jobs)
  const schedulerData = formatJobsForScheduler(filteredJobs)

  const handleSearchChange = e => {
    const query = e.target.value.toLowerCase()
    setSearchQuery(query)

    const filtered = jobs.filter(job => {
      return (
        (job.jobID && job.jobID.toString().toLowerCase().includes(query)) ||
        (job.jobTitle && job.jobTitle.toLowerCase().includes(query))
      )
    })

    setFilteredJobs(filtered)
  }

  const saveJobToService = async (id, data) => {
    
    console.log(`save job ${id} ${JSON.stringify(data)}`)

    const jobData = {
      ...data,
      key: id || uuidv4(), // If there's no ID, generate a new one
      startDate: data.startDate.toISOString().split("T")[0], // Ensure dates are correctly formatted
      startTime: data.startDate.toTimeString().split(" ")[0].slice(0, 5),
      endDate: data.endDate.toISOString().split("T")[0],
      endTime: data.endDate.toTimeString().split(" ")[0].slice(0, 5),
      location: data.location,
      jobstatus: data.jobstatus,
      provider: data.provider,
      jobType: data.jobType,
    }

    if (id) {
     // console.log("Saving job with jobID: " + id)
      await JobDataService.updateJob(id, jobData)
    } else {
     // console.log("Creating new job with jobID: " + jobData.jobID)
      await JobDataService.createJob(jobData)
    }
  }

  const onAppointmentFormSubmit = async e => {
    const { formData } = e

    await saveJobToService(currentAppointmentData?.id, formData)
    closeAppointmentForm()

    // Refresh the jobs list
    const updatedJobs = await JobDataService.getAllJobs()
    setJobs(updatedJobs)
    // if (formData.jobStatus === "completed") {
    //   window.location.href = "/master";
    // }
    
    window.location.href = "/master"

  }

  const onCancelJobSubmit = async jobData => {
    await saveJobToService(jobData.id, { ...jobData, jobstatus: "cancelled" })
    setIsCancelJobModalOpen(false)
    setIsAppointmentFormOpen(false)
  }

  const onAppointmentFormOpening = async e => {
    const { form, appointmentData } = e

    const formItems = [
      {
        label: { text: "Job ID" },
        dataField: "jobID",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.jobID || appointmentData.id || "",
          readOnly: true,
        },
      },
      {
        label: { text: "Job Title" },
        dataField: "jobTitle",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.jobTitle || "",
        },
      },
      {
        label: { text: "Provider" },
        dataField: "provider",
        editorType: "dxTextBox",
        editorOptions: {
          value:
            (await OrganisationDataService.getOrganisationBusinessName(
              appointmentData.provider
            )) || "",
          readOnly: true,
        },
      },
      {
        label: { text: "Location" },
        dataField: "location",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.location || "",
        },
      },
      {
        label: { text: "Start Date" },
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "datetime",
          displayFormat: "dd/MM/yyyy HH:mm",
          value: appointmentData.startDate || new Date(),
        },
      },
      {
        label: { text: "End Date" },
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "datetime",
          displayFormat: "dd/MM/yyyy HH:mm",
          value: appointmentData.endDate || new Date(),
        },
      },
      {
        label: { text: "Status" },
        dataField: "jobstatus",
        editorType: "dxSelectBox",
        editorOptions: {
          items: [
            { id: "open", text: "Open" },
            { id: "open over-due", text: "Open Over-due" },
            { id: "allocated", text: "Allocated" },
            { id: "completed", text: "Completed" },
            { id: "cancelled", text: "Cancelled" },
          ],
          displayExpr: "text",
          valueExpr: "id",
          value: appointmentData.jobstatus || "open",
        },
      },
      {
        label: { text: "Job Type" },
        dataField: "jobType",
        editorType: "dxSelectBox",
        editorOptions: {
          items: [
            { id: "Support worker", text: "Support worker" },
            { id: "Sleep in", text: "Sleep in" },
            { id: "Health Care Assistant", text: "Health Care Assistant" },
            {
              id: "Registered General Nurse",
              text: "Registered General Nurse",
            },
            {
              id: "Registered Mental Health Nurse",
              text: "Registered Mental Health Nurse",
            },
            { id: "Doctor", text: "Doctor" },
          ],
          displayExpr: "text",
          valueExpr: "id",
          value: appointmentData.jobType || "Support worker",
        },
      },
      {
        label: { text: "Timesheet Status" },
        dataField: "timesheetStatus",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.timesheetStatus || "Not Submitted",
          disabled: true,
        },
        visible: true,
        readOnly: true,
      },
      {
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "Allocation",
          onClick: () => openAllocationModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#8BC34A",
              color: "white",
            },
          },
        },
      },
      {
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "Cancel Job",
          onClick: () => openCancelJobModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#F44336",
              color: "white",
            },
          },
        },
      },
    ]

    if (
      appointmentData.jobstatus === "completed" &&
      appointmentData.timesheetStatus === "Submitted"
    ) {
      formItems.push({
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "View Timesheet",
          onClick: () => viewTimeModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#000",
              color: "white",
            },
          },
        },
      })
    }

        if (
          appointmentData.jobstatus === "completed" &&
          appointmentData.timesheetStatus != "Submitted"
        ) {
          formItems.push({
            itemType: "button",
            horizontalAlignment: "left",
            buttonOptions: {
              text: "Timesheet",
              onClick: () => openTimeModal(appointmentData),
              elementAttr: {
                style: {
                  backgroundColor: "#FFC107",
                  color: "white",
                },
              },
            },
          })
        }

    form.option("items", formItems)
  }

  const renderAppointmentContent = model => {
    const { targetedAppointmentData } = model.data

    if (!targetedAppointmentData || !targetedAppointmentData.resourceId) {
      return <div style={{ height: "100%", padding: "5px" }}>No job</div>
    }

    let color
    switch (targetedAppointmentData.resourceId) {
      case "open":
        color = "#8BC34A"
        break
      case "openOverdue":
        color = "#FF9888"
        break
      case "allocated":
        color = "#FFC107"
        break
      case "completed":
        color = "#4CAF50"
        break
      case "cancelled":
        color = "#F44336"
        break
      default:
        color = "Alice"
    }

    return (
      <div style={{ backgroundColor: color, height: "100%", padding: "5px" }}>
        <b>
          {targetedAppointmentData.jobTitle || "No title"} -{" "}
          {targetedAppointmentData.jobID}
        </b>
        <br />
        <span>
          {new Date(targetedAppointmentData.startDate).toLocaleString("en-GB")}{" "}
          - {new Date(targetedAppointmentData.endDate).toLocaleString("en-GB")}
        </span>
      </div>
    )
  }

  return (
    <div className="page-content">
      {/* Search Bar */}
      <div className="legend" style={{ float: "left" }}>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#8BC34A" }}
          ></span>
          <span className="label">Open</span>
        </div>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#FF9888" }}
          ></span>
          <span className="label">Open Over-due</span>
        </div>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#FFC107" }}
          ></span>
          <span className="label">Allocated</span>
        </div>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#4CAF50" }}
          ></span>
          <span className="label">Completed</span>
        </div>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#F44336" }}
          ></span>
          <span className="label">Cancelled</span>
        </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <h1 style={{ float: "right" }}>
          <a href="masterk" className="">
            Kanban View
          </a>{" "}
          |{" "}
          <a href="masterg" className="">
            Gantt View
          </a>
        </h1>

        <label>Search by Job ID or Job Title</label>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by Job ID or Job Title"
          style={{
            padding: "10px",
            width: "100%",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      </div>

      <Scheduler
        dataSource={schedulerData}
        defaultCurrentView="month"
        startDayHour={0}
        endDayHour={24}
        showAllDayPanel={true}
        height={600}
        editing={{
          allowAdding: false,
          allowDeleting: true,
          allowUpdating: true,
        }}
        appointmentComponent={renderAppointmentContent}
        onAppointmentUpdating={async e => {
          const jobID = e.oldData.jobID || e.oldData.id
          const formData = {
            jobTitle: e.newData.jobTitle || e.oldData.jobTitle,
            provider: e.newData.provider || e.oldData.provider,
            startDate: new Date(e.newData.startDate),
            endDate: new Date(e.newData.endDate),
            jobstatus: e.newData.jobstatus,
            location: e.newData.location,
            jobType: e.newData.jobType,
            resourceId: e.newData.jobstatus.replace(" ", ""),
          }

          await saveJobToService(jobID, formData)
          setJobs(prevJobs =>
            prevJobs.map(job =>
              job.jobID === jobID
                ? {
                    ...job,
                    jobstatus: e.newData.jobstatus,
                    resourceId: e.newData.jobstatus.replace(" ", ""),
                  }
                : job
            )
          )
        }}
        onAppointmentDeleting={async e => {
          await JobDataService.deleteJob(e.appointmentData.id)
        }}
        onAppointmentFormOpening={onAppointmentFormOpening}
      >
        <View type="day" name="Day View" />
        <View type="week" name="Week View" />
        <View type="month" name="Month View" />

        <Resource
          dataSource={[
            { id: "open", text: "Open", color: "#8BC34A" },
            { id: "openOverdue", text: "Open Over-due", color: "#FF9888" },
            { id: "allocated", text: "Allocated", color: "#FFC107" },
            { id: "completed", text: "Completed", color: "#4CAF50" },
            { id: "cancelled", text: "Cancelled", color: "#F44336" },
          ]}
          fieldExpr="resourceId"
          label="Job Status"
          useColorAsDefault={true}
        />
        <Editing allowResizing allowDragging allowUpdating />
        <AppointmentDragging />
        <Scrolling mode="virtual" />
      </Scheduler>

      <DataGrid
        dataSource={schedulerData}
        keyExpr="id"
        showBorders={true}
        // onRowClick={e => openAppointmentForm(e.data)}
      >
        <Column dataField="id" caption="ID" width={200} />
        <Column dataField="jobTitle" caption="Job Title" />
        <Column dataField="jobstatus" caption="Job Status" />
        <Column
          dataField="startDate"
          caption="Start Date"
          dataType="datetime"
          format="dd/MM/yyyy"
        />
        <Column
          dataField="endDate"
          caption="End Date"
          dataType="datetime"
          format="dd/MM/yyyy"
        />
        <Pager visible={true} />
        <Paging pageSize={10} />
      </DataGrid>

      <Popup
        visible={isViewTimesheetModalOpen}
        onHiding={closeViewTimesheetModal}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="View Timesheet"
        width={500}
        height={400}
        position={{ my: "center", at: "center", of: window }}
      >
        {loadingTimesheet ? (
          <p>Loading timesheet...</p>
        ) : (
          renderTimesheetModalContent()
        )}
      </Popup>

      <Popup
        visible={isAppointmentFormOpen}
        onHiding={closeAppointmentForm}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        // Display the provider as the title, or fallback to "Job Details" if unavailable
        title={currentAppointmentData?.jobID || "Job Details"}
        width={500}
        height={400}
        position={{ my: "center", at: "center", of: window }}
      >
        {/* Appointment Form Content */}
        {loadingTimesheet ? (
          <p>Loading timesheet...</p>
        ) : (
          <div>
            {/* Your Appointment Form or Timesheet Data goes here */}
            {timesheetData ? (
              <div>
                <h3>Timesheet Details</h3>
                {/* Render the timesheet details */}
              </div>
            ) : (
              <p>No timesheet found for this job.</p>
            )}
          </div>
        )}
        <Form
          formData={currentAppointmentData}
          // onEditorEnterKey={onAppointmentFormSubmit}
          onSubmit={onAppointmentFormSubmit}
        >
          <GroupItem colCount={1}>
            <SimpleItem dataField="jobID" label={{ text: "Job ID" }} />
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem dataField="jobTitle" isRequired={true}>
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="provider"
              isRequired={true}
              editorOptions={{ defaultValue: null }}
            >
              <RequiredRule />
              <div>{loading ? "Loading provider..." : organisation}</div>
            </SimpleItem>
            {/* <SimpleItem dataField="location" isRequired={true}>
              <RequiredRule />
            </SimpleItem> */}
            <SimpleItem
              dataField="startDate"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                displayFormat: "dd/MM/yyyy HH:mm",
              }}
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="endDate"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                displayFormat: "dd/MM/yyyy HH:mm",
              }}
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="jobstatus"
              editorType="dxSelectBox"
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="jobType"
              editorType="dxSelectBox"
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
          </GroupItem>
          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              text: "Allocation",
              onClick: () => openAllocationModal(currentAppointmentData),
              elementAttr: {
                style: {
                  backgroundColor: "#8BC34A",
                  color: "white",
                },
              },
            }}
          />
          {currentAppointmentData?.jobstatus === "completed" && (
            <ButtonItem
              horizontalAlignment="left"
              buttonOptions={{
                text: "Timesheet",
                onClick: () => openTimeModal(currentAppointmentData),
                elementAttr: {
                  style: {
                    backgroundColor: "#FFC107",
                    color: "white",
                  },
                },
              }}
            />
          )}

          {currentAppointmentData?.jobstatus === "completed" &&
            currentAppointmentData?.timesheetStatus === "Submitted" && (
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={{
                  text: "View Timesheet",
                  onClick: () => openTimeModal(currentAppointmentData),
                  elementAttr: {
                    style: {
                      backgroundColor: "#FFC107",
                      color: "white",
                    },
                  },
                }}
              />
            )}

          {currentAppointmentData?.jobstatus === "completed" &&
            timesheetData && (
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={{
                  text: "View Timesheet",
                  onClick: openViewTimesheetModal,
                  elementAttr: {
                    style: {
                      backgroundColor: "#2196F3",
                      color: "white",
                    },
                  },
                }}
              />
            )}

          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              text: "Cancel Job...",
              onClick: () => openCancelJobModal(currentAppointmentData),
              elementAttr: {
                style: {
                  backgroundColor: "#F44336",
                  color: "white",
                },
              },
            }}
          />
        </Form>
      </Popup>

      <AllocationModal
        visible={isAllocationModalOpen}
        onHiding={closeAllocationModal}
        jobData={currentAppointmentData}
      />
      <TimeModal
        visible={isTimeModalOpen}
        onHiding={closeTimeModal}
        jobData={currentAppointmentData}
      />

      <ViewTimeModal
        visible={isViewTimeModalOpen}
        onHiding={closeViewTimeModal}
        jobID={currentAppointmentData?.jobID}
      />

      <CancelJobModal
        visible={isCancelJobModalOpen}
        onHiding={closeCancelJobModal}
        jobData={currentAppointmentData}
        onSubmit={onCancelJobSubmit}
      />
    </div>
  )
}

export default Master
