import React, { useState, useEffect } from "react"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import JobDataService from "services/JobDataService"
import AllocationModal from "./AllocationModal"
import TimeModal from "./TimeModal"
import CancelJobModal from "./CancelJobModal"
import "leaflet/dist/leaflet.css"
import "./master.css"

// Custom marker icon to avoid default broken icon issue
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const MasterMap = () => {
  const [jobs, setJobs] = useState([])
  const [currentJob, setCurrentJob] = useState(null)
  const [isAllocationModalOpen, setIsAllocationModalOpen] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [isCancelJobModalOpen, setIsCancelJobModalOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const jobData = await JobDataService.getAllJobs()
      setJobs(jobData)
    }

    fetchData()
  }, [])

  // Helper function to map job locations
  const mapJobsToMarkers = jobs => {
    return jobs
      .filter(job => job.latitude && job.longitude) // Filter out jobs without location
      .map(job => ({
        id: job.jobID,
        title: job.jobTitle || "Untitled Job",
        position: [job.latitude, job.longitude], // Use job's latitude and longitude
        details: job,
      }))
  }

  const markers = mapJobsToMarkers(jobs)

  return (
    <div className="page-content">
      <h2>Job Management - Map View</h2>

      {/* MapContainer from React Leaflet */}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={12}
        style={{ height: "600px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Add markers for each job */}
        {markers.map(marker => (
          <Marker
            key={marker.id}
            position={marker.position}
            eventHandlers={{
              click: () => {
                setCurrentJob(marker.details) // Set the clicked job as current job
              },
            }}
          >
            <Popup>
              <strong>{marker.title}</strong>
              <br />
              Location: {marker.position.join(", ")}
              <br />
              Start: {marker.details.startDate} {marker.details.startTime}
              <br />
              End: {marker.details.endDate} {marker.details.endTime}
              <br />
              <button onClick={() => setIsAllocationModalOpen(true)}>
                Allocate Worker
              </button>
              <button onClick={() => setIsCancelJobModalOpen(true)}>
                Cancel Job
              </button>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/* Modals for allocation, timesheet, and cancel job */}
      <AllocationModal
        visible={isAllocationModalOpen}
        onHiding={() => setIsAllocationModalOpen(false)}
        jobData={currentJob}
      />
      <TimeModal
        visible={isTimeModalOpen}
        onHiding={() => setIsTimeModalOpen(false)}
        jobData={currentJob}
      />
      <CancelJobModal
        visible={isCancelJobModalOpen}
        onHiding={() => setIsCancelJobModalOpen(false)}
        jobData={currentJob}
      />
    </div>
  )
}

export default MasterMap
