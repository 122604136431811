import React from "react"
import { Container, Row, Col } from "reactstrap"
import logo from "../../assets/images/caretip.jpg" // Replace with the actual path to your logo
import "./Footer.css" // Optional for additional styling

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footerx py-4 bg-light">
        <Container>
          <Row>
            {/* Left Section: Logo */}
            <Col md={4} className="d-flex align-items-center">
              <img
                src={logo}
                alt="Care-Tip Logo"
                className="footer-logo me-0"
              />
              <span className="footer-catchphrase">
                Simplifying Workforce Management.
              </span>
            </Col>

            {/* Right Section: Categories of Links */}
            <Col md={8}>
              <Row>
                <Col md={4} sm={6} xs={12}>
                  <h5 className="footer-heading">Company</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/careers">Careers</a>
                    </li>
                    <li>
                      <a href="/press">Press</a>
                    </li>
                  </ul>
                </Col>

                <Col md={4} sm={6} xs={12}>
                  <h5 className="footer-heading">Services</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/staffing">Staffing</a>
                    </li>
                    <li>
                      <a href="/recruit">Recruitment</a>
                    </li>
                    <li>
                      <a href="/rota">Rota Management</a>
                    </li>
                    <li>
                      <a href="/digitaltime">Digital Timesheet</a>
                    </li>
                    <li>
                      <a href="/invoices">Invoicing</a>
                    </li>
                  </ul>
                </Col>

                <Col md={4} sm={6} xs={12}>
                  <h5 className="footer-heading">Resources</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/blog">Blog</a>
                    </li>
                    <li>
                      <a href="/guides">Guide</a>
                    </li>
                    <li>
                      <a href="/contactus">Contact us</a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Catchphrase at the bottom */}
          <Row className="mt-3">
            <Col className="text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} Care-Tip. All rights reserved.
                Designed & Developed by Precious Tech.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
