import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/timesheet.jpeg"
import feature1 from "../../assets/images/timetracking.jpeg"
import feature2 from "../../assets/images/autocalc.jpeg"
import feature3 from "../../assets/images/cloudstorage.jpeg"
import "./DigitalTimesheet.css"
import BookADemo from "./BookADemo"
import ContactUs from "./ContactUs"

const DigitalTimesheet = () => {
          const BookADemo = () => {
            location.href = "/book"
          }

          const ContactUs = () => {
            location.href = "/contactus"
          }
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Revolutionize Your Timesheets</h1>
            <p className="lead">
              Effortlessly track and manage employee hours with our Digital
              Timesheet solution.
            </p>
            <Button color="primary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>

        {/* Features Section */}
        <section className="features-section my-5">
          <Container>
            <h2 className="text-center mb-4">Key Features</h2>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Real-Time Tracking</CardTitle>
                    <CardText>
                      Track employee hours and activities in real time to
                      eliminate inaccuracies.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Automated Calculations</CardTitle>
                    <CardText>
                      Automatically calculate hours worked, overtime, and pay.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Cloud-Based Storage</CardTitle>
                    <CardText>
                      Access timesheets from anywhere with secure cloud-based
                      storage.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">Benefits of Digital Timesheets</h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Improved Accuracy</h5>
                <p>
                  Eliminate manual errors and ensure precise record-keeping for
                  payroll and reporting.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Time-Saving Efficiency</h5>
                <p>
                  Save hours of administrative work with automated processes and
                  calculations.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Enhanced Transparency</h5>
                <p>
                  Provide employees with visibility into their hours and
                  approvals.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Cost Reduction</h5>
                <p>
                  Cut costs by streamlining timesheet management and reducing
                  errors.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section my-5">
          <Container>
            <h2 className="text-center mb-4">What Our Clients Say</h2>
            <Row>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "The digital timesheet system has drastically improved our
                      payroll accuracy and saved us countless hours."
                    </CardText>
                    <p className="text-muted text-end">
                      - Emily R., Payroll Manager
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" className="mb-4">
                <Card className="shadow border-0">
                  <CardBody>
                    <CardText>
                      "A must-have for any organization looking to simplify time
                      tracking and management."
                    </CardText>
                    <p className="text-muted text-end">
                      - Jonathan D., Operations Lead
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Digitize Your Timesheets?</h2>
            <p className="lead mb-4">
              Contact us today to learn more about our Digital Timesheet
              solutions or to schedule a demo.
            </p>
            <Button
              color="primary"
              size="lg"
              className="me-3"
              onClick={ContactUs}
            >
              Contact Us
            </Button>
            <Button color="secondary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default DigitalTimesheet
