import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"
import heroImage from "../../assets/images/staffing.jpeg"
import feature1 from "../../assets/images/ondemand.jpeg"
import feature2 from "../../assets/images/scheduling.jpeg"
import feature3 from "../../assets/images/complianceassurance.jpeg"
import "./StaffingService.css"

const StaffingService = () => {
          const BookADemo = () => {
            location.href = "/book"
          }

          const ContactUs = () => {
            location.href = "/contactus"
          }
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Professional Staffing Services</h1>
            <p className="lead">
              Connecting the right talent with the right opportunities, every
              time.
            </p>
            <Button color="primary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>

        {/* Service Highlights */}
        <section className="service-highlights my-5">
          <Container>
            <h2 className="text-center mb-4">Our Key Features</h2>
            <Row>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">On-Demand Staffing</CardTitle>
                    <CardText>
                      Quickly fill open shifts with qualified professionals
                      available 24/7.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Effortless Scheduling</CardTitle>
                    <CardText>
                      Simplify workforce scheduling with our intuitive platform.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" className="mb-4">
                <Card className="h-100 shadow border-0">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="card-img-top"
                  />
                  <CardBody>
                    <CardTitle tag="h5">Compliance Assurance</CardTitle>
                    <CardText>
                      Ensure all staff meet regulatory compliance requirements
                      before deployment.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">
              Why Choose Our Staffing Service?
            </h2>
            <Row>
              <Col md="6" className="mb-4">
                <h5>Cost-Effective Solutions</h5>
                <p>
                  Reduce staffing costs by leveraging our flexible staffing
                  model.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Access to Top Talent</h5>
                <p>
                  Gain access to a pool of highly skilled professionals ready to
                  work when you need them.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Seamless Integration</h5>
                <p>
                  Integrate our platform with your existing systems for
                  streamlined operations.
                </p>
              </Col>
              <Col md="6" className="mb-4">
                <h5>Time-Saving Efficiency</h5>
                <p>
                  Save time on recruitment, onboarding, and scheduling with our
                  automated solutions.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Call-to-Action */}
        <section className="cta-section text-center py-5">
          <Container>
            <h2 className="mb-4">Ready to Optimize Your Workforce?</h2>
            <p className="lead mb-4">
              Contact us today to learn more about our staffing solutions or to
              schedule a demo.
            </p>
            <Button
              color="primary"
              size="lg"
              className="me-3"
              onClick={ContactUs}
            >
              Contact Us
            </Button>
            <Button color="secondary" size="lg" onClick={BookADemo}>
              Schedule a Demo
            </Button>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default StaffingService
