import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import style from "./dashboard.css";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopJobs from "./TopJobs";
import TopJobsChart from "./TopJobsChart";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import JobDataService from "services/JobDataService";
import WorkersDataService from "services/WorkersDataService";
import AgreementDataService from "services/AgreementDataService";
import OrganisationDataService from "services/OrganisationDataService"


const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [noofjobs, setNoofjobs] = useState(0);
  const [noofworkers, setNoofworkers] = useState(0);
  const [noofagreements, setNoofagreements] = useState(0);
  const [superadmin, setSuperAdmin] = useState(false);
  const [client, setClient] = useState(false);
  const [clientmanager, setClientManager] = useState(false);
  const [agencysuperadmin, setAgencySuperAdmin] = useState(false);
  const [worker, setWorker] = useState(false);
  const [lastloggedin, setLastLoggedin] = useState("");
  const [loggedinAs, setLoggedinAs] = useState("");
  const [userloggedin, setUserloggedin] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userAddresses, setUserAddresses] = useState("");
  const [organisation, setOrganisation] = useState("");
  
  const selectDashboardState = (state) => state.Dashboard;
  const DashboardProperties = createSelector(
    selectDashboardState,
      (dashboard) => ({
        chartsData: dashboard.chartsData
      })
  );

  const {
      chartsData
  } = useSelector(DashboardProperties);

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(false);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

    useEffect(() => {
        const unsubscribe = JobDataService.getJobCount((count) => {
          setNoofjobs(count);
        });
        return () => unsubscribe();
      }, []);

    useEffect(() => {
  const unsubscribe = WorkersDataService.getWorkersCount((count) => {
          setNoofworkers(count);
        });
        return () => unsubscribe();
      }, []);

    useEffect(() => {
        const unsubscribe = AgreementDataService.getAgreementsCount((count) => {
          setNoofagreements(count);
        });
        return () => unsubscribe();
      }, []);


  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(onGetChartsData("yearly"));
  // }, [dispatch]);

    const user = useSelector(state => state.Login.user);
    
    const userstate = useSelector(state => state);

    function getLastLoggedInTime(jsonString) {
      try 
      {
          // Parse the JSON string into an object
      const userObj =  jsonString;
      const lastLoginDate = new Date(userObj.seconds * 1000);

       const formattedDate = lastLoginDate.toLocaleDateString("en-GB", {
         year: "numeric",
         month: "long",
         day: "numeric",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit",
       })

        // Format the date as needed (e.g., toLocaleString, toISOString)
        return formattedDate;
      } catch (error) {
        console.log(error);
      }
    
    }

    function getCompanyName(company)
    {
        OrganisationDataService.getOrganisationByKey(company, data => {
          console.log("DT: " + JSON.stringify( data));

          setOrganisation(data.businessName);
          console.log("ORG: " + organisation)

        })
    }


      useEffect(() => {
          if (!localStorage.getItem("authUserDetails")) {
            console.log("No user details found...")
          } 
          else 
          {
            console.log(localStorage.getItem("authUserDetails"))
            if (localStorage.getItem("authUserDetails")) {
              let userDetails = JSON.parse(
                localStorage.getItem("authUserDetails")
              )

             setLastLoggedin("Last logged in on " + getLastLoggedInTime(userDetails.lastLoginTime));
             setLoggedinAs("Role: " + userDetails.userType);
             setUserloggedin("User: " + userDetails.fullName);

             let org = getCompanyName(userDetails.userCompany);

                  OrganisationDataService.getOrganisationByKey(
                    userDetails.userCompany,
                    data => {
                      setUserCompany("Company: " + data.businessName);
                    }
                  )
            
              setUserAddresses("Address: " + userDetails.address);

              if (userDetails.userType === "Super Admin") {
                console.log("setting as Super Admin.")

                setSuperAdmin(true)
              } else if (userDetails.userType === "Client") {
                console.log("setting as Client.")
                setClient(true)
              } else if (userDetails.userType === "Client Manager") {
                console.log("setting as Client Mgr.")
                setClientManager(true)
              } else if (userDetails.userType === "Agency Super Admin") {
                console.log("setting as Agency Super Admin")
                setAgencySuperAdmin(true)
              } else if (userDetails.userType === "Worker") {
                console.log("setting as Worker")
                setWorker (true)
              }
              
            } else {
              console.log("No user details found in localStorage.")
            }
 
          }
      }, [])

function MenuItem({ label, color, url }) {
  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <div className="menu-item" style={{ backgroundColor: color }}>
        {/* Replace with actual icon component or img tag */}
        {/* <div className="icon"></div> */}
        <div className="label">{label}</div>
      </div>
    </Link>
  )
}

    const navigate = useNavigate()

       const menuItems = [
         { label: "Home", color: "#FF5733", url: "/dashboard" },
         { label: "Profile", color: "#FF33F6", url: "/profile" },
         { label: "Onboarding", color: "#FFD633", url: "/worker" },
        //  { label: "Scheduler", color: "#33FF57", url: "/master" },

         //  { label: "Certificates", color: "#33FF57", url: "/certificates" },
         //  { label: "Emergency", color: "#FFD633", url: "/emergency" },
         //  { label: "Vaccinations", color: "#33FFF6", url: "/vaccinations" },
         //  { label: "References", color: "#8B33FF", url: "/references" },
       ]

    // const handleItemClick = url => {
    //    <Link to="/agreements"></Link>
    //   navigate(url)
    // }

  //meta title
  document.title = "Dashboard | Care-Tip - Admin & Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              {/* <MonthlyEarning /> */}
            </Col>
            <Col xl="8">
              {superadmin && (
                <Row>
                  {/* Reports Render */}

                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Workers</p>
                            <h4 className="mb-0">{noofworkers}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <Link to="/workers">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "mdi mdi-account-switch font-size-24"
                                  }
                                ></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Agreement</p>
                            <h4 className="mb-0">{noofagreements}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <Link to="/agreements">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={"mdi mdi-receipt font-size-24"}
                                ></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Jobs</p>
                            <h4 className="mb-0">{noofjobs}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <Link to="/jobs">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "mdi mdi-account-check font-size-24"
                                  }
                                ></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              <Card>
                <CardBody>
                  <div>{userloggedin}</div>
                  <div>{lastloggedin}</div>
                  <div>{loggedinAs}</div>
                  <div>{userCompany}</div>
                  <div>{userAddresses}</div>
                </CardBody>
                {/* <CardBody>
                <div>{loggedinAs}</div>
              </CardBody> */}
              </Card>
              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <StackedColumnChart periodData={periodData} dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' />
                </CardBody>
              </Card> */}
            </Col>
          </Row>

          {superadmin && (
            <Row>
              <Col xl="4">
                <SocialSource />
              </Col>
              <Col xl="4">
                <TopJobsChart />
              </Col>

              <Col xl="4">
                <TopJobs />
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="12">
              <div className="icon-menu">
                {menuItems.map(item => (
                  <MenuItem
                    key={item.label}
                    label={item.label}
                    color={item.color}
                    url={item.url}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe to our newletter and get notification to stay
                  update.
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
