import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap"
import "./GuidePage.css" // Optional CSS for styling
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"

// Example guide data
const guides = [
  {
    title: "Getting Started with Workforce Management",
    description:
      "A comprehensive guide to understanding the basics of workforce management and how to streamline your processes.",
    link: "#",
  },
  {
    title: "Best Practices for Compliance in Healthcare",
    description:
      "Learn about the essential practices to ensure compliance and maintain high standards in healthcare organizations.",
    link: "#",
  },
  {
    title: "10 Tips to Improve Employee Engagement",
    description:
      "Discover actionable strategies to boost employee engagement and foster a positive work environment.",
    link: "#",
  },
  {
    title: "Optimizing Temporary Staffing Costs",
    description:
      "Practical advice on how to manage and reduce costs while maintaining efficiency in temporary staffing.",
    link: "#",
  },
  {
    title: "Leveraging Technology for Better Scheduling",
    description:
      "Explore how advanced technology can simplify staff scheduling and improve resource allocation.",
    link: "#",
  },
]

const OurGuide = () => {
  return (
    <>
    <Header />
    <Container className="my-5">
      <h1 className="text-center mb-4">Guides & Resources</h1>
      <p className="text-center text-muted mb-5">
        Explore our expert guides and resources to help you navigate workforce
        management with confidence.
      </p>

      <Row>
        {guides.map((guide, index) => (
          <Col md="6" lg="4" className="mb-4" key={index}>
            <Card className="h-100 shadow border-0">
              <CardBody>
                <CardTitle tag="h5">{guide.title}</CardTitle>
                <CardText>{guide.description}</CardText>
                <Button color="primary" href={guide.link}>
                  Learn More
                </Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    <Footer />
    </>
  )
}

export default OurGuide
