import AWS from "aws-sdk"

class EmailService {
  constructor(region, accessKeyId, secretAccessKey) {
    // Initialize the SES client
    this.ses = new AWS.SES({
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    })
  }

  // Method to send a plain text email
  sendEmailPlainText({ to, from, subject, body }) {
    const params = {
      Source: from,
      Destination: {
        ToAddresses: [to],
      },
      Message: {
        Subject: {
          Data: subject,
        },
        Body: {
          Text: {
            Data: body,
          },
        },
      },
    }

    return this.ses
      .sendEmail(params)
      .promise()
      .then(data => {
        console.log("Email sent successfully", data)
        return data
      })
      .catch(error => {
        console.error("Error sending email", error)
        throw error
      })
  }

  // Method to send an email with HTML content
  sendEmailHTML({ to, from, subject, htmlBody }) {
    const params = {
      Source: from,
      Destination: {
        ToAddresses: [to],
      },
      Message: {
        Subject: {
          Data: subject,
        },
        Body: {
          Html: {
            Data: htmlBody,
          },
        },
      },
    }

    return this.ses
      .sendEmail(params)
      .promise()
      .then(data => {
        console.log("Email sent successfully", data)
        return data
      })
      .catch(error => {
        console.error("Error sending email", error)
        throw error
      })
  }

  // Method to send email using predefined templates
  sendEmailTemplate({ to, from, templateName, templateData }) {
    const params = {
      Source: from,
      Destination: {
        ToAddresses: [to],
      },
      Template: templateName, // The name of the template
      TemplateData: JSON.stringify(templateData), // JSON string with template data
    }

    return this.ses
      .sendTemplatedEmail(params)
      .promise()
      .then(data => {
        console.log("Templated email sent successfully", data)
        return data
      })
      .catch(error => {
        console.error("Error sending templated email", error)
        throw error
      })
  }
}

export default EmailService
