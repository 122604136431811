// src/firebase.js
import { initializeApp, getApps } from "firebase/app"
import { getFunctions } from "firebase/functions"

// Firebase configuration (replace these with your actual Firebase config values)
const firebaseConfig = {
  apiKey: "AIzaSyDOZDXrHoMNsVERaW1doy4Yde3atlAkjyM",
  authDomain: "caretip-app.firebaseapp.com",
  projectId: "caretip-app",
  storageBucket: "caretip-app.appspot.com",
  messagingSenderId: "477394462938",
  appId: "1:477394462938:web:2d7cda22ff45b5ceeb9de3",
}


// Initialize Firebase only if no app is already initialized
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

// Initialize and export functions instance
export const functions = getFunctions(app);