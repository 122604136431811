import React, { useState, useEffect } from "react"
import { Button, Table, Modal, ModalHeader, ModalBody, Input } from "reactstrap"
import { getFirestore, collection, getDocs } from "firebase/firestore"
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth"
import OrganisationDataService from "services/OrganisationDataService"
import { useNavigate } from "react-router-dom"
import { getFunctions, httpsCallable } from "firebase/functions"
import axios from "axios"
const AWS = require("aws-sdk")

const UsersTable = () => {
  const [users, setUsers] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [organisationName, setOrganisationName] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [admin, setAdmin] = useState(null)

  const toggleModal = () => setModal(!modal)

  // Set up AWS SES
  const ses = new AWS.SES({
    region: "eu-north-1",
    accessKeyId: "AKIAVVZON4MOPBED3CRP",
    secretAccessKey: "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",
  })

  // Define email parameters
  const params = {
    Source: "caretipapp@gmail.com",
    Destination: {
      ToAddresses: ["caretipapp@gmail.com"],
    },
    Message: {
      Subject: { Data: "Test Email from AWS SES" },
      Body: {
        Text: { Data: "This is a test email sent using AWS SES." },
      },
    },
  }

  const showUserDetail = async user => {
    setSelectedUser(user)
    toggleModal()

    if (user.userCompany) {
      try {
        const name = await OrganisationDataService.getOrganisationBusinessName(
          user.userCompany
        )
        setOrganisationName(name)
      } catch (error) {
        console.error("Error fetching organisation name:", error)
        setOrganisationName("Error retrieving business name")
      }
    } else {
      setOrganisationName("No company")
    }
  }

  const navigate = useNavigate()

  const createUser = () => {
    navigate(`/adminregister`)
  }

  const adminLogin = async () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    try {
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      setAdmin(user) // Store the authenticated admin user
      return user
    } catch (error) {
      console.error("Admin login failed:", error)
      alert("Admin login is required to delete users.")
      return null
    }
  }

  const deleteUser = async user => {
    // Check if the admin is logged in, if not, prompt for admin login
    if (!admin) {
      const loggedInAdmin = await adminLogin()
      if (!loggedInAdmin) return
    }

    const functions = getFunctions()

    const deleteUserFn = httpsCallable(functions, "deleteUser")

    try {
      const response = await deleteUserFn({ uid: user.id })
      console.log(response.data.message)
      alert("User successfully deleted!")

      setUsers(prevUsers => prevUsers.filter(u => u.id !== user.id))

      toggleModal()
    } catch (error) {
      console.error("Error deleting user:", error)
      alert("Failed to delete user.")
    }
  }

  useEffect(() => {
    // Send email
    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.error("Error sending email:", err)
      } else {
        console.log("Email sent! Message ID:", data.MessageId)
      }
    });
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const db = getFirestore()
        const usersRef = collection(db, "users")
        const querySnapshot = await getDocs(usersRef)
        const fetchedUsers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))

        setUsers(fetchedUsers)
      } catch (error) {
        console.error("Error fetching users:", error)
      }
    }
    fetchData()
  }, [])

  const filteredUsers = users.filter(
    user =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className="page-content">
      <div className="mb-3">
        <Input
          type="text"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      <p>
        <Button onClick={createUser}>Create User</Button>
      </p>

      <Table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>User Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.userType}</td>
              <td>
                <Button color="primary" onClick={() => showUserDetail(user)}>
                  View Details
                </Button>
                <Button color="danger" onClick={() => deleteUser(user)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedUser && (
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedUser.fullName}
          </ModalHeader>
          <ModalBody>
            <p>
              <strong>Email:</strong> {selectedUser.email}
            </p>
            <p>
              <strong>Address:</strong> {selectedUser.address}
            </p>
            <p>
              <strong>User Type:</strong> {selectedUser.userType}
            </p>
            <p>
              <strong>User Company:</strong> {organisationName}
            </p>
            <Button color="danger" onClick={() => deleteUser(selectedUser)}>
              Delete User
            </Button>
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default UsersTable
