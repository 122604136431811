import React, { useState } from "react"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import "./invoiceform.css"
import FinanceDataService from "./../../services/FinanceDataService"
import { ValidatorTypes } from "devextreme-react/cjs/validator"

const InvoiceForm = () => {
  const [invoiceType, setInvoiceType] = useState("simple")
  const [selectedFile, setSelectedFile] = useState(null) // To handle file uploads
  const [invoiceData, setInvoiceData] = useState({
    from: "",
    to: "",
    number: "",
    date: "",
    due_date: "",
    items: [{ name: "", quantity: 1, unit_cost: 0 }],
    tax: 0,
    logo: "https://www.care-tip.com/static/media/caretip.c02277b2dcf38ba7b05f.jpg",
    notes: "",
    terms: "",
    currency: "GBP",
    vatnumber: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setInvoiceData({
      ...invoiceData,
      [name]: value,
    })
  }

  const handleItemChange = (index, e) => {
    const { name, value } = e.target
    const items = [...invoiceData.items]
    items[index][name] = value
    setInvoiceData({
      ...invoiceData,
      items,
    })
  }

  const handleAddItem = () => {
    setInvoiceData({
      ...invoiceData,
      items: [...invoiceData.items, { name: "", quantity: 1, unit_cost: 0 }],
    })
  }

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0])
  }

  const generatePDF = async () => {
    try {
      const input = document.querySelector("#invoice")

      // Generate PDF using html2canvas and jsPDF
      const canvas = await html2canvas(input, {
        scale: 2,
        useCORS: true,
      })
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4")
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
      pdf.save(`invoice-${invoiceType}.pdf`)

      // Prepare data for Firebase
   const totalAmount = invoiceData.items
     .reduce((total, item) => total + item.quantity * item.unit_cost, 0)
     .toFixed(2)
   const taxAmount =
     invoiceType === "vat"
       ? ((invoiceData.tax / 100) * totalAmount).toFixed(2)
       : 0
   const grandTotal = (parseFloat(totalAmount) + parseFloat(taxAmount)).toFixed(
     2
   )

      const financeData = {
        invoiceHeader: {
          fromOrg: invoiceData.from,
          toOrg: invoiceData.to,
          invoiceNumber: invoiceData.number,
          invoiceDate: invoiceData.date,
          dueDate: invoiceData.due_date,
          terms: invoiceData.terms,
        },
        lineItems: invoiceData.items,
        total: totalAmount,
        vat: taxAmount,
        grandTotal: grandTotal,
        createdAt: Date.now(), // Timestamp
        vatnumber: invoiceData.vatnumber,
      }

      // Submit invoice data to Firebase
      const financeRefKey = await FinanceDataService.createFinance(financeData)

      // If file is attached, upload it
      if (selectedFile) {
        await FinanceDataService.uploadFile(financeRefKey, selectedFile)
      }

      alert("Invoice submitted successfully!")
      window.location.href = "/invoices" // Redirect after submission
    } catch (error) {
      console.error("Error submitting invoice: ", error)
      alert("Error submitting invoice: " + error.message)
    }
  }

  const renderInvoice = () => {
    const {
      from,
      to,
      number,
      date,
      due_date,
      items,
      tax,
      notes,
      terms,
      currency,
      logo,
      vatnumber
    } = invoiceData
  const totalAmount = items
    .reduce((total, item) => total + item.quantity * item.unit_cost, 0)
    .toFixed(2)
     const taxAmount =
       invoiceType === "vat" ? ((tax / 100) * totalAmount).toFixed(2) : 0
     const grandTotal = (
       parseFloat(totalAmount) + parseFloat(taxAmount)
     ).toFixed(2)

    return (
      <div id="invoice" className="invoice-preview">
        {logo && (
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: "100px", marginBottom: "20px" }}
          />
        )}
        <h2>Invoice</h2>
        <p>
          <strong>From:</strong> {from}
        </p>
        <p>
          <strong>To:</strong> {to}
        </p>
        <p>
          <strong>Invoice Number:</strong> {number}
        </p>
        <p>
          <strong>Invoice Date:</strong> {date}
        </p>
        <p>
          <strong>Due Date:</strong> {due_date}
        </p>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit Cost</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>
                  {currency} {parseFloat(item.unit_cost).toFixed(2)}
                </td>
                <td>
                  {currency} {(item.quantity * item.unit_cost).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {invoiceType === "vat" && (
          <>
            <p style={{ float: "right",  marginRight: "67px"}}>
              <strong>VAT ({tax}%):</strong> {currency} {taxAmount}
            </p>
            <p>
              <strong>VAT Number:</strong> {vatnumber}
            </p>
          </>
        )}
        <p style={{ float: "right",  marginRight: "67px" }}>
          <strong>Total Amount:</strong> {currency} {grandTotal}
        </p>

        {notes && notes.length > 0 && (
          <p>
            <strong>Notes:</strong> {notes}
          </p>
        )}
        {terms && terms.length > 0 && (
          <p>
            <strong>Terms:</strong> {terms}
          </p>
        )}
      </div>
    )
  }

  return (
    <div className="page-content">
      <div className="invoice-form">
        <h1>Create Invoice</h1>

        <label>
          Invoice Type:
          <select
            name="invoiceType"
            value={invoiceType}
            onChange={e => setInvoiceType(e.target.value)}
          >
            <option value="simple">Simple Invoice</option>
            <option value="vat">VAT Invoice</option>
          </select>
        </label>

        <div>
          <label>From:</label>
          <input
            type="text"
            name="from"
            value={invoiceData.from}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>To:</label>
          <input
            type="text"
            name="to"
            value={invoiceData.to}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Invoice Number:</label>
          <input
            type="text"
            name="number"
            value={invoiceData.number}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Invoice Date:</label>
          <input
            type="date"
            name="date"
            value={invoiceData.date}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Due Date:</label>
          <input
            type="date"
            name="due_date"
            value={invoiceData.due_date}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Logo URL:</label>
          <input
            type="text"
            name="logo"
            value={invoiceData.logo}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Currency:</label>
          <select
            name="currency"
            value={invoiceData.currency}
            onChange={handleChange}
          >
            <option value="USD">USD - US Dollar</option>
            <option value="EUR">EUR - Euro</option>
            <option value="GBP">GBP - British Pound</option>
            <option value="NGN">NGN - Nigeria Naira</option>
            <option value="JPY">JPY - Japanese Yen</option>
            <option value="CAD">CAD - Canadian Dollar</option>
          </select>
        </div>

        {invoiceData.items.map((item, index) => (
          <div key={index} className="line-item-group">
            <div>
              <label>Description:</label>
              <input
                type="text"
                name="name"
                placeholder="Item Description"
                value={item.name}
                onChange={e => handleItemChange(index, e)}
              />
            </div>

            <div>
              <label>Quantity:</label>
              <input
                type="number"
                name="quantity"
                placeholder="Quantity"
                value={item.quantity}
                onChange={e => handleItemChange(index, e)}
              />
            </div>

            <div>
              <label>Unit Cost:</label>
              <input
                type="number"
                name="unit_cost"
                placeholder="Unit Cost"
                value={item.unit_cost}
                onChange={e => handleItemChange(index, e)}
              />
            </div>
          </div>
        ))}

        <button onClick={handleAddItem}>Add Line Item</button>
        <br/>
        {invoiceType === "vat" && (
          <>
            <div>
              <label>VAT Rate (%):</label>
              <input
                type="number"
                name="tax"
                value={invoiceData.tax}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>VAT Number:</label>
              <input
                type="text"
                name="vatnumber"
                value={invoiceData.vatnumber}
                onChange={handleChange}
              />
            </div>
          </>
        )}

        <div>
          <label>Notes:</label>
          <textarea
            name="notes"
            value={invoiceData.notes}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Terms:</label>
          <textarea
            name="terms"
            value={invoiceData.terms}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Attach a File:</label>
          <input type="file" onChange={handleFileChange} />
        </div>

        <button onClick={generatePDF}>Download PDF</button>

        <div style={{ marginTop: "20px" }}>
          <h2>Invoice Preview</h2>
          {renderInvoice()}
        </div>
      </div>
    </div>
  )
}

export default InvoiceForm
